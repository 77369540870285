import * as React from 'react';
import Dialog  from '@mui/material/Dialog';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { useAppContext } from '../../utils/ContextProvider.jsx';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export default function Modal({heading = null, content = null, action= null, width = 'md', setOpenState = null, openState = null}) {

    var {setProcessing} = useAppContext()

    const handleClickOpen = () => {
        setOpenState(true);
    };

    const handleClose = () => {
        setOpenState(false);
        setProcessing(false)
    };


    return (
        <React.Fragment>
                <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={openState}
                        maxWidth={width}
                        fullWidth
                    >
                    <div className="row px-3 py-3">
                        <div className="col">
                            <div className="px-3 py-2 row align-items-center justify-content-between">
                                <div className="col">
                                    <h2 className="my-0" style={{fontSize: '1.5rem'}}>{heading}</h2>
                                </div>
                                <div className="col-auto text-end">
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <div className="row px-3">
                                        {content}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </BootstrapDialog>
            </React.Fragment>
    )
}