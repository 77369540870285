import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import { preferencesOptions } from "../../../../utils/PreferencesOptions/index"

export default function SurfacePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var object = {
            [evt.target.name]: evt.target.value
        }
        var objectString = JSON.stringify(object)
        localStorage.removeItem("preferences")
        localStorage.setItem("preferences", objectString)
        if (evt.target.value === 'trail') {
            navigate('/shoe-finder/preferences/surface/trail_type')
        } else if (evt.target.value === 'hybrid') {
            var preferencesObject = JSON.parse(localStorage.getItem('preferences'))
            var newPreferencesObject = {...preferencesObject, "shoeUsage": "everyday"}
            var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
            localStorage.setItem("preferences", newPreferencesObjectString)
            navigate('/shoe-finder/preferences/brand')
        } else {
            navigate('/shoe-finder/preferences/usage')
        }
        
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"I want a shoe that is best suited to...?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={preferencesOptions.shoeSurface}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
                toolTip={"Running shoes are specifically designed for surfaces, with road shoes having different requirments based on your profile to trail shoes based on the variation in the surface. Hybrid shoes are best if your runs will include a short section of road, but are mostly light trails."}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}