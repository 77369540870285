import * as React from 'react';
import Dialog  from '@mui/material/Dialog';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import axios from 'axios';
import { useAppContext } from '../../../utils/ContextProvider.jsx/index.jsx';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button'
import  DialogActions from '@mui/material/DialogActions';
import { set } from 'mongoose';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { useOutletContext } from 'react-router-dom';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export default function AddShoeModal({setSelectedShoe = null, userShoes = null, selectedShoe = null, user = null, heading = null, content = null, width = 'md', setOpenState = null, openState = null}) {

    var navigate = useNavigate()

    var { requestUrl, setLoading, setIsAlert, setLoading, isProcessing, setProcessing, trackEvent, sessionUser } = useAppContext()

    const handleClickOpen = () => {
        setOpenState(true);
    };

    const handleClose = () => {
        setOpenState(false);
    };

    var [fetchingShoes, setFetchingShoes] = useState(false)

    var [allShoes, setAllShoes] = useState()

    var [shoe, setShoe] = useState()

    var [shoeData, setShoeData] = useState({
        rating: 0,
        usage: '0-50km',
        description: ""
    })

    var [shoeReview, setShoeReview] = useState()

    useEffect(()=> {
        setFetchingShoes(true)
        var url = `${requestUrl}/api/shoes`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setAllShoes(res.data.allShoes.filter(shoe => 
                shoe?.gender == sessionUser?.runningProfile?.gender &&
                !userShoes?.some(userShoe => userShoe.shoeId._id === shoe._id)
            ))
            if (selectedShoe) {
                setShoe(selectedShoe.shoe)
            }
            setFetchingShoes(false)
        })
        .catch((err)=> {
            console.log(err)
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setFetchingShoes(false)
        })
    },[])

    console.log(userShoes)

    const updateShoeData = (evt) => {
        const changedField = evt.target.name
        const newValue = evt.target.value
        setShoeData(currData => {
            return {
                ...currData,
                [changedField]: newValue
            }
        })
    }

    const createNewUserShoe = (evt) => {
        setProcessing(true)
        var url = `${requestUrl}/api/userShoes`
        axios({
            url: url,
            method: "POST",
            data: {
                shoeId: shoe._id,
                rating: shoeData.rating,
                description: shoeData.description,
                usage: shoeData.usage
            },
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Add existing shoe', {shoeName: `${shoe.brand.name} ${shoe.model} ${shoe.version}`})
            setProcessing(false)
            setOpenState(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `New shoe added ✅`
                }
            })
            navigate(0)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })

    }



    return (
        <React.Fragment>
                <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={openState}
                        fullWidth
                    >
                    <div className="row px-3 py-3">
                        <div className="col">
                            <div className="px-3 py-2 row align-items-center justify-content-between">
                                <div className="col-8">
                                    <h3 className="my-0">Add to shoe locker</h3>
                                </div>
                                <div className="col-auto text-end">
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row mx-1 mt-2">
                                <div className="col px-3 mx-md-0" >
                                    <Autocomplete
                                        id="country-select-demo"
                                        loading={fetchingShoes ? true : false}
                                        loadingText={"loading shoes.."}
                                        options={fetchingShoes ? null : allShoes}
                                        disabled={fetchingShoes ? true : false}
                                        value={fetchingShoes ? null : shoe}
                                        autoHighlight
                                        getOptionLabel={(shoe) => `${shoe.brand?.name} ${shoe.model} ${shoe.version} (${shoe.gender})`}
                                        onChange={(evt, value) => setShoe(value)}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label={fetchingShoes? 'loading shoes..' : "Select a shoe"}
                                            autoComplete="false"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                            />
                                        )}
                                        />
                                </div>
                                
                            </div>
                            {shoe?
                            <div>
                                <div className="row mt-3">
                                <div className="col-5 mx-auto text-center">
                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.brand.slug}.png`} alt="" />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-8 mx-auto text-center">
                                    <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.brand.slug}_${shoe?.modelSlug}_${shoe?.version}_${shoe?.colorways[0]?.colorwayCode}.png`} alt="" />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col text-center">
                                    <h2>{shoe?.brand.name} {(shoe?.model)?.charAt(0).toUpperCase() + shoe?.model.slice(1)} {shoe?.version}</h2>
                                </div>
                            </div>
                            <div className="row my-2 mx-2">
                                <div className="col text-start">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Rating</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <Rating
                                                    name="rating"
                                                    size="large"
                                                    value={shoeData.rating} 
                                                    onChange={updateShoeData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>  

                            <div className="row my-2 mx-2">
                                <div className="col text-start">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Description</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <TextField 
                                                id="descriptionTextField" 
                                                label="Description" 
                                                variant="outlined" 
                                                name="description" 
                                                multiline 
                                                sx={{width: '90%'}}
                                                value={shoeData.description}
                                                helperText="The more detail the better, we use this to support your recommendation. e.g I like ___, I don't like ___"
                                                onChange={updateShoeData}  
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            
                            <div className="row my-2 mx-2">
                                <div className="col text-start">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Usage</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                        <FormControl sx={{width: '50%'}}>
                                            <InputLabel id="usageSelector">Distance used for</InputLabel>
                                            <Select
                                            labelId="distanceRanSelect"
                                            id="usageSelector"
                                            value={shoeData.usage} 
                                            label="Distance used for"
                                            name="usage"
                                            onChange={updateShoeData} 
                                            >
                                                <MenuItem value={"0-50km"}>0-50km</MenuItem>
                                                <MenuItem value={"50-100km"}>50-100km</MenuItem>
                                                <MenuItem value={"100-150km"}>100-150km</MenuItem>
                                                <MenuItem value={"150-200km"}>150-200km</MenuItem>
                                                <MenuItem value={">250km"}> More than 250km</MenuItem>
                                            </Select>
                                        </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </div>
                            : null}
                            
                        </div>
                    </div>
                    <DialogActions className='mx-3 my-3'>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button 
                            onClick={createNewUserShoe} 
                            variant='contained' 
                            disabled={!shoe || isProcessing ? true : false}
                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </BootstrapDialog>

            </React.Fragment>
    )
}
