import DashboardHeader from "../../../components/Dashboard/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useAppContext } from "../../../utils/ContextProvider.jsx";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TableRow from '@mui/material/TableRow';
import {  Tooltip } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RecommendationToolTip from "../../../components/Dashboard/ToolTip/RecommendationToolTip";
import RecommendationReasoningModal from "../../../components/Dashboard/Modal/RecommendationReasoningModal";
import Button from "@mui/material/Button";
import FeedbackSnackbar from "../../../components/Snackar/Feedback Snackbar";
import * as Sentry from "@sentry/react";
import { ScrollRestoration } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AIComparisonPanel from "./AIComparisonPanel";
import { sortingMethodsRecommendation } from "../../../utils/SortingMethods";
import { createEvent } from "../../../utils/CreateEvent";
import { useOutletContext } from "react-router-dom";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt.js";
import Avatar from "@mui/material/Avatar";
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CompareShoeAnalysisSection from "./AnalysisPanel";
import Skeleton from '@mui/material/Skeleton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GeneralTooltip from "../../../components/ToolTip/index.jsx";
import { shoeTraitGroupings } from "../../../utils/ShoeTraitGroupings/index.js";
import ShoeTraitRow from "./ShoeTraitRow/index.jsx";
import { shoeTraitMapping, shoeValueMapping } from "../../../utils/ValueMapping/index.js";
import CelebrationIcon from '@mui/icons-material/Celebration';
import IconButton from "@mui/material/IconButton";

export default function CompareShoePage( { notRec = null }) {

    var { recommendationId } = useParams()

    var [ user, userShoes ] = useOutletContext()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl, trackEvent, sessionUser } = useAppContext()

    const navigate = useNavigate()

    const { shoe } = useLocation().state || {}

    const [recommendation, setRecommendation] = useState({})

    var [partnerDeals, setPartnerDeals] = useState([])

    const [shoeComparisonList, setShoeComparisonList] = useState([])

    const [open, setOpen] = useState(false)

    const [openVariable, setOpenVariable] = useState()

    const [selectedColorways, setSelectedColorways] = useState({});

    const [websiteReviews, setWebsiteReviews] = useState({});

    const [loadingReviews, setLoadingReviews] = useState({});

    // State variables for collapsible sections
    const [openSuitability, setOpenSuitability] = useState(false);
    const [openReviews, setOpenReviews] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (shoe) {
            if (shoe[0]?.length > 0) {
                setShoeComparisonList(shoe[0]?.concat([{shoe: {_id: '0'}}]));
                // Set initial colorways for each shoe
                const initialColorways = shoe[0].reduce((acc, currentShoe, index) => {
                    // Sort colorways
                    const sortedColorways = currentShoe.shoe.colorways?.sort((a, b) => {
                        const hasDealA = partnerDeals.some(deal => deal.shoeColorway.toString() === a._id.toString() && (currentShoe.shoe.shoeShape === 'foot shaped' || recommendation?.requirements?.width.traits.includes(deal.width))) ? 1 : 0;
                        const hasDealB = partnerDeals.some(deal => deal.shoeColorway.toString() === b._id.toString() && (currentShoe.shoe.shoeShape === 'foot shaped' || recommendation?.requirements?.width.traits.includes(deal.width))) ? 1 : 0;

                        const widthAvailableA = currentShoe.shoe.shoeShape === 'foot shaped' || a?.widthOptions?.some(widthOption => recommendation?.requirements?.width.traits.includes(widthOption));
                        const widthAvailableB = currentShoe.shoe.shoeShape === 'foot shaped' || b?.widthOptions?.some(widthOption => recommendation?.requirements?.width.traits.includes(widthOption));

                        if (hasDealA && widthAvailableA !== hasDealB && widthAvailableB) {
                            return hasDealB - hasDealA;
                        }
                        if (widthAvailableA !== widthAvailableB) {
                            return widthAvailableB - widthAvailableA;
                        }
                        if (hasDealA !== hasDealB) {
                            return hasDealB - hasDealA;
                        }

                        return 0;
                    });

                    // Select the first colorway after sorting
                    acc[index] = sortedColorways ? sortedColorways[0]?._id : null;
                    return acc;
                }, {});
                setSelectedColorways(initialColorways);

                // Fetch website reviews for pre-loaded shoes
                shoe[0].forEach(currentShoe => {
                    if (currentShoe.shoe._id !== '0') {
                        setLoadingReviews(prev => ({ ...prev, [currentShoe.shoe._id]: true }));
                        axios.get(`${requestUrl}/api/websiteReviews/shoe/${currentShoe.shoe._id}`)
                            .then(response => {
                                setWebsiteReviews(prev => ({ ...prev, [currentShoe.shoe._id]: response.data }));
                            })
                            .catch(err => {
                                console.error("Error fetching website reviews:", err);
                            })
                            .finally(() => {
                                setLoadingReviews(prev => ({ ...prev, [currentShoe.shoe._id]: false }));
                            });
                    }
                });
            } else {
                setShoeComparisonList([{shoe: {_id: '0'}}, {shoe: {_id: '0'}}, {shoe: {_id: '0'}}]);
            }
        }
        trackEvent('Compare page loaded', {num_shoes: shoe[0]?.length > 0 ? shoe[0]?.length : 0});

        const recommendationUrl = `${requestUrl}/api/recommendations/${recommendationId}`;
        const partnerDealsUrl = `${requestUrl}/api/partnerDeals`;

        const recommendationRequest = axios({
            url: recommendationUrl,
            method: "GET",
            withCredentials: true
        });

        const partnerDealsRequest = axios({
            url: partnerDealsUrl,
            method: "GET",
            withCredentials: true
        });

        Promise.all([recommendationRequest, partnerDealsRequest])
            .then(([recommendationRes, partnerDealsRes]) => {
                const response = recommendationRes.data.recommendation;
                setRecommendation(response);
                setPartnerDeals(partnerDealsRes.data.filter((deal) => 
                    deal.partner.countries.length === 0 || // Check if countries array is empty
                    (sessionUser.location ? deal.partner.countries.includes(sessionUser.location?.country) : true)
                ));
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }));
                navigate('/dashboard');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        var list = shoeComparisonList.filter(item => item.shoe && item.shoe.model)
        if (list.length > 1) {
            createEvent({
                action: 'compare_shoes',
                category: 'interaction',
                data: {
                    shoes: list.map(item => item.shoe._id),
                    recommendationId: recommendationId,
                    route: !notRec ? 'recommendations' : 'browse'
                }
            })
        }
    }, [shoeComparisonList])

    const handleSelectShoe = (evt, index, value) => {
        var shoe = value || { shoe: { _id: '0' } }; // Default to a placeholder if value is null
        var updatedArray = shoeComparisonList.slice();
        updatedArray[index] = shoe;
        if (updatedArray.length < 4) {
            setShoeComparisonList(updatedArray.concat([{ shoe: { _id: '0' } }]));
        } else {
            setShoeComparisonList(updatedArray);
        }
        // Reset colorway when a new shoe is selected
        setSelectedColorways(prev => ({ ...prev, [index]: shoe.shoe.colorways ? shoe.shoe.colorways[0]?._id : null }));

        // Fetch website reviews for the new shoe
        if (shoe.shoe._id !== '0') {
            setLoadingReviews(prev => ({ ...prev, [shoe.shoe._id]: true }));
            axios.get(`${requestUrl}/api/websiteReviews/shoe/${shoe.shoe._id}`)
                .then(response => {
                    setWebsiteReviews(prev => ({ ...prev, [shoe.shoe._id]: response.data }));
                })
                .catch(err => {
                    console.error("Error fetching website reviews:", err);
                })
                .finally(() => {
                    setLoadingReviews(prev => ({ ...prev, [shoe.shoe._id]: false }));
                });
        }
    }

    const handleSelectColorway = (index, colorwayId) => {
        setSelectedColorways(prev => ({ ...prev, [index]: colorwayId }));
    };

    var breadcrumb = !notRec ? [
        {
            title: 'recommendations',
            href: '/dashboard/recommendations'
        },
        {
            title: `${recommendation.preferences? recommendation.preferences.shoeUsage : null}, ${recommendation.preferences? recommendation.preferences?.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        },
        {
            title: `compare`
        }
    
    ] :
    [
        {
            title: 'browse all shoes',
            href: '/dashboard/browse'
        },
        {
            title: `compare`
        }
    
    ]

    const handlePartnerDealClick = (shoe, colorway, deal) => {
        console.log(deal);
        trackEvent('View partner deal', {
            partner: deal?.partner.name,
            shoeName: `${shoe?.shoe.brand.name} ${shoe?.shoe.model} ${shoe?.shoe.version}`
        });
        createEvent({
            action: 'view_partner_deal',
            category: 'interaction',
            data: {
                partner: deal?.partner.name,
                shoeId: shoe?.shoe._id,
                colorwayId: colorway?._id, // Corrected variable
                recommendationId: recommendationId,
                partnerDeal: deal,
                price: deal?.price,
                specialPrice: deal?.specialPrice ? deal?.specialPrice : null
            }
        });
    };

    const openRecommendationModal = (evt, variable) => {
        setOpen(true)
        setOpenVariable(variable)
    }

    const handleCollapseToggle = (index) => {
        setOpen(prevOpen => ({
            ...prevOpen,
            [index]: !prevOpen[index]
        }));
    };

    // Function to find the first deal with the user's size in stock or where sizes are not specified
    const findDealsWithSize = (deals, shoeColorwayId, shoe, requirements, user) => {
        return deals.filter(deal => {
            const isColorwayMatch = deal.shoeColorway.toString() === shoeColorwayId?.toString();
            const isWidthMatch = shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : true;
            const isSizeInStock = !deal.sizes || deal.sizes.length === 0 || deal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0);

            return isColorwayMatch && isWidthMatch && isSizeInStock;
        });
    };

    // Function to sort deals based on the user's size availability
    const sortDealsBySizeAvailability = (deals, user) => {
        return deals.sort((a, b) => {
            const userSize = user?.runningProfile.shoeSize;

            const aHasSize = a.sizes?.some(sizeObj => sizeObj.size === userSize);
            const bHasSize = b.sizes?.some(sizeObj => sizeObj.size === userSize);

            const aHasNoSizingInfo = !a.sizes || a.sizes.length === 0;
            const bHasNoSizingInfo = !b.sizes || b.sizes.length === 0;

            if (aHasSize && !bHasSize) return -1;
            if (!aHasSize && bHasSize) return 1;
            if (aHasNoSizingInfo && !bHasNoSizingInfo) return -1;
            if (!aHasNoSizingInfo && bHasNoSizingInfo) return 1;

            return 0;
        });
    };

    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '100vh'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader 
                    heading={'Compare running shoes'}
                    subHeading={'Select a number of running shoes, and compare them side-by-side based on key information and how they fit your recommendation.'}
                    breadcrumb={breadcrumb}
    
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
                        { shoeComparisonList?.length > 1 && shoeComparisonList[1]?.shoe?.cushion ?

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-xl-10 mx-auto">
                                    <AIComparisonPanel shoeList={shoeComparisonList} />
                                </div>
                            </div> 
                        </div>                    
                        : null}
                        <div className="row mt-3 mx-0 mx-md-4">
                            <div className="col-12 mx-auto show-shoe-tile py-5" style={{border: 'none'}}>
                                <div className="row" style={{ overflowX: 'auto'}}>
                                    <Table sx={{ minWidth: '900px' }} >
                                        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                            <TableRow>
                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}></TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                const selectedColorwayId = selectedColorways[index];
                                                return (
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} align="center" key={shoe?.shoe?._id+'ShoeImageCell'+`${index}`}>
                                                        <Link style={{cursor: shoe?.shoe?.brand ? 'pointer' : 'default'}} target="_blank" to={ shoe?.shoe?.brand ? (!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}`: `/dashboard/browse/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}` ) : null} state={{shoe: shoe, preferences: recommendation.preferences, requirements: recommendation.requirements}}>
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 mx-auto text-center">
                                                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.shoe?.brand?.slug}.png`} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 mb-4">
                                                                <div className="col-10 mx-auto text-center">
                                                                    <img className="img img-fluid" style={{maxWidth: '180px'}} src={ selectedColorwayId ? 
                                                                        `https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.shoe?.brand?.slug}_${shoe?.shoe?.modelSlug}_${shoe?.shoe?.version}_${shoe?.shoe.colorways?.find(c => c._id === selectedColorwayId)?.colorwayCode}.png` 
                                                                        : `/assets/placeholder_shoe.svg`} alt="" />
                                                                </div>
                                                            </div>   
                                                        </Link>
                                                        {shoe?.shoe?.brand && (
                                                            <Link className="mt-3" target="_blank" style={{cursor: shoe?.shoe?.brand ? 'pointer' : 'default'}} to={ shoe?.shoe?.brand ? (!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}`: `/dashboard/browse/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}` ) : null} state={{shoe: shoe, preferences: recommendation.preferences, requirements: recommendation.requirements}}>
                                                                <Button variant="outlined" size="small" endIcon={<ArrowRightAlt />}>
                                                                    View shoe
                                                                </Button>
                                                            </Link>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >

                                        <TableRow>
                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Selected shoe</TableCell>
                                        {shoeComparisonList?.map((shoe, index) => {
                                        return (
                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} key={`${index}ShoeSelector`} align="center">
                                                <div className="col-12 text-center">
                                                <Autocomplete
                                                        id="country-select-demo"
                                                        options={recommendation.shoes
                                                            .filter(shoeOption => 
                                                                !shoeComparisonList.some(selectedShoe => selectedShoe.shoe._id === shoeOption.shoe._id)
                                                            )
                                                            .sort((a, b) => {
                                                                const aIsCurrent = userShoes?.some(userShoe => userShoe.shoeId._id === a.shoe._id);
                                                                const bIsCurrent = userShoes?.some(userShoe => userShoe.shoeId._id === b.shoe._id);
                                                                if (aIsCurrent && !bIsCurrent) return -1;
                                                                if (!aIsCurrent && bIsCurrent) return 1;
                                                                return sortingMethodsRecommendation['suitability'].method(a, b);
                                                            })}
                                                        groupBy={(option) => 
                                                            userShoes.some(userShoe => userShoe.shoeId._id === option.shoe._id) 
                                                                ? `Current ${recommendation.preferences.shoeSurface.charAt(0).toUpperCase() + recommendation.preferences.shoeSurface.slice(1)} Shoes (sorted by suitability)` 
                                                                : `All ${recommendation.preferences.shoeSurface.charAt(0).toUpperCase() + recommendation.preferences.shoeSurface.slice(1)} Shoes (sorted by suitability)`
                                                        }
                                                        value={shoe.shoe._id == '0' ? null : shoe}
                                                        autoHighlight
                                                        clearOnEscape
                                                        getOptionLabel={(shoe) => `${shoe?.shoe?.brand?.name} ${shoe?.shoe?.model} ${shoe?.shoe?.version}`}
                                                        onChange={(evt, value) => handleSelectShoe(evt, index, value)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label="Search"
                                                            autoComplete="true"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // Ensure the default end adornment is included
                                                            }}
                                                            /> 
                                                        )}
                                                    />

                                                </div>
                                                
                                            </TableCell>
                                        )
                                    })}
                                        </TableRow>

                                        <TableRow>
    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Colors</TableCell>
        {shoeComparisonList?.map((shoe, index) => {
            return (
                <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} key={`${index}ColorwaySelector`} align="center">
                    <div className="col-12 text-center">
                        <div className="row justify-content-center" style={{overflowX: 'auto'}}>
                        {shoe.shoe.colorways?.sort((a, b) => {
                            const hasDealA = partnerDeals.some(deal => deal.shoeColorway.toString() === a._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || recommendation?.requirements?.width.traits.includes(deal.width))) ? 1 : 0;
                            const hasDealB = partnerDeals.some(deal => deal.shoeColorway.toString() === b._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || recommendation?.requirements?.width.traits.includes(deal.width))) ? 1 : 0;

                            const widthAvailableA = shoe.shoe.shoeShape === 'foot shaped' || a?.widthOptions?.some(widthOption => recommendation?.requirements?.width.traits.includes(widthOption));
                            const widthAvailableB = shoe.shoe.shoeShape === 'foot shaped' || b?.widthOptions?.some(widthOption => recommendation?.requirements?.width.traits.includes(widthOption));

                            if (hasDealA && widthAvailableA !== hasDealB && widthAvailableB) {
                                return hasDealB - hasDealA;
                            }
                            if (widthAvailableA !== widthAvailableB) {
                                return widthAvailableB - widthAvailableA;
                            }
                            if (hasDealA !== hasDealB) {
                                return hasDealB - hasDealA;
                            }

                            return 0;
                        }).map((colorway, colorwayIndex) => {
                            const widthAvailable = shoe.shoe.shoeShape !== 'foot shaped' ? colorway?.widthOptions?.some(widthOption => recommendation?.requirements?.width.traits.includes(widthOption)) : true;
                            const dealsForColorway = partnerDeals.filter(deal => 
                                deal.shoeColorway.toString() === colorway._id &&
                                (shoe.shoe.shoeShape !== 'foot shaped' ? recommendation?.requirements?.width.traits.includes(deal.width) : deal)
                            );
                            return (
                                <div className="col-2 mx-1 my-1" style={{
                                    cursor: widthAvailable ? 'pointer' : 'auto',
                                    maxWidth: '70px',
                                    borderRadius: '0.5em' // Apply border radius here
                                }} key={`${shoe.shoe._id}Colorway${colorwayIndex}`} onClick={() => handleSelectColorway(index, colorway._id)}>
                                    <Tooltip title={widthAvailable ? `${colorway.colorwayName}` : `${colorway.colorwayName} (not available in ${recommendation?.requirements?.width.traits.join(' or ')})`}>
                                        <div className="row position-relative">
                                            <div className="col py-1 py-xl-2" style={{
                                                border: selectedColorways[index] === colorway?._id ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') : '1px solid #D9D9D9',
                                                opacity: widthAvailable ? '1' : '0.3',
                                                borderRadius: '0.5em' // Ensure inner div also has the same border radius
                                            }}>
                                                <img className='img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} alt={colorway.colorwayName} />
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            );
                        })}

                        </div>
                        
                    </div>
                </TableCell>
            );
        })}
</TableRow>

{/* New Partner Deals Row */}
<TableRow>
<TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Retailers</TableCell>
{shoeComparisonList?.map((shoe, index) => {
    const selectedColorwayId = selectedColorways[index];
    const matchingDeals = partnerDeals.filter(deal => 
        deal.shoeColorway.toString() === selectedColorwayId &&
        (shoe.shoe.shoeShape === 'foot shaped' || recommendation?.requirements?.width.traits.includes(deal.width))
    );
    const sortedDeals = sortDealsBySizeAvailability(matchingDeals, user);
    const selectedColorway = shoe.shoe.colorways?.find(c => c._id === selectedColorwayId);

    return (
        <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} key={`${index}PartnerDeals`} align="center">
            <div className="col-12 text-center">
                <div className="row justify-content-center">
                    {sortedDeals.length > 0 ? (
                        sortedDeals.map((deal, dealIndex) => (
                            <div className="row" key={`${shoe.shoe._id}DealRow${dealIndex}`}>
                                <div className="col mx-2 py-3 px-3" style={{border: '1px solid #687393', borderRadius: '0.5em'}}>
                                    <div className="row align-items-center">
                                    <div className="col-auto">
                                        <Avatar src={`/logos/partners/${deal.partner.slug}_icon.svg`} alt={deal.partner.name} />
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col text-start">
                                                <h4 className='my-0'>
                                                   View at {deal.partner.name} <VerifiedIcon color='primary' fontSize='small'/>
                                                </h4>
                                            </div>
                                        </div>
                                        
                                        <div className="row align-items-center mt-1">
                                            {deal.sizes && deal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0) ? 
                                            <div className="col-auto">
                                                <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#2E7d43'}}>
                                                    <InfoOutlinedIcon fontSize='inherit' className='me-1'/> Your size in stock 
                                                </p>
                                            </div>
                                            : 
                                            deal.sizes && deal.sizes.length > 0 && !deal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0) ? 
                                            <div className="col-auto">
                                                <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#d32f2f'}}>
                                                    <CancelOutlinedIcon fontSize='inherit' className='me-1'/> Your size out of stock
                                                </p>
                                            </div>
                                            : 
                                            <div className="col-auto">
                                                <p className='my-0 d-flex align-items-center' style={{fontSize: '1em'}}>
                                                    <InfoOutlinedIcon fontSize='inherit' className='me-1'/> Check website for sizes 
                                                </p>
                                            </div>}
                                            {deal.specialPrice ? 
                                            <div className="col-auto">
                                                <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#d32f2f'}}>
                                                    <WhatshotIcon fontSize='inherit' className='me-1'/> Reduced price     
                                                </p>
                                            </div>
                                            : null}
                                            {deal.partner.coupon && (
                                                <div className="col-auto">
                                                    <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#d32f2f'}}>
                                                        <CelebrationIcon fontSize='inherit' className='me-1'/> Save {deal.partner.coupon.discountPercent}% with code {deal.partner.coupon.code}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row mt-1">
                                            <div className="col-auto">
                                                <a className='my-0' style={{color: 'black', cursor: 'pointer', textDecoration: 'none'}} href={deal.affiliateLink || `${deal.partner.baseUrl}${deal.urlKey}${deal.partner.referralUrlString}`} target="_blank" onClick={() => handlePartnerDealClick(shoe, selectedColorway, deal)}>Visit website <ArrowRightAltIcon fontSize='small'/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <span>No partner offers available for this colorway</span>
                    )}
                </div>
            </div>
        </TableCell>
    );
})}
</TableRow>
<TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Surface</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        return (
                                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} align="center" key={shoe.shoe?._id+'SurfaceCell'+`${index}`}>
                                                                {shoe.shoe.surface ? `${shoe.shoe.surface}` : null}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Price</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        return (
                                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} align="center" key={shoe.shoe?._id+'PriceCell'+`${index}`}>
                                                                {shoe.shoe.msrp ? `$${shoe.shoe.msrp}` : null}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>

                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Best for</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        return (
                                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} align="center" key={shoe.shoe?._id+'BestForCell'+`${index}`}>
                                                                {shoe.shoe.bestFor ? `${shoe.shoe.bestFor}` : null}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>

                                                <TableRow hover style={{borderTop: '20px solid #FAF9FA'}}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Suitability</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => (
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                whiteSpace: 'normal',
                                                                wordWrap: 'break-word',
                                                                color: parseFloat(shoe.score / shoe.maxScore * 100) > 80 ? 'green' : (parseFloat(shoe.score / shoe.maxScore * 100)) > 60 ? 'orange' : 'red',
                                                                borderTop: '2px solid #D9D9D9' // Add border to separate the row
                                                            }}
                                                            key={shoe.shoe?._id + 'SuitabilityCell' + `${index}`}
                                                        >
                                                            {shoe.score ? `${parseFloat((shoe.score / shoe.maxScore) * 100).toFixed(0)}%` : null}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>

                                        {/* Suitability Section */}
                                        <TableRow>
                                            <TableCell colSpan={shoeComparisonList.length + 1}>
                                                <IconButton
                                                    onClick={() => setOpenSuitability(!openSuitability)}
                                                    aria-label="toggle suitability"
                                                    size="small"
                                                >
                                                    {openSuitability ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                                <i style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setOpenSuitability(!openSuitability)}>View suitability breakdown</i>
                                            </TableCell>
                                        </TableRow>
                                        {openSuitability && (
                                            <>
            
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Cushion</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'cushion'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.cushion.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.cushion.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'CushionCell'+`${index}`}>
                                                                {shoe.shoe?.cushion ? `${shoe.shoe?.cushion} (${shoe.shoe.stackHeight}mm, ${shoe.shoe.cushionType})` : null} {recommendation?.requirements?.cushion.reasoning.length > 0 && shoe.shoe.cushion? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'cushion'}/> : null}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Support</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'stability'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.stability.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.stability.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'StabilityCell'+`${index}`}>
                                                                {shoe.shoe.stability ? `${shoe.shoe.stability}` : null} {recommendation?.requirements?.stability.reasoning.length > 0 && shoe.shoe.stability? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'stability'}/> : null}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Weight</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'weight'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.weight.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.weight.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'WeightCell'+`${index}`}>
                                                                {shoe.shoe.weight ? `${shoe.shoe.weight}g` : null} {recommendation?.requirements?.weight.reasoning.length > 0 && shoe.shoe.weight? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'weight'}/> : null}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Heel-toe-drop</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'heelToeDrop'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.heelToeDrop.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.heelToeDrop.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'HeelToeDropCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.heelToeDrop ? `${shoe.shoe.heelToeDrop}mm (${parseInt(shoe.shoe?.heelToeDrop) > 8 ? 'high' : (parseInt(shoe.shoe?.heelToeDrop) > 3 ? 'medium' : 'low')})`  : null} {recommendation?.requirements?.heelToeDrop.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'heelToeDrop'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Toebox shape</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'shoeShape'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.shoeShape.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.shoeShape.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'ShoeShapeCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.shoeShape ? `${shoe.shoe.shoeShape}`  : null} { recommendation?.requirements?.shoeShape.reasoning.length > 0 && shoe.shoe.shoeShape? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'shoeShape'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Width options</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'width'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.width.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.width.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'WidthOptionCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.width ? `${shoe.shoe.width.join(',')}`  : null} {recommendation?.requirements?.width.reasoning.length > 0 && shoe.shoe.width? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'width'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Toebox width</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'widthRatingForefoot'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.widthRatingForefoot.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.width.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'widthRatingForefootOptionCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.widthRatingForefoot ? `${shoe.shoe.widthRatingForefoot}`  : null} {recommendation?.requirements?.widthRatingForefoot.reasoning.length > 0 && shoe.shoe.widthRatingForefoot? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'widthRatingForefoot'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                {recommendation.preferences?.shoeSurface === 'trail' || recommendation.preferences?.shoeSurface === 'hybrid' ?
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Grip</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'grip'})[0]
                                                        return (
                                                            <TableCell
                                                            align="center" 
                                                            sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.grip.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.grip.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                            key={shoe.shoe?._id+'GripCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.grip ? `${shoe.shoe.grip} (${shoe.shoe.lugDepth}mm deep, ${shoe.shoe.lugDensity} density lugs)`  : null} {recommendation?.requirements?.grip.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'grip'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                : null}
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Carbon plate</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'carbonPlate'})[0]
                                                        return (
                                                            <TableCell
                                                            align="center" 
                                                            sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.carbonPlate.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.carbonPlate.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                            key={shoe.shoe?._id+'CarbonPlateCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.carbonPlate ? `${shoe.shoe.carbonPlate}`  : null} {recommendation?.requirements?.carbonPlate.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'carbonPlate'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Rocker</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'rocker'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.rocker.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.rocker.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'CarbonPlateCell'+`${index}`}
                                                                >
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.rocker ? `${shoe.shoe.rocker}`  : null} {recommendation?.requirements?.rocker.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'rocker'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Shoe Last</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'shoeLast'})[0]
                                                        return (
                                                            <TableCell
                                                                align="center" 
                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.shoeLast.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.shoeLast.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                                key={shoe.shoe?._id+'ShoeLastCell'+`${index}`}
                                                                >
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.shoeLast ? `${shoe.shoe.shoeLast}`  : null} {recommendation?.requirements?.shoeLast.reasoning.length > 0 && shoe.shoe.shoeLast? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'shoeLast'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                {recommendation.preferences?.shoeSurface === 'trail' || recommendation.preferences?.shoeSurface === 'hybrid' ?
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Rock plate</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'rockPlate'})[0]
                                                        return (
                                                            <TableCell align="center" sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: recommendation?.requirements?.rockPlate.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation?.requirements?.rockPlate.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} key={shoe.shoe?._id+'RockPlateCell'+`${index}`}>
                                                                <div className="row justify-content-center align-items-center">
                                                                    <div className="col-auto">
                                                                        {shoe.shoe.rockPlate ? `${shoe.shoe.rockPlate}`  : null} {recommendation?.requirements?.rockPlate.reasoning.length > 0 && shoe.shoe.rockPlate ? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'rockPlate'}/> : null}
                                                                    </div>
                                                                </div>    
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                : null}

                                            </>
                                        )}

                                                <TableRow hover  style={{borderTop: '20px solid #FAF9FA'}}>
                                                    <TableCell>Ratings</TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        return (
                                                            <TableCell
                                                            align="center"
                                                             sx={{ whiteSpace: 'normal', wordWrap: 'break-word', color: parseFloat(shoe?.shoe.averageWebsiteScore) > 8.5 ? 'green' : (parseFloat(shoe?.shoe.averageWebsiteScore)) > 7.5 ? 'orange' : (parseFloat(shoe?.shoe.averageWebsiteScore)) > 7 ? 'red' : 'grey' }} 
                                                             key={shoe.shoe?._id+'RatingCell'+`${index}`}>
                                                                {shoe?.shoe.averageWebsiteScore && shoe?.shoe.averageWebsiteScore != 'NaN' ? `${(shoe?.shoe.averageWebsiteScore / 2).toFixed(1)}/5 (${shoe?.shoe.websiteReviews?.length})` : '-'}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                                <TableRow hover={'true'}>
                                                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Most frequently mentioned <GeneralTooltip title="This is based on the most frequent positive and negative traits mentioned in reviews of this shoe." /></TableCell>
                                                    {shoeComparisonList?.map((shoe, index) => {
                                                        const reviews = websiteReviews[shoe.shoe._id];
                                                        const isLoading = loadingReviews[shoe.shoe._id];
                                                        return (
                                                            <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} align="center" key={shoe.shoe?._id+'AnalysisCell'+`${index}`}>
                                                                {isLoading ? (
                                                                    <Skeleton variant="rectangular" width={210} height={118} />
                                                                ) : reviews ? (
                                                                    <CompareShoeAnalysisSection websiteReviews={reviews} link={!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}`: `/dashboard/browse/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}`} shoe={shoe} recommendation={recommendation}/>
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>

                                        {/* Reviews & Performance Section */}
                                        <TableRow>
                                            <TableCell colSpan={shoeComparisonList.length + 1}>
                                                <IconButton
                                                    onClick={() => setOpenReviews(!openReviews)}
                                                    aria-label="toggle reviews"
                                                    size="small"
                                                >
                                                    {openReviews ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                               <i style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setOpenReviews(!openReviews)}>View reviews breakdown</i>
                                            </TableCell>
                                        </TableRow>
                                        {openReviews && (
                                            <>
                                                {shoeTraitGroupings.map((shoeTrait) => (
                                                    <TableRow hover key={`${shoeTrait}Row`}>
                                                        <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                            {shoeTrait}
                                                        </TableCell>
                                                        {shoeComparisonList?.map((shoe, index) => {
                                                            const reviewsData = websiteReviews[shoe.shoe._id];
                                                            const isLoading = loadingReviews[shoe.shoe._id];
                                                            return (
                                                                <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }} align="center" key={`${shoe.shoe?._id}${shoeTrait}Cell${index}`}>
                                                                    {isLoading ? (
                                                                        <Skeleton variant="rectangular" width={210} height={118} />
                                                                    ) : reviewsData ? (
                                                                        <ShoeTraitRow 
                                                                            link={!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}`: `/dashboard/browse/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}`}
                                                                            shoeTrait={shoeTrait} // Change 'trait' to 'shoeTrait'
                                                                            websiteReviews={reviewsData} // Change 'reviews' to 'websiteReviews'
                                                                        />
                                                                    ) : (
                                                                        <span></span>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <FeedbackSnackbar/>
                <RecommendationReasoningModal openState={open} setOpenState={setOpen} recommendation={recommendation} openVariable={openVariable} />
            </div>
        )
    
    }
}


