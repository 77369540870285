import { Tooltip, Rating } from "@mui/material";
import { useEffect, useState } from "react";
import TraitSummaryCard from "../AnalysisPanel/TraitSummaryCard";
import Skeleton from '@mui/material/Skeleton';
const filterWebsiteReviews = (trait, reviews) => {
    if (!reviews || !Array.isArray(reviews)) return { pros: [], cons: [] };

    const prosList = [];
    const consList = [];

    reviews.forEach((review) => {
        const pros = review.prosList?.filter(item => item.group === trait) || [];
        const cons = review.consList?.filter(item => item.group === trait) || [];
        prosList.push(...pros);
        consList.push(...cons);
    });

    return { pros: prosList, cons: consList };
};

export default function ShoeTraitRow({ link = null, shoeTrait, websiteReviews }) {
    const [pros, setPros] = useState([]);
    const [cons, setCons] = useState([]);

    useEffect(() => {
       const { pros: filteredPros, cons: filteredCons } = filterWebsiteReviews(shoeTrait, websiteReviews);
        setPros(filteredPros);
        setCons(filteredCons);
    }, [websiteReviews, shoeTrait]);

    const calculateRating = () => {
        const total = pros.length + cons.length;
        const rating = total > 0 ? Math.round((pros.length / total) * 5 + 0.5) : 0;
        return rating;
    };

    const shouldShowRating = websiteReviews && websiteReviews.length > 0 && (pros.length > 0 || cons.length > 0);

    return (

            <div className="row align-items-center justify-content-between">
                <div className="col" onClick={() => {
                if (link) {
                    // Set the localStorage item before opening the new window
                    localStorage.setItem('openAccordian', shoeTrait);
                    localStorage.removeItem('openAccordianSubGroup', shoeTrait);
                    const newWindow = window.open(link, '_blank');
                    if (newWindow) {
                        newWindow.onload = () => {
                            // Ensure the localStorage item is set before any further actions
                            localStorage.setItem('openAccordian', shoeTrait);
                            localStorage.removeItem('openAccordianSubGroup', shoeTrait);
                        };
                    }
                }
            }}>
                    <div className="row justify-content-between">
                        {shouldShowRating && (
                            <div className="col mx-auto">
                                <div className="row align-items-center">
                                    <Rating className="mx-auto"
                                        name={`${shoeTrait}-rating`}
                                        value={calculateRating()}
                                        readOnly
                                        precision={0.5}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {shouldShowRating && (
                        <div className="row mt-2">
                            <div className="col">
                            <p style={{fontSize: '0.8em'}}><i>{`(Positives: ${pros.length}, Negatives: ${cons.length})`}</i></p>
                        </div>
                    </div>
                    )}
                    {shouldShowRating && (
                        <div className="row mt-2">
                            <div className="col">
                                <TraitSummaryCard shoeTrait={shoeTrait} prosSummaryList={pros} consSummaryList={cons}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>

    );
}
