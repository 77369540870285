import { useState, useEffect } from "react"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../../../utils/ContextProvider.jsx/index.jsx"
import axios from "axios"
import DashboardHeader from "../../../../components/Dashboard/Header/index.jsx"

export default function AllWebsiteReviewsPage () {

    var [ unprocessedReviews, setUnprocessedReviews ] = useState()

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading } = useAppContext()

    var navigate = useNavigate()

    useEffect(()=> {
        var url = `${requestUrl}/api/websiteReviews/`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setUnprocessedReviews(res.data)
        })
        .catch((err)=> {
        var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
        })
    },[])

    const handleClick = (evt, websiteReview) => {
        navigate(`/admin/reviews/${websiteReview._id}`, {state: {websiteReview: websiteReview, unprocessedReviews: unprocessedReviews }})
    }

    return (
        <div>
            <DashboardHeader
                    heading={`Reviews to process`}
                    subHeading={`Click on each review to copy the review content and add any required scores. This will send the information required to ChatGPT to process.`}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
            <div className="container-md">
                <div>
                    <p>{unprocessedReviews?.length} total reviews</p>
                </div>
                {unprocessedReviews?.map((review) => { 
                    return (
                        <div className="row my-3">
                            <div className="col py-3 px-4" style={{backgroundColor: 'white'}}>
                                <div className="row">
                                    <div className="col-2">
                                        <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${review.shoe[0].brand.slug}_${review.shoe[0].modelSlug}_${review.shoe[0].version}_${review.shoe[0]?.colorways[0]?.colorwayCode}.png`} alt="" />
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col">
                                                <h2>{review.shoe[0].brand.name} {review.shoe[0].model} {review.shoe[0].version}</h2>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-8" style={{wordBreak: 'break-all'}}>
                                                <p className="my-0">{review.url}</p>
                                            </div>
                                            <div className="col-auto">
                                                <Button onClick={(evt) => handleClick(evt, review)}>Process Review</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    )
                })}

            </div>
        </div>
        </div>
        
    )
}