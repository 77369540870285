import { useEffect, useState } from "react"
import axios from "axios"
import Loader from "../../../../components/Loader/index"
import { useAppContext } from "../../../../utils/ContextProvider.jsx"
import Skeleton from "@mui/material/Skeleton"
import Button from "@mui/material/Button"
import ChipComponent from "../../../../components/Chip"
import { useNavigate } from "react-router-dom"
import * as Sentry from "@sentry/react";

export default function ShoeLockerSection () {

    var navigate = useNavigate()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, setProcessing, isProcessing, requestUrl, trackEvent } = useAppContext()

    var [fetchingUserShoes, setFetchingUserShoes] = useState(false)

    var [userShoes, setUserShoes] = useState()


    useEffect(()=> {
        var url = `${requestUrl}/api/userShoes`
        setFetchingUserShoes(true)
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setUserShoes(res.data.userShoes)
            setFetchingUserShoes(false)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setFetchingUserShoes(false)
        })
    },[])

    if (fetchingUserShoes) {
        return (
            <div className="my-4">
                <Skeleton variant="rectangular" animation="wave" />
                
            </div>
        )
    } return (
        <div className="row my-3 gx-5">
            {userShoes?.sort((a,b) => {return b.current - a.current}).slice(0,4).map((shoe, index) => {
                return (
                    <div className="col-6 col-md-3 px-2" key={`${shoe?.shoeId._id}SimilarShoeTile`}>
                        <div className="col my-2 pt-2 pb-3 show-shoe-tile">
                                <Button
                                disableFocusRipple
                                disableRipple
                                style={{ backgroundColor: 'transparent', textTransform: 'none', color: 'inherit'}} 
                                onClick={(evt) => navigate('/dashboard/shoe-locker')}
                                key={`${shoe?.shoeId._id}SimilarShoeCard`}
                                >
                                    <div className="col">

                                        <div className="row justify-content-center">
                                            <div className="col-auto">
                                                <ChipComponent 
                                                    color={shoe.current == true ? "primary": "default"}
                                                    label={shoe.current == true ? "Current shoe": "Not active"}
                                                    size={"small"}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-6 mx-auto text-center">
                                                <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoeId.brand?.slug}.png`} alt="" />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-10 mx-auto text-center">
                                                <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoeId.brand?.slug}_${shoe?.shoeId.modelSlug}_${shoe?.shoeId.version}_${shoe?.shoeId.colorways[0]?.colorwayCode}.png`} alt="" />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col text-center">
                                                <h3>{shoe.shoeId.brand?.name} {(shoe?.shoeId.model)?.charAt(0).toUpperCase() + shoe?.shoeId.model.slice(1)} {shoe?.shoeId.version}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                            </div>

                    </div>
                    
                )
            })}
        </div>
    )
}
