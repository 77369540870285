import { Form, useNavigate } from "react-router-dom"
import { useState, useEffect, Suspense } from "react"
import axios from "axios"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import { useAppContext } from '../../../../utils/ContextProvider.jsx'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from "../../../../components/Loader"
import { preferences } from "joi"
import * as Sentry from "@sentry/react";
import { ScrollRestoration } from "react-router-dom"
import Switch from "@mui/material/Switch"


export default function ShoeSizePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const [shoeSize, setShoeSize] = useState("9");

    const [gender, setGender] = useState()
    
    useEffect(()=> {
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        setGender(runningProfileObject.gender)
    },[])

    const handleSubmit = (evt) => {
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        var newRunningProfileObject = {...runningProfileObject, 'shoeSize': shoeSize}
        var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
        localStorage.setItem("runningProfile", newRunningProfileObjectString)
        navigate('/shoe-finder/preferences')
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <div className="container form-body mt-4">
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <h2>What is your current shoe size?</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <p>Please select from the list below.</p>
                    </div>
                </div>

                <div className="row mt-3 align-items-center">
                    <div className="col-11 col-md-5">
                    <FormControl fullWidth>
                        <InputLabel id="shoe-size-label">Shoe size</InputLabel> 
                        {gender == 'female' ?
                            <Select
                            labelId="shoe-size-label"
                            id="shoe-size-select"
                            value={shoeSize}
                            name="shoeSize"
                            label="Shoe size"
                            onChange={(evt) => setShoeSize(evt.target.value)}
                            >
                                <MenuItem value={"4"}>4 US (2 UK)</MenuItem>
                                <MenuItem value={"4.5"}>4.5 US (2.5 UK)</MenuItem>
                                <MenuItem value={"5"}>5 US (3 UK)</MenuItem>
                                <MenuItem value={"5.5"}>5.5 US (3.5 UK)</MenuItem>
                                <MenuItem value={"6"}>6 US (4 UK)</MenuItem>
                                <MenuItem value={"6.5"}>6.5 US (4.5 UK)</MenuItem>
                                <MenuItem value={"7"}>7 US (5 UK)</MenuItem>
                                <MenuItem value={"7.5"}>7.5 US (5.5 UK)</MenuItem>
                                <MenuItem value={"8"}>8 US (6 UK)</MenuItem>
                                <MenuItem value={"8.5"}>8.5 US (6.5 UK)</MenuItem>
                                <MenuItem value={"9"}>9 US (7 UK)</MenuItem>
                                <MenuItem value={"9.5"}>9.5 US (7.5 UK)</MenuItem>
                                <MenuItem value={"10"}>10 US (8 UK)</MenuItem>
                                <MenuItem value={"10.5"}>10.5 US (8.5 UK)</MenuItem>
                                <MenuItem value={"11"}>11 US (9 UK)</MenuItem>
                                <MenuItem value={"11.5"}>11.5 US (9.5 UK)</MenuItem>
                                <MenuItem value={"12"}>12 US (10 UK)</MenuItem>
                                <MenuItem value={"12.5"}>12.5 US (10.5 UK)</MenuItem>
                                <MenuItem value={"13"}>13 US (11 UK)</MenuItem>
                            </Select>
                        : 
                            <Select
                            labelId="shoe-size-label"
                            id="shoe-size-select"
                            value={shoeSize}
                            name="shoeSize"
                            label="Shoe size"
                            onChange={(evt) => setShoeSize(evt.target.value)}
                            >
                                <MenuItem value={"5"}>5 US (4 UK)</MenuItem>
                                <MenuItem value={"5.5"}>5.5 US (4.5 UK)</MenuItem>
                                <MenuItem value={"6"}>6 US (5 UK)</MenuItem>
                                <MenuItem value={"6.5"}>6.5 US (5.5 UK)</MenuItem>
                                <MenuItem value={"7"}>7 US (6 UK)</MenuItem>
                                <MenuItem value={"7.5"}>7.5 US (6.5 UK)</MenuItem>
                                <MenuItem value={"8"}>8 US (7 UK)</MenuItem>
                                <MenuItem value={"8.5"}>8.5 US (7.5 UK)</MenuItem>
                                <MenuItem value={"9"}>9 US (8 UK)</MenuItem>
                                <MenuItem value={"9.5"}>9.5 US (8.5 UK)</MenuItem>
                                <MenuItem value={"10"}>10 US (9 UK)</MenuItem>
                                <MenuItem value={"10.5"}>10.5 US (9.5 UK)</MenuItem>
                                <MenuItem value={"11"}>11 US (10 UK)</MenuItem>
                                <MenuItem value={"11.5"}>11.5 US (10.5 UK)</MenuItem>
                                <MenuItem value={"12"}>12 US (11 UK)</MenuItem>
                                <MenuItem value={"12.5"}>12.5 US (11.5 UK)</MenuItem>
                                <MenuItem value={"13"}>13 US (12 UK)</MenuItem>
                                <MenuItem value={"13.5"}>13.5 US (12.5 UK)</MenuItem>
                                <MenuItem value={"14"}>14 US (13 UK)</MenuItem>
                                <MenuItem value={"14.5"}>14.5 US (13.5 UK)</MenuItem>
                                <MenuItem value={"15"}>15 US (14 UK)</MenuItem>
                                <MenuItem value={"15.5"}>15.5 US (14.5 UK)</MenuItem>
                                <MenuItem value={"16"}>16 US (15 UK)</MenuItem>
                            </Select>
                        }
                            
                    </FormControl>
                    </div>
                </div>
                
            </div>
            
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit}  />    
        </div>
                 
    )
}