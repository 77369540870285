import { Form, useNavigate,useOutletContext } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"

export default function RunningProfileIntroPage({ progress, section, isAutoComplete, link} ) {

    const navigate = useNavigate()

    const handleSubmit = (evt) => {
        navigate('/shoe-finder/running-profile/experience')
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"First up, let's build up your running profile"} 
                subHeading={"This will include information about your running style, experience and habits."}
                completeTime={"4"} 
                formBodyType={"Intro"}
            />
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit}  />    
        </div>
                 
    )
}