import { useState, useEffect } from "react"
import { ScrollRestoration, redirect, useLocation, useNavigate } from "react-router-dom";
import axios from "axios"
import FlashAlert from "../../../components/Alert/index.jsx";
import { useAppContext } from '../../../utils/ContextProvider.jsx/index.jsx'
import Loader from "../../../components/Loader/index.jsx";
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx";
import HintCard from "../../../components/Dashboard/Cards/HintCard/index.jsx";
import RecommendationCard from "../../../components/Dashboard/Cards/RecommendationCard/index.jsx";
import { Button } from "@mui/material";
import StartIcon from '@mui/icons-material/Start';
import * as Sentry from "@sentry/react";

export default function AllRecommendationsPage() {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl } = useAppContext()

    var buttons = [
        {
            text: 'Create a new recommendation',
            function: () => {return navigate('/shoe-finder/preferences/surface')}
        },
        {
            text: 'Update your running profile',
            function: () => {return navigate('/dashboard/profile')}
        }

    ]

    const navigate = useNavigate()
    const location = useLocation()

    const [user, setUser] = useState({})
    const [partnerDeals, setPartnerDeals] = useState([])

    useEffect(() => {
        setLoading(true)
        const userUrl = `${requestUrl}/api/users`
        const partnerDealsUrl = `${requestUrl}/api/partnerDeals`

        const userRequest = axios({
            url: userUrl,
            method: "GET",
            withCredentials: true
        })

        const partnerDealsRequest = axios({
            url: partnerDealsUrl,
            method: "GET",
            withCredentials: true
        })

        Promise.all([userRequest, partnerDealsRequest])
            .then(([userRes, partnerDealsRes]) => {
                setUser(userRes.data.user)
                setPartnerDeals(partnerDealsRes.data)
                setLoading(false)
            })
            .catch((err) => {
                Sentry.captureException(err)
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong"
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }))
                setLoading(false)
                localStorage.clear('isAuthenticated')
                navigate('/')
            })
    }, [])


    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '100vh'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader
                    heading={`Recommendations (${user?.recommendation?.length})`}
                    buttons={buttons}
                    subHeading={'A list of all of your running shoe recommendations, based on surface type and intended usage.'}
                />
                <div className="container-fluid dashboard-body pb-5" id="recommendationsArea">
                    <div className="container-fluid">
                        <div className="row road-page-header">
                            <div className="col-12 col-xl-10 mx-auto">

                                {/* Recommendation cards */}
                                <div className="row my-3">
                                    {user.recommendation?.map((recommendation, index) => 
                                        <div className="col-12 mx-auto mx-lg-0 col-lg-6 mb-3" style={{height: '100%'}} key={`${recommendation._id}RecommendationCard`}>
                                            <RecommendationCard 
                                                key={user.recommendation._id} 
                                                recommendation={recommendation} 
                                                partnerDeals={partnerDeals}
                                            />
                                        </div>
                                    )}
                                    {user.recommendation?.length == 0 ? 
                                        <div className="col-auto mx-auto mb-3">
                                            <Button
                                                endIcon={<StartIcon />}
                                                variant="contained"
                                                href="/shoe-finder/intro"
                                                >
                                                Create recommendation
                                            </Button>
                                        </div>
                                    : null}
                                </div>
                                

                                {/* <div className="row justify-content-between align-items-center mt-5">
                                    <div className="col-11 col-md-8 mx-auto mx-md-0">
                                        <div className="row">
                                            <div className="col text-center text-md-start">
                                                <h2>Resources</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center text-md-start">
                                                <p className="mb-2 dashboard-text">Additional resources to help you on your buying journey</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}