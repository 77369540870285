var shoeTraitMapping = {
    widthRatingForefoot: 'toebox space',
    shoeShape: 'toebox shape',
    stability: 'support',
    shoeCategory: 'best for',
    shoeSurface: 'surface',
    shoeUsage: 'type of shoe',
    everydayShoeType: 'speciality',
    shoeBrands: 'brands',
    shoeBudget: 'budget',
    speedType: 'speed variant',
    trailSurface: 'trail technicality',
    rockPlate: 'rock plate needed',
    rocker: 'sole curvature',
    shoeLast: 'arch'
}

var shoeValueMapping = {
    shoeLast: {
        straight: 'low',
        'semi-curved': 'normal,high',
        curved: 'normal,high'
    }
}

export { shoeTraitMapping, shoeValueMapping }