import React from 'react';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { useAppContext } from '../../utils/ContextProvider.jsx'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

export default function FlashAlert({ severity = "error", message }) {

  const {setAlertState } = useAppContext()

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setAlertState(false)
  }
  

  return (
      <Snackbar 
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      open={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                setAlertState(false)
              }}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity={severity}
        > {message}
        </Alert>
      </Snackbar>
  );
}