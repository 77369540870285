import { IconButton, Button } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppContext } from '../../../utils/ContextProvider.jsx'

export default function FormFooter ({ isAutoComplete = true, icon = 'bi bi-arrow-right', style = 'primary', text = 'Next', action = 'link', handleSubmit = null, setProcessing=null, isProcessing=null, partner = null }) {

    var { partner, disabledSubmit, setDisabledSubmit } = useAppContext()

    return (
        <div className='container'>
            <div className="row mt-3 align-items-center" id="buttonSubmitRow">
                <div className="col text-start px-0">
                    <IconButton
                        size='large'
                        onClick={() => window.history.back()}
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </div>
                {isAutoComplete === false ? <div className="col text-end">
                    <Button
                        variant='contained' 
                        onClick={handleSubmit}
                        disabled={isProcessing == true ? true : disabledSubmit? true : false}
                        endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <NavigateNextIcon />}
                        style={{backgroundColor: partner ? partner.primary : null}}
                    >
                        Next
                    </Button>
                </div>  : null }           
            </div>
            {partner? 
            <div className='row align-items-center justify-content-center mt-5'>
                <div className="col-auto">
                    <p className='my-0'>Powered by</p>
                </div>
                <div className="col-auto">
                    <img className='img img-fluid mx-2' src="/logos/logo-primary.svg" alt="" />
                </div>
            </div>  
            : null}
        </div>
         

        
    )
}