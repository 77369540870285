import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import { preferencesOptions } from "../../../../utils/PreferencesOptions"

export default function UsagePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, [evt.target.name]: evt.target.value}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        if (evt.target.value === 'speed') {
            navigate('/shoe-finder/preferences/speed_type')
        } else if (evt.target.value === 'everyday') {
            navigate('/shoe-finder/preferences/feel')
        } else {
            navigate('/shoe-finder/preferences/brand')
        }
        
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"I'm looking for a shoe that is...."} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={preferencesOptions.shoeUsage}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}