import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';

var environment = process.env.NODE_ENV || 'development'
var mixpanelToken = null
var requestUrl = null

if (environment !== "production") {
    var mixpanelToken = process.env.REACT_APP_DEV_MIXPANEL_TOKEN
    var requestUrl = "http://localhost:4000"
} else {
    var mixpanelToken = process.env.REACT_APP_PROD_MIXPANEL_TOKEN
    var requestUrl = "https://fittir.io"
}

mixpanel.init(mixpanelToken, { track_pageview: true, persistence: 'localStorage'});

const AppContext = createContext();

export const AppProvider = ( { children }) => {

    const [sessionUser, setSessionUser] = useState({})

    const [userShoes, setUserShoes] = useState([])

    const [userLocation, setUserLocation] = useState({})

    const [userIp, setUserIP] = useState("")

    const [isAlert, setIsAlert] = useState({
        error: false,
        success: false
    })

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)

    useEffect(() => {

        async function getUserIP() {
            try {
              const response = await fetch('https://api.ipify.org?format=json');
              const data = await response.json();
              setUserIP(data.ip);
            } catch (error) {
              console.error("Error fetching IP:", error);
            }
          }
      
          async function getUserLocation() {
            try {
              const response = await fetch(`https://ipinfo.io/json?token=ae921cbe98766c`);
              const data = await response.json();
              setUserLocation({
                city: data.city,
                region: data.region,
                country: data.country,
              });
            } catch (error) {
              console.error("Error fetching location:", error);
            }
          }
      
          getUserIP();
          getUserLocation();

    },[])

    const setAlertState = ( state ) => {
        setIsAlert(isAlert => {
            return {
                ...isAlert,
                error: state,
                success: state
            }
        })
    }

    const [disabledSubmit, setDisabledSubmit] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const setLoading = ( state ) => {
        setIsLoading(state)
    }

    const [isFiltering, setIsFiltering] = useState(false)

    const [isProcessing, setIsProcessing] = useState(false)
    const setProcessing = ( state ) => {
        setIsProcessing(state)
    }

    var [partner, setPartner] = useState()

    // Functions for Mixpanel
    const trackEvent = (eventName, params) => {mixpanel.track(eventName, params)}

    const value = {
        isAlert,
        setIsAlert,
        setAlertState,
        isLoading,
        setLoading,
        trackEvent,
        isProcessing,
        setProcessing, 
        disabledSubmit,
        setDisabledSubmit,
        requestUrl,
        isFiltering,
        setIsFiltering,
        environment,
        partner, 
        setPartner,
        sessionUser,
        setSessionUser,
        userShoes,
        setUserShoes,
        userIp,
        userLocation,
        upgradeModalOpen,
        setUpgradeModalOpen
    }



    return <AppContext.Provider value={value}> {children} </AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext);