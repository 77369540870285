import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";


export default function RecommendationToolTip({ recommendation = null, variable = null, icon = <InfoOutlinedIcon/>, openRecommendationModal = null}) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, trackEvent, requestUrl } = useAppContext()
    
    var string = `Recommended: ${recommendation.requirements[variable].traits.length > 0 ? recommendation.requirements[variable].traits.join(', ') : Object.entries(recommendation.requirements[variable].traits).map((elem) => `${elem[0]}: ${elem[1]}`).join(', ')}`

    const handleClick = (evt, variable) => {
        trackEvent('View recommendation reasoning', {route: 'compareShoesPage'})
        openRecommendationModal(evt, variable)
    }

    return (
        <Tooltip
            title={<span>{string} <br /> <a style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => handleClick(evt, variable)}>Learn more</a></span>}
            arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000}
            >
            {icon}
        </Tooltip>
    )
}