import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "Yes",
        subHeading: null,
        name: "isInjury",
        value: true

    },
    {
        title: "No",
        subHeading: null,
        name: "isInjury",
        value: false

    }
]

export default function InjuryRepeatYesNoPage({ progress, section, isAutoComplete} ) {

    const navigate = useNavigate()

    const handleClick = (evt) => {
        var name = evt.target.name
        var value = evt.target.value
        if (!value) {
            navigate('/shoe-finder/running-profile/tightness')
        } else {
            navigate('/shoe-finder/running-profile/injury-type')
        }
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Have you had another injury from running with the past year?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
                toolTip={"An injury is considered as something that has stopped you from being able to run."}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}