import { testimonials } from '../../../utils/Testimonials'
import Avatar from '@mui/material/Avatar'
import './index.css'
import { useEffect, useRef, useState } from 'react'
import Rating from '@mui/material/Rating'
import { useInView } from 'react-intersection-observer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSection({ backgroundColor = '#fff' }) {
    const wrapperRef = useRef(null);
    const [count, setCount] = useState(0);
    const { ref: sectionRef, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true, // Enable navigation arrows
      }

    useEffect(() => {
        if (wrapperRef.current) {
            const wrapper = wrapperRef.current;
            const testimonialBoxes = wrapper.querySelectorAll('.testimonial-box');
            const totalWidth = Array.from(testimonialBoxes).reduce((sum, box) => sum + box.offsetWidth + parseInt(getComputedStyle(box).marginRight), 0);
            
            wrapper.style.width = `${totalWidth}px`;
            
            // Clone testimonials for seamless loop
            testimonialBoxes.forEach(box => {
                const clone = box.cloneNode(true);
                wrapper.appendChild(clone);
            });

            // Set animation duration based on content width
            const animationDuration = totalWidth / 50; // Adjust 50 to change speed
            wrapper.style.animationDuration = `${animationDuration}s`;
        }
    }, [testimonials]);

    useEffect(() => {
        if (inView && count < 3298) {
            const timer = setTimeout(() => {
                setCount(prevCount => Math.min(prevCount + 100, 3298));
            }, 20);
            return () => clearTimeout(timer);
        }
    }, [count, inView]);

    return (
        <section className="testimonial-section my-5 py-3" ref={sectionRef}>
            <div className="container-fluid mb-5">
                <div className="col-12 col-md-10 mx-auto">

                    <div className="row text-center mt-4">
                        <div className="col-11 col-md-8 mx-auto">
                            <h2 style={{fontSize: '2rem'}}>
                                Hear from some of the <span style={{fontWeight: 'bold', color: '#1F8bF4'}}>{count.toLocaleString()}</span> other happy runners who have used Fittir...
                            </h2>
                        </div>
                    </div>

                </div>
                <div className="testimonial-container mt-4 d-none d-md-block">
                    <div className="testimonial-wrapper" ref={wrapperRef}>
                        {testimonials.map((testimonial, index) => (
                            <div className="testimonial-box" key={index}>
                                <div className="row">
                                    <div className="col-12 mx-2 my-2 py-4 px-2 px-md-5 text-center" style={{border: '1px solid #ccc', borderRadius: '1em', backgroundColor: 'white', minHeight: '400px'}}>

                                        <div className="row mt-3">
                                            <div className="col-8 col-md-7 mx-auto text-center">
                                                <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${testimonial.imageSlug}.png`} alt={testimonial.shoeSlug} />
                                            </div>
                                        </div>                           
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <h4 className="mt-3">{testimonial.name} - {testimonial.shoe} 🎉</h4>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <Rating value={testimonial.starRating} precision={0.5} readOnly />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="mt-3"><i>“{testimonial.quote}”</i></p>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-auto mx-auto">
                                                <Avatar alt={testimonial.name} src={`/testimonials/${testimonial.nameSlug}.png`} />
                                            </div>
                                        </div>

    
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container d-md-none">
                    <div className="row">
                        <Slider {...settings}>
                            {testimonials.map((testimonial, index) => (
                                    <div className="text-center" style={{border: '1px solid #ccc', borderRadius: '1em', backgroundColor: 'white'}}>
                                        <div className="row mt-3 justify-content-center">
                                            <div className="col-8 col-md-6 text-center">
                                                <img className="img img-fluid" style={{display: 'inline-block'}} src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${testimonial.imageSlug}.png`} alt={testimonial.shoeSlug} />
                                            </div>
                                        </div>                           
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <h4 className="mt-3">{testimonial.name} - {testimonial.shoe} 🎉</h4>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <Rating value={testimonial.starRating} precision={0.5} readOnly />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="mt-3"><i>“{testimonial.quote}”</i></p>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-auto mx-auto">
                                                <Avatar alt={testimonial.name} src={`/testimonials/${testimonial.nameSlug}.png`} />
                                            </div>
                                            </div>

    
                                        </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
