import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function ProcessingBackdrop({ isProcessing = null, carousel = null }) {
    const settings = {
      infinite: carousel && carousel.length > 1,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };
  
    return (
      <div>
        <Backdrop
          sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isProcessing}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" />
            {carousel && carousel.length > 0 && (
              <Box sx={{ mt: 2, width: '80%', maxWidth: '600px' }}>
                <Slider {...settings}>
                  {carousel.map((slide, index) => (
                    <div key={index}>
                      <Box sx={{ textAlign: 'center', padding: 2 }}>
                        {slide.content}
                      </Box>
                    </div>
                  ))}
                </Slider>
              </Box>
            )}
          </Box>
        </Backdrop>
      </div>
    );
  }
