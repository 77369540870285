import { useState, useEffect } from "react"
import { Button } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppContext } from "../../../../utils/ContextProvider.jsx/index"
import axios from "axios"
import DashboardHeader from "../../../../components/Dashboard/Header/index.jsx"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"

export default function WebsiteReviewPage () {

    var navigate = useNavigate()

    var { websiteReview, unprocessedReviews } = useLocation().state || {}

    var [ websiteReview, setWebsiteReview ] = useState(websiteReview)

    var [ reviewContent, setReviewContent ] = useState('')

    var [ reviewScore, setReviewScore ] = useState('')

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading, isProcessing, setProcessing } = useAppContext()

    const handleSubmit = (evt) => {
        var url = `${requestUrl}/api/websiteReviews/${websiteReview._id}`
        setProcessing(true)
        axios({
            url: url,
            method: 'PUT',
            withCredentials: true,
            data: {
                reviewContent: reviewContent,
                reviewScore: reviewScore,
                processed: true
            }
        })
        .then((res) => {
            var index = unprocessedReviews.indexOf(websiteReview)
            if (index !== -1) {
                var updatedUnprocessedReviews = unprocessedReviews.slice(index + 1);
                var nextReview = updatedUnprocessedReviews[0];
            }
            setWebsiteReview(nextReview)
            setReviewContent("")
            setReviewScore("")
            setProcessing(false)
            navigate(`/admin/reviews/${nextReview._id}`, {state: { websiteReview: nextReview , unprocessedReviews: updatedUnprocessedReviews }})
        })
        .catch((err) => {
            console.log(err)
        })
        
    }
    
    const handleDelete = (evt) => {
        var url = `${requestUrl}/api/websiteReviews/${websiteReview._id}`
        setProcessing(true)
        axios({
            url: url,
            method: 'DELETE',
            withCredentials: true,
        })
        .then((res) => {
            var index = unprocessedReviews.indexOf(websiteReview)
            if (index !== -1) {
                var updatedUnprocessedReviews = unprocessedReviews.slice(index + 1);
                var nextReview = updatedUnprocessedReviews[0];
            }
            setWebsiteReview(nextReview)
            setReviewContent("")
            setReviewScore("")
            setProcessing(false)
            if (nextReview) {
                navigate(`/admin/reviews/${nextReview._id}`, {state: { websiteReview: nextReview , unprocessedReviews: updatedUnprocessedReviews }})
            } else {
                navigate(`/admin/reviews/`)
            }
            
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleSkip = () => {
        var index = unprocessedReviews.indexOf(websiteReview);
        if (index !== -1) {
            var nextReview = unprocessedReviews[index + 1];
            setWebsiteReview(nextReview)
        }
        if (nextReview) {
            navigate(`/admin/reviews/${nextReview._id}`, {state: { websiteReview: nextReview , unprocessedReviews: unprocessedReviews }})
        } else {
            navigate(`/admin/reviews/`)
        }
    }

    return (
        <div>
            <DashboardHeader
                    heading={`Review: ${websiteReview._id}`}
                    subHeading={`Shoe: ${websiteReview.shoe[0].brand.name} ${websiteReview.shoe[0].model} ${websiteReview.shoe[0].version}`}
                    breadcrumb={[
                        {
                            title: 'Reviews',
                            href: '/admin/reviews'
                        },
                        {
                            title: 'Process'
                        }
                    
                    ]}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <a href={websiteReview?.url} target="_blank">{websiteReview?.url}</a>
                        <iframe src={websiteReview?.url} width="100%" height="700px"></iframe>
                    </div>
                    <div className="col px-4 py-4" style={{backgroundColor: 'white', border: '1px solid white', borderRadius: '0.5em'}}>
                        <div className="row">
                            <div className="col">
                                <h2>Review information</h2>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <TextField    
                                    fullWidth
                                    id="reviewRating"
                                    label="Review score"
                                    value={reviewScore}
                                    defaultValue={0}
                                    onChange={(evt) => setReviewScore(evt.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <TextField    
                                    fullWidth
                                    id="reviewContent"
                                    label="Review content"
                                    value={reviewContent}
                                    multiline
                                    rows={15}
                                    onChange={(evt) => setReviewContent(evt.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mt-4 justify-content-end">
                            <div className="col-auto px-2">
                                <Button
                                onClick={handleSkip}
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                variant="link"
                                >Skip</Button>
                            </div>
                            <div className="col-auto px-2">
                                <Button
                                onClick={handleDelete}
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                variant="outlined"
                                >Delete</Button>
                            </div>
                            <div className="col-auto px-2">
                                <Button
                                variant="contained"
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                onClick={handleSubmit}
                                >Update</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>

        
 
    )
}