import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"

export default function PreferencesIntroPage({ progress, section, isAutoComplete, link} ) {

    const navigate = useNavigate()

    const handleSubmit = (evt) => {
        navigate('/shoe-finder/preferences/surface')
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Finally, let's figure out what type of running shoe you are looking for.."} 
                subHeading={"This will include information to help us narrow down on the perfect shoe, including price, brand, surface and intended use."}
                completeTime={"2"} 
                formBodyType={"Intro"}
            />
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit}  />    
        </div>
                 
    )
}