import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import ChipComponent from '../../../../components/Chip';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Take the questionnaire',
    description:
      "Spend 5 minutes telling us about your goals, habits, how you run, your shoe preferences and what you intend to use the shoe for. We'll help guide you through the tricky parts. ",
      imageURL: '/prototype/features/prototype_1.png'
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Get your shoe recommendation',
    description:
      "We combine all your information and sort through all the shoes available to find your perfect match based on your needs and preferences.",
      imageURL: '/prototype/features/prototype_2.png'
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Compare and decide',
    description:
      "We'll let you know why some shoes suit you, and why others don't. You can compare all the available options based on what's important to you.",
    imageURL: '/prototype/features/prototype_3.png'
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
        <div className="col">
            <div className="row align-items-center">
                {/* List column */}
                <div className="col-11 mx-auto col-md-6">
                <Grid item xs={12} md={6}>
                    <div>
                        <div className="row mb-3">
                            <div className="col-auto">
                                <ChipComponent 
                                color='primary'
                                label='Free-to-use'
                                size='small'
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h2>Fittir helps you make the right decission, the first time.</h2>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <p>Get matched to your perfect running shoe in a matter of minutes based on your needs, preferences and reviews from experienced runners similar to you.</p>
                            </div>
                        </div>
                    </div>
                    <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                        <div className="row justify-content-center mb-3">
                        {items.map(({ title }, index) => (
                            <div className="col-auto px-1 py-1" key={`${index}FeatureChip`}>
                                        <Chip
                                    key={index}
                                    label={title}
                                    onClick={() => handleItemClick(index)}
                                />
                               
                            </div>
                         ))}
                        </div>
                        
                    </Grid>
                        <Box
                            component={Card}
                            sx={{
                            display: { xs: 'auto', sm: 'none' },
                            }}
                        >
                                <Box sx={{ px: 2, pb: 2 }}>
                                    <div className="row my-4">
                                        <div className="col-7 my-3 text-center mx-auto">
                                            <img className='img' src={items[selectedItemIndex].imageURL} alt="" />
                                        </div>
                                    </div>
                                    <h4 className="my-3">{selectedFeature.title}</h4>
                                    <p>{selectedFeature.description}</p>
                                </Box>
                                </Box>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}
                                    useFlexGap
                                    sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                                        >
                                    {items.map(({ icon, title, description }, index) => (
                                    <Card
                                        key={index}
                                        variant='outlined'
                                        component={Button}
                                        onClick={() => handleItemClick(index)}
                                        sx={{
                                            p: 3,
                                            height: 'fit-content',
                                            width: '100%',
                                            background: 'none',
                                            backgroundColor:
                                              selectedItemIndex === index ? 'action.selected' : undefined,
                                            borderColor: (theme) => {
                                              if (theme.palette.mode === 'light') {
                                                return selectedItemIndex === index
                                                  ? 'primary.light'
                                                  : 'grey.200';
                                              }
                                              return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                                            },}}
                                        

                                    >
                                        <div className="row align-items-center py-1">
                                            <div className="col-auto">
                                                {icon}
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col text-start">
                                                        <h4 style={{textTransform: 'none'}}>{title}</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col text-start">
                                                        <p style={{textTransform: 'none'}}>{description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                        ))}
                                    </Stack>
                                            </Grid>
                                            </div>

                                            {/* Picture column */}
                                            <div className="col-11 text-center mx-auto col-md-6">
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
                                                >
                                                    <div className="col-10 pt-4 mx-auto text-center text-md-end">
                                                        <img className='img img-fluid' src={items[selectedItemIndex].imageURL} alt="" />
                                                    </div>
                                                </Grid>
                                            </div>
                                        </div>
        </div>
        
  );
}