import Card from '@mui/material/Card';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Checkbox, Avatar, AvatarGroup, Typography } from '@mui/material';
import ChipComponent from '../../../Chip';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Tooltip from '@mui/material/Tooltip';
import Rating from '@mui/material/Rating';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import StarRateIcon from '@mui/icons-material/StarRate';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAppContext } from '../../../../utils/ContextProvider.jsx';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import WidthWideIcon from '@mui/icons-material/WidthWide';
import { createEvent } from '../../../../utils/CreateEvent/index.js';
import VerifiedIcon from '@mui/icons-material/Verified';
import { shoeTraitMapping, shoeValueMapping } from '../../../../utils/ValueMapping/index.js';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function ShoeCard({ recommendationId = null, shoe = null, preferences = null, currentShoe = null, requirements = null, partnerDeals = null, toggleShoe = null, selectedShoes = null, notRec = false, reviews = [] }) {

    var [ user ] = useOutletContext()
    var { trackEvent, requestUrl, sessionUser } = useAppContext()

    var [ shoePartnerDeals, setShoePartnerDeals ] = useState([])

    var [ shoeReviews, setShoeReviews ] = useState([])

    var colorwayIndex = shoe.shoe.colorways?.findIndex((colorway) => 
        colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption))
    )

    if (colorwayIndex === -1) {
        colorwayIndex = 0;
    }

    // Initialize the state for shoeColorway properly
    const [shoeColorway, setShoeColorway] = useState(shoe.shoe.colorways?.[colorwayIndex] || null);

    var [availableColors, setAvailableColors] = useState([])

    useEffect(() => {
        if (partnerDeals && partnerDeals.length > 0) {
            const filteredDeals = partnerDeals.filter((deal) =>
                deal.shoe.toString() === shoe.shoe._id.toString() &&
                (deal.partner.countries.length === 0 || // Check if countries array is empty
                (sessionUser.location ? deal.partner.countries.includes(sessionUser.location?.country) : true))
            );
            setShoePartnerDeals(filteredDeals);
        }
    }, [partnerDeals, sessionUser, shoe.shoe._id]);

    // useEffect to set initial shoe colorway only when component mounts
    useEffect(() => {
        if (shoe.shoe.colorways?.length > 0) {
            // Sort colorways based on deals and width availability
            const sortedColorways = shoe.shoe.colorways.sort((a, b) => {
                const hasDealA = shoePartnerDeals?.some(deal => deal.shoeColorway.toString() === a._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))) ? 1 : 0;
                const hasDealB = shoePartnerDeals?.some(deal => deal.shoeColorway.toString() === b._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))) ? 1 : 0;
                const widthAvailableA = shoe.shoe.shoeShape === 'foot shaped' || a?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));
                const widthAvailableB = shoe.shoe.shoeShape === 'foot shaped' || b?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));

                if (hasDealA && widthAvailableA !== hasDealB && widthAvailableB) {
                    return hasDealB - hasDealA;
                }
                if (widthAvailableA !== widthAvailableB) {
                    return widthAvailableB - widthAvailableA;
                }
                if (hasDealA !== hasDealB) {
                    return hasDealB - hasDealA;
                }

                return 0;
            });

            // Set the first colorway from the sorted list
            setShoeColorway(sortedColorways[0]);
            setAvailableColors(sortedColorways);
        }
    }, [shoe.shoe.colorways, shoePartnerDeals, requirements.width.traits, shoe.shoe._id, shoe.shoe.shoeShape]);

    useEffect(() => {
        var shoeReviews = reviews?.filter((review) => review?.shoeId.toString() === shoe?.shoe?._id.toString())
        console.log(reviews)
        setShoeReviews(shoeReviews)
    }, [reviews, shoe.shoe._id])

    const navigate = useNavigate();

    const handleClick = (evt, tabIndex) => {
        const url = `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`;
        navigate(url, {
            state: {
                shoe: shoe,
                preferences: preferences,
                requirements: requirements,
                shoeColorway: shoeColorway,
                tabIndex: tabIndex,
                shoePartnerDeals: shoePartnerDeals,
            },
        });
    };

    const handleFindRetailer = () => {
        trackEvent('Find a retailer', {
            shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`,
        });

        createEvent({
            action: 'search_google_for_retailer',
            category: 'interaction',
            data: {
                shoeId: shoe?.shoe._id,
                colorwayId: shoeColorway?._id,
                recommendationId: recommendationId,
            }
        })

        const url = `https://www.google.com/search?q=buy+${encodeURIComponent(shoe.shoe.brand.name || '')}+${encodeURIComponent(
            (shoe.shoe.model || '').charAt(0).toUpperCase() + shoe.shoe.model?.slice(1) || ''
        )}+${encodeURIComponent(shoe.shoe.version || '')}`;

        axios({
            url: `${requestUrl}/api/users/email/running-tips`,
            method: 'POST',
            withCredentials: true,
        });

        window.open(url, '_blank');
    };

    const handlePartnerDealClick = (deal) => {
        console.log(deal)
        trackEvent('View partner deal', {partner: deal?.partner.name, shoeName: `${shoe?.shoe.brand.name} ${shoe?.shoe.model} ${shoe?.shoe.version}`})
        createEvent({
            action: 'view_partner_deal',
            category: 'interaction',
            data: {
                partner: deal?.partner.name,
                shoeId: shoe?.shoe._id,
                colorwayId: shoeColorway?._id,
                recommendationId: recommendationId,
                partnerDeal: deal,
                price: deal?.price,
                specialPrice: deal?.specialPrice ? deal?.specialPrice : null
            }
        })
    }

    const createdAtTimestamp = shoe.shoe.createdAt ? new Date(shoe.shoe.createdAt).getTime() : -Infinity;
    const days30AgoTimestamp = new Date(Date.now() - 45 * 24 * 60 * 60 * 1000); // 30 days ago


    // Check if `createdAt` is within the last 180 days
    const isNewModel = createdAtTimestamp >= days30AgoTimestamp.getTime() && createdAtTimestamp <= Date.now();

    // Determine the label based on the version
    const isNewVersion = shoe.shoe.version !== 'v1' && shoe.shoe.version !== '1' && shoe.shoe.version !== '001';
    const label = isNewVersion ? "New version" : "New model";

    const handleColorwayChange = (evt, colorway) => {
        setShoeColorway(colorway)
        createEvent({
            action: 'view_shoe_colorway',
            category: 'interaction',
            data: {
                shoeId: shoe.shoe._id,
                colorwayId: colorway._id,
                page: 'recommendation',
                recommendationId: recommendationId,   
            }
        })
    }

    // Function to sort deals based on the user's size availability
    const sortDealsBySizeAvailability = (deals, user) => {
        return deals.sort((a, b) => {
            const userSize = user?.runningProfile.shoeSize;

            const aHasSize = a.sizes?.some(sizeObj => sizeObj.size === userSize);
            const bHasSize = b.sizes?.some(sizeObj => sizeObj.size === userSize);

            const aHasNoSizingInfo = !a.sizes || a.sizes.length === 0;
            const bHasNoSizingInfo = !b.sizes || b.sizes.length === 0;

            if (aHasSize && !bHasSize) return -1;
            if (!aHasSize && bHasSize) return 1;
            if (aHasNoSizingInfo && !bHasNoSizingInfo) return -1;
            if (!aHasNoSizingInfo && bHasNoSizingInfo) return 1;

            return 0;
        });
    };

    // Use the function to sort the deals
    const sortedDeals = sortDealsBySizeAvailability(shoePartnerDeals, user);

    return (
        <Card 
            key={shoe.shoe_id}
            style={currentShoe ? { border: "2px solid #1F8BF4" }: null}
            className='px-2'
            >
            {/* Match pill */}
            {shoeReviews.length > 0 && shoeReviews.some(review => review.rating >= 5) ?
            (() => {
                const highRatedReviews = shoeReviews.filter(review => review.rating >= 5);
                return (
                    <div className="row mt-4 align-items-center">
                        <div className="col-8 mx-auto">
                            <div className="row">
                            <div 
                            className="col-auto mx-auto text-center px-3 py-2" 
                            onClick={(evt) => handleClick(evt, 2)} 
                            style={{
                                cursor: 'pointer',
                                border: '2px solid #1976d2',
                                borderRadius: '1em',
                                backgroundColor: '#f5f9ff',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                animation: 'wave 3s infinite', // Changed to wave animation
                            }}
                        >
                        <style>
                            {`
                                @keyframes wave {
                                    0% {
                                        background-position: 0% 50%;
                                    }
                                    50% {
                                        background-position: 100% 50%;
                                    }
                                    100% {
                                        background-position: 0% 50%;
                                    }
                                }
                                .col-6 {
                                    background: linear-gradient(270deg, #f5f9ff, #e0f2ff);
                                    background-size: 400% 400%;
                                }
                            `}
                        </style>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AvatarGroup max={3} sx={{ marginRight: 1 }}>
                                    {highRatedReviews.slice(0, 3).map((review, index) => (
                                        <Avatar 
                                            key={index} 
                                            alt={review.userId.name}
                                            src={review.userId.profileImage}
                                            sx={{ 
                                                width: 36, 
                                                height: 36,
                                                backgroundColor: '#1976d2',
                                                border: '2px solid #fff' // Added white border around avatars
                                            }}
                                        >
                                            {review.userId.name.charAt(0)}
                                        </Avatar>
                                    ))}
                                </AvatarGroup>
                                <Typography 
                                    className='mt-1' 
                                    variant="body2"
                                    color="#1976d2"
                                >
                                    {highRatedReviews.length > 3 
                                        ? `Recommended by ${highRatedReviews.slice(0, 3).map(review => review.userId.name).join(', ')} and ${highRatedReviews.length - 3} other runners  `
                                        : `Recommended by ${highRatedReviews.map(review => review.userId.name).join(', ')}  `
                                    }
                                    <ThumbUpOffAltIcon className='mb-1'/>
                                </Typography>
                            </div>
                        </div>

                            </div>

                        </div>
                        
                    </div>
                );
            })()
            : null}
             <div key={`${shoe.shoe._id}_match_pil`} className="position-relative p-3 mt-1 mb-1">
             <div className='row justify-content-end'>
                 <div className='col-auto px-1'>
                     <Checkbox 
                     checked={selectedShoes.some(selectedShoe => selectedShoe.shoe._id == shoe.shoe._id)}
                     onChange={(evt) => toggleShoe(evt, shoe)} 
                     />
                 </div>
             </div>    
             {/* Centered Chip */}
             <div className="position-absolute" style={{ top: '22px', left: '50%', transform: 'translateX(-50%)' }}>
                 <Tooltip 
                     arrow 
                     disableFocusListener 
                     enterTouchDelay={0} 
                     leaveTouchDelay={5000}
                     interactive // Allow the tooltip to be interactive
                     title={
                         <span>
                             {(() => {
                                 // Group criteria by category, using 'Uncategorized' as a default
                                 const criteriaByCategory = shoe.criteria.reduce((acc, criteria) => {
                                     const category = requirements[criteria.trait].reasoning[0]?.category || 'Uncategorized';
                                     if (!acc[category]) {
                                         acc[category] = [];
                                     }
                                     acc[category].push(criteria);
                                     return acc;
                                 }, {});

                                 // Filter to only include 'requirement' and 'preference' categories
                                 const filteredCategories = Object.keys(criteriaByCategory).filter(category => 
                                     category === 'requirement' || category === 'preference'
                                 );

                                 // Render criteria sorted by category
                                 return filteredCategories.map((category) => (
                                     <div key={`${category}Category`} className="category-group">
                                         <h6 className=' mb-0 mt-1'>{category === 'requirement' ? 'Running style' : 'Intended use'}</h6>
                                         {criteriaByCategory[category].sort((a, b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                             var string = criteria.trait.split(/(?=[A-Z])/);
                                             var finalString = shoeTraitMapping[criteria.trait] || string.join(' ').toLowerCase();
                                             if (requirements[criteria.trait].reasoning.length > 0) {
                                                 return (
                                                     <div key={`${criteria.trait}TraitRow`} className="row">
                                                         <div className="col">
                                                             {criteria.pass === 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {
                                                                 shoeValueMapping[criteria.trait]?.[shoe.shoe[criteria.trait]] || 
                                                                 (Array.isArray(shoe.shoe[criteria.trait]) ? 
                                                                     shoe.shoe[criteria.trait].join(', ') : 
                                                                     shoe.shoe[criteria.trait])
                                                             } {
                                                                 criteria.pass === "FALSE" ? 
                                                                 `(Recommended: ${
                                                                     requirements[criteria.trait].traits.length > 0 ? 
                                                                         requirements[criteria.trait].traits
                                                                             .map(trait => shoeValueMapping[criteria.trait]?.[trait] || trait)
                                                                             .join(', ') 
                                                                         : 
                                                                     Object.entries(requirements[criteria.trait].traits)
                                                                         .map((elem) => `${elem[0]}: ${shoeValueMapping[criteria.trait]?.[elem[1]] || elem[1]}`)
                                                                         .join(', ')
                                                             })` 
                                                             : ""
                                                         }
                                                         </div>
                                                     </div>
                                                 );
                                             }
                                         })}
                                     </div>
                                 ));
                             })()}
                             <a style={{color: 'white', cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => handleClick(evt, 1)}>Learn more</a>
                         </span>
                     }
                 >
                     <span>
                         <ChipComponent
                             icon={
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                             }
                             label={!notRec ? `${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match` : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'Great profile match' : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'Average profile match' : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'Average profile match' : 'Poor profile match' 
                                    }
                             color={
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                             }
                             variant={(shoe.score / shoe.maxScore) < 1.0 ? 'outlined' : null}
                             href={`/dashboard/recommendations/${recommendationId}/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}#matching-information`}
                         />
                     </span>
                 </Tooltip>
             </div>


            </div>

           




            {/* Brand image */}
            <div key={`${shoe.shoe._id}_brandImage=`} className="row">
                <div className="col-9 mx-auto text-center">
                    <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoe.brand.slug}.png`} alt="" />
                </div>
            </div>


            {/* Shoe image */}
            <div className="row pt-2">
                <div className="col-12 mx-auto text-center">
                    <a href={!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` : `/dashboard/browse/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` }><img  style={{cursor: 'pointer'}} onClick={handleClick} loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${shoeColorway?.colorwayCode}.png`} alt="" /></a>
                </div>
            </div>

            <CardContent className='py-3'>



            
            

            

            <div className="row mt-2 px-2 justify-content-center justify-content-md-start">
                {availableColors.map((colorway, index) => {
                    const widthAvailable = shoe.shoe.shoeShape === 'foot shaped' || colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));
                    return (
                        <div 
                            className="col-2 mx-1 my-1" 
                            style={{
                                cursor: widthAvailable ? 'pointer' : 'auto', 
                                position: 'relative', 
                                opacity: widthAvailable ? '1' : '0.3'
                            }} 
                            key={`${shoe.shoe._id}Colorway${index}`} 
                            onClick={(evt) => handleColorwayChange(evt, colorway)}
                        >
                            {!widthAvailable && (
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'linear-gradient(to bottom right, transparent 49%, #D9D9D9 50%, transparent 51%)',
                                    pointerEvents: 'none'
                                }} />
                            )}
                            <Tooltip title={widthAvailable ? `${colorway.colorwayName} (${colorway.color})` : `${colorway.colorwayName} (${colorway.color}) (not available in ${requirements.width.traits.join(' or ')})`}>
                                <div className="row">
                                    <div className="col py-1 py-xl-2" style={{
                                        border: shoeColorway.colorwayCode === colorway.colorwayCode ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') : '1px solid #D9D9D9', 
                                        borderRadius: '0.5em'
                                    }}>
                                        <img className='img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} alt="" />
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    )
                })}
            </div>
            

<div className="row mt-2">
    {sortedDeals.length > 0 ? (
        sortedDeals
            .filter(deal => 
                deal.shoeColorway.toString() === shoeColorway?._id?.toString() &&
                (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))
            )
            .slice(0, 1) // Only show the first one after it is sorted
            .map((deal, index) => (
                <div key={index} className="col mx-2 py-3 px-3" style={{border: '1px solid #687393', borderRadius: '0.5em'}}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <Avatar src={`/logos/partners/${deal.partner.slug}_icon.svg`} alt={deal.partner.name} />
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-auto">
                                    <h4 className='my-0'>
                                       View at {deal.partner.name} <VerifiedIcon color='primary' fontSize='small'/>
                                    </h4>
                                </div>
                            </div>
                            
                            <div className="row align-items-center mt-1">
                                {deal.sizes && deal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0) ? 
                                <div className="col-auto">
                                    <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#2E7d43'}}>
                                        <InfoOutlinedIcon fontSize='inherit' className='me-1'/> Your size in stock 
                                    </p>
                                </div>
                                : 
                                deal.sizes && deal.sizes.length > 0 && !deal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0) ? 
                                <div className="col-auto">
                                    <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#ed6c02'}}>
                                        <CancelOutlinedIcon fontSize='inherit' className='me-1'/> No stock in your size
                                    </p>
                                </div>
                                : 
                                <div className="col-auto">
                                    <p className='my-0 d-flex align-items-center' style={{fontSize: '1em'}}>
                                        <InfoOutlinedIcon fontSize='inherit' className='me-1'/> Check website for sizes 
                                    </p>
                                </div>}
                                {deal.specialPrice ? 
                                <div className="col-auto">
                                    <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#d32f2f'}}>
                                        <WhatshotIcon fontSize='inherit' className='me-1'/> Reduced price     
                                    </p>
                                </div>
                                : null}
                                {deal.partner.coupon && (
                                    <div className="col-auto">
                                        <p className='my-0 d-flex align-items-center' style={{fontSize: '1em', color: '#d32f2f'}}>
                                            <CelebrationIcon fontSize='inherit' className='me-1'/> Save {deal.partner.coupon.discountPercent}% with code {deal.partner.coupon.code}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="row mt-1">
                                <div className="col-auto">
                                    <a className='my-0' style={{color: 'black', cursor: 'pointer', textDecoration: 'none'}} href={deal.affiliateLink || `${deal.partner.baseUrl}${deal.urlKey}${deal.partner.referralUrlString}`} target="_blank" onClick={() => handlePartnerDealClick(deal)}>Visit website <ArrowRightAltIcon fontSize='small'/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
    ) : null}
</div>




            <div className="row mt-2">

            {/* Label (new, best for, most cushioned etc) */}
            {currentShoe ?
                
                    <div className="col-auto px-2">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label="Owned"
                            />
                    </div>          
                : 
                null
            }
            
            {/* {shoePartnerDeals?.some(deal => deal.shoeColorway.toString() == shoeColorway?._id?.toString() && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal )) ?
                <div className="col-auto px-2">
                    <ChipComponent 
                        color='success'
                        size='small'
                        label="Retail offers"
                        icon={<AttachMoneyIcon/>}
                        />
                </div>  
            : null} */}

            {isNewModel ? (
                    <div className="col-auto px-2">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label={label}
                        />
                    </div>
            ) : null}

            {shoe?.shoe.shoeShape == 'foot shaped' ? (
                    <div className="col-auto px-2">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label={`Great for wide feet (foot shaped)`}
                            icon={<WidthWideIcon/>}
                        />
                    </div>
            ) : null}

            </div>


            <div className="row mt-3">
                <div className="col-auto">
                    <h2 style={{cursor: 'pointer'}} onClick={handleClick}>
                        {shoe.shoe.brand.name} {(shoe.shoe.model).charAt(0).toUpperCase() + shoe.shoe.model.slice(1)} {shoe.shoe.version}
                        {shoe.shoe.shoeShape !== 'foot shaped' && shoeColorway?.widthOptions.includes(user?.runningProfile.footWidth) && user?.runningProfile.footWidth !== 'normal' && user?.runningProfile.footWidth !== 'narrow' ? ` (${user?.runningProfile.footWidth})` : ''}
                    </h2>
                </div>
            </div>

            
            <div className="row mt-1">
                <div className="col-auto">
                    {/* Find the first valid deal with specialPrice */}
                    {(() => {
                        const validDeal = shoePartnerDeals?.find(deal => 
                            deal.shoeColorway.toString() === shoeColorway?._id?.toString() &&
                            (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : true) &&
                            (!deal.sizes || deal.sizes.length === 0 || deal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0)) &&
                            deal.specialPrice
                        );
                        
                        // Display specialPrice if available, otherwise show MSRP
                        return validDeal ? (
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <h3 className='my-0' style={{color: '#2E7d43'}}>${validDeal.specialPrice.toFixed(0)}</h3>
                                </div>
                                <div className="col-auto px-0">
                                        <h4 className='my-0' style={{textDecoration: 'line-through'}}>${shoe.shoe.msrp}</h4> 
                                </div>
                            </div>
                            
                        ) : (
                            <h4>${shoe.shoe.msrp}</h4>  /* Default to MSRP */
                        );
                    })()}
                </div>
            </div>

            
            {shoe.shoe.averageWebsiteScore && shoe.shoe.averageWebsiteScore !== "" && shoe.shoe.averageWebsiteScore != "NaN" ?
            <div className="row mt-2 align-items-center" style={{cursor: 'pointer'}} onClick={(evt) => handleClick(evt, 2)}>
                <div className="col-auto">
                    <Rating readOnly value={parseFloat(shoe.shoe.averageWebsiteScore)/2} precision={0.01}/>
                </div>
                <div className="col-auto px-0">
                    <p className='my-0'>({shoe.shoe.websiteReviews.length})</p>
                </div>
                
            </div>

            :
            <div className="row mt-2">
                <p><i>pending reviews..</i></p>
            </div> }

            <div className="row px-2 mt-2">
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`feels ${shoe.shoe.cushionType}`}
                                icon={<SingleBedIcon/>}
                                size='small'
                                color='success'
                                variant='outlined'
                            />
                </div>
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.cushion} cushion (${shoe.shoe.stackHeight}mm)`}
                                size='small'
                                color='default'
                            />
                </div>
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.weight}g`}
                                size='small'
                                color='default'
                            />
                </div>
                {preferences.shoeSurface == 'trail' || preferences.shoeSurface == 'hybrid' ?
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.grip} grip`}
                                size='small'
                                color='default'
                            />
                </div>
                : null }
                {shoe.shoe.carbonPlate == 'TRUE' ?
                    <div className="col-auto my-1 px-1">
                        <ChipComponent
                                label={`carbon plate`}
                                size='small'
                                color='info'
                                variant='outlined'
                            />
                    </div>
                : null}

<div className="col-auto my-1 px-1">
                <ChipComponent
                        label={`${shoe.shoe.stability} support`}
                        size='small'
                        color='default'
                    />
                </div>

                <div className="col-auto my-1 px-1">
                <ChipComponent
                        label={`${shoeColorway?.widthOptions?.length} width options`}
                        size='small'
                        color='default'
                    />
                </div>


            </div>

            </CardContent>
            <CardActions>
                <div className="col">
                    
                    <div className="row justify-content-center">
                        <div className="col-auto my-1">
                            { partnerDeals?.some(deal => deal.shoeColorway.toString() == shoeColorway?._id?.toString() && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal )) ?
                            <Button size="small" endIcon={<ShoppingCartIcon/>} variant="outlined" onClick={handleClick}>Find a retailer</Button>
                            : <Button size="small" endIcon={<ShoppingCartIcon/>} variant="outlined" onClick={handleFindRetailer} target="_blank" >Find a retailer</Button>
                            }   
                        </div>
                        <div className="col-auto my-1">
                            <a href={`/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`}><Button onClick={handleClick} variant='outlined' size="small" endIcon={<ArrowRightAltIcon/>}>View Details</Button></a>
                        </div>
                    </div>
                    {/* New component to show partner deals */}
                </div>
                
            </CardActions>
             {/* <PurchaseShoeModal openState={purchaseShoeModalOpen} setOpenState={setPurchaseShoeModalOpen} shoe={shoe} colorway={shoeColorway} requirements={requirements} /> */}
        </Card>
       
    )
}
