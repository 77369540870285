var teamMembers = [
    {
        name: 'Anthony Grant',
        imageUrl: 'anthony',
        position: 'Founder',
        currentShoes: 'New Balance Fresh Foam X More V5 (road) and Topo Athletic Mtn Racer 3 (trail)',
        distance: 'ultra-marathon, trail',
        links: [
            {
                platform: 'strava',
                link: 'https://www.strava.com/athletes/47620792'
            },
            {
                platform: 'instagram',
                link: 'https://www.instagram.com/run.ant.run?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
            },
            {
                platform: 'linkedIn',
                link: 'https://www.linkedin.com/in/anthony-grant-7b706816a/'
            }
        ]
    }
]

export { teamMembers }