import { Alert } from "@mui/material"
import { useEffect, useState } from "react"
import axios from "axios"
import Skeleton from '@mui/material/Skeleton';
import { useAppContext } from "../../../../../utils/ContextProvider.jsx";

export default function TraitSummaryCard ({ shoeTrait = null, selectedsub_group = null, prosSummaryList = null, consSummaryList = null, shoe = null }) {

    var [summary, setSummary] = useState()

    var [fetchingSummary, setFetchingSummary] = useState()

    var { requestUrl } = useAppContext()

    useEffect(()=> {
        if (prosSummaryList.length > 2 && consSummaryList.length > 2) {
            setFetchingSummary(true)
            axios({
                url: `${requestUrl}/api/integrations/open_ai/shoeTrait/summarise`,
                method: 'POST',
                data: {
                    shoeTrait: shoeTrait,
                    prosSummaryList: prosSummaryList,
                    consSummaryList: consSummaryList
                },
                withCredentials: true
            })
            .then((res) => {
                setSummary(res.data)
                setFetchingSummary(false)
            })
            .catch((err) => {
                console.log(err)
                setFetchingSummary(false)
            })
        }
        
    }, [shoeTrait, selectedsub_group])

    if (prosSummaryList && prosSummaryList.length > 2 && consSummaryList && consSummaryList.length > 2) {
        if (!fetchingSummary) {
            return (
                <Alert color="info">
                    {summary}
                </Alert> 
            )
        } else {
            return (
                <Skeleton variant="rectangular" height={60} />
            )
        }
    } 
}