import { Button, Chip } from "@mui/material"
import StartIcon from '@mui/icons-material/Start';
import { Helmet } from "react-helmet";
import ChipComponent from "../../../components/Chip";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AppsIcon from '@mui/icons-material/Apps';
import TestimonialSection from "../../../components/Website/TestimonialSection";
import BlogPreviewSection from "../../../components/Website/BlogPreviewSection";
import { useInView } from 'react-intersection-observer';
import './index.css';

export default function HowItWorksPage() {
    const [ref1, inView1] = useInView({ triggerOnce: true });
    const [ref2, inView2] = useInView({ triggerOnce: true });
    const [ref3, inView3] = useInView({ triggerOnce: true });
    const [ref4, inView4] = useInView({ triggerOnce: true });
    const [heroRef, heroInView] = useInView({ triggerOnce: true });
    const [problemsRef, problemsInView] = useInView({ triggerOnce: true });
    const [stepsRef, stepsInView] = useInView({ triggerOnce: true });
    const [ctaRef, ctaInView] = useInView({ triggerOnce: true });

    return (
        <div>
            <Helmet>
                <title>Fittir - How it works</title>
                <meta name="description" content="How Fittir helps you sort through the 100's of running shoe options to find the right one for you." />
                <meta property="og:title" content="Fittir - How it works" />
                <meta property="og:description" content="How Fittir helps you sort through the 100's of running shoe options to find the right one for you.."/>
            </Helmet>

            <section ref={heroRef} className={`home-page-hero my-5 pt-3 ${heroInView ? 'fade-in' : 'visible'}`}>
                <div className="col-11 mx-auto">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 mx-auto">
                            <div className="row">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <ChipComponent color={'default'} size={'small'} label={'How it works'} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <h1 className="dashboard-text hero-heading">Running shoe recommendations <span className="heroTextHighlight">tailored to you.</span></h1>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <p className="dashboard-text">After a questionnaire to understand your needs and preferences, Fittir sorts through the 100's of running shoe options to find the right one for you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 text-center mx-auto ms-md-0 pt-5 pt-md-0">
                            <div className="row">
                                <div className="col-11 mx-auto">
                                    <img className="img img-fluid" src="/prototype/main_prototype.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Problems with running shoe shopping online */}
            <section ref={problemsRef} className={`how-it-works my-5 py-5 ${problemsInView ? 'fade-in' : 'visible'}`} style={{backgroundColor: '#f5f5f5'}}>
                <div className="container">
                    <div className="col-md py-5">
                    <div className="row text-center">
                        <div className="col-11 col-md-9 mx-auto">
                            <h2 style={{fontSize: '2rem'}}>Buying running shoes online has its challenges</h2>
                            <p className="mt-3">We know all to well how difficult it can be to find the right running shoe online. We've built Fittir to avoid some of the common pitfalls of running shoe shopping online.</p>
                        </div>
                    </div>
                    <div className="row mt-4">

                        {/* Problem 1 */}
                        <div className="col-11 mx-auto col-md">
                            <div className="row">
                                <div className="col text-center py-3 px-3 my-2 mx-3" style={{backgroundColor: 'white', borderRadius: '1em'}}>
                                    <div className="step py-2">
                                        <AppsIcon style={{fontSize: '3em'}} />
                                        <h3 className="mt-4">Number of options</h3>
                                        <p>These days there are 100's of running shoes to choose from across multiple brands. We sort through the options to find the best ones for you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Problem 2 */}
                        <div className="col-11 mx-auto col-md">
                            <div className="row">
                                <div className="col text-center py-3 px-3 my-2 mx-3" style={{backgroundColor: 'white', borderRadius: '1em'}}>
                                    <div className="step py-2">
                                        <TipsAndUpdatesIcon style={{fontSize: '3em'}} />
                                        <h3 className="mt-4">Generic recommendations</h3>
                                        <p>We all run differently, that means what works for one person may not work for another. We take your needs into account to find the right shoe for you.</p>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        {/* Problem 3 */}
                        <div className="col-11 mx-auto col-md">
                            <div className="row">
                                <div className="col text-center py-3 px-3 my-2 mx-3" style={{backgroundColor: 'white', borderRadius: '1em'}}>
                                    <div className="step py-2">
                                        <AccessTimeIcon style={{fontSize: '3em'}} />
                                        <h3 className="mt-4">Time-consuming research</h3>
                                        <p>A shoe is more than just some paper stats. We use our access to 1000's of amateur runner and wear-tester reviews to help you make the right choice.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>

                </div>
            </section>

            {/* Steps to use Fittir */}
            <section ref={stepsRef} className={`how-it-works my-5 py-3 ${stepsInView ? 'fade-in' : 'visible'}`}>
                <div className="container">
                    <div className="col-12 col-md-10 mx-auto">

                        <div className="row text-center mt-4">
                            <div className="col-11 col-md-8 mx-auto">
                                <h2 style={{fontSize: '2rem'}}>Fittir guides you from start to finish</h2>
                                <p className="mt-3">Built by runners, for runners, we know what a great running shoe should do. We guide you through the assessment, recommendation, and comparison process.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-auto mx-auto">
                            <script src="https://static.elfsight.com/platform/platform.js" async></script>
                            <div class="elfsight-app-ff836bbc-9321-45c1-9043-8a83783bea86" data-elfsight-app-lazy></div>
                            </div>
                        </div>

                        {/* How it works - assessment */}
                        <div ref={ref1} className={`row mt-5 ${inView1 ? 'fade-in' : ''}`}>
                            <div className="col-md">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-10 mx-auto col-md-5 mx-md-0 text-center text-md-start">
                                        <h3 style={{fontSize: '1.8rem'}}>🗣️ Assessment</h3>
                                        <p className="mt-3">In a questionnaire that takes between 5-10 minutes, we ask you a series of questions to understand your running needs and preferences.</p>
                                        <p className="mt-3">We guide you through the tricky parts when it comes to your running style, and you can always update it later if you need to.</p>
                                    </div>
                                    <div className="col-10 mx-auto col-md-6 mx-md-0 text-center text-md-end mt-2">
                                        <img className="img img-fluid" src="/prototype/assessment.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* How it works - recommendation */}
                        <div ref={ref2} className={`row mt-5 ${inView2 ? 'fade-in' : ''}`}>
                            <div className="col-md">
                                <div className="row justify-content-between  align-items-center">
                                    <div className="col-10 mx-auto col-md-6 mx-md-0 text-center order-2 order-md-1 text-md-start">
                                        <img className="img img-fluid" src="/prototype/recommend.svg" alt="" />
                                    </div>
                                    <div className="col-10 mx-auto col-md-5 mx-md-0 text-center text-md-start order-1 order-md-2">
                                        <h3 style={{fontSize: '1.8rem'}}>💡 Recommendation</h3>
                                        <p className="mt-3">Once we've gathered your preferences and running needs, we sort through the 100's of running shoe options and rank the options from best to worst.</p>
                                        <p className="mt-3">We'll show you why certain shoes suit you and why others don't, including an assessment of your current shoes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* How it works - compare */}
                        <div ref={ref3} className={`row mt-5 ${inView3 ? 'fade-in' : ''}`}>
                            <div className="col-md">
                                <div className="row justify-content-between  align-items-center">
                                    <div className="col-10 mx-auto col-md-5 mx-md-0 text-center  text-md-start">
                                        <h3 style={{fontSize: '1.8rem'}} >🔁 Comparison</h3>
                                        <p className="mt-3">We help you select a couple of option to compare against what you are currently running in to quickly see the key differences. </p>
                                        <p className="mt-3">Not only do we use what a shoe is on paper, but we also reference our database of 1000's of written, wear-tester and video reviews to quickly highlight pros and cons of each shoe.</p>
                                    </div>
                                    <div className="col-10 mx-auto col-md-6 mx-md-0 text-center text-md-end">
                                        <img className="img img-fluid" src="/prototype/compare.svg" alt="" />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        {/* How it works - decide */}
                        <div ref={ref4} className={`row mt-5 ${inView4 ? 'fade-in' : ''}`}>
                            <div className="col-md">
                                <div className="row justify-content-between  align-items-center">
                                    <div className="col-10 mx-auto col-md-6 mx-md-0 text-center  order-2 order-md-1 text-md-start">
                                        <img className="img img-fluid" src="/prototype/decide.svg" alt="" />
                                    </div>
                                    <div className="col-10 mx-auto col-md-5 mx-md-0 text-center text-md-start order-1 order-md-2">
                                        <h3 style={{fontSize: '1.8rem'}}  >🫵 Decide and purchase</h3>
                                        <p className="mt-3">We partner with the best running shoe retailers and brands to not only show any deals, but also help quickly find stock in your preferred color and size.</p>
                                        <p className="mt-3">We'll display their return policy and any other benefits, so once you are ready, we'll send you right to their checkout to complete the purchase .</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <TestimonialSection />

            <section ref={ctaRef} className={`CTA-section my-5 py-5 ${ctaInView ? 'fade-in' : ''} gradient-background`}>
                <div className="container">
                    <div className="row">
                        <div className="col-11 col-md-9 mx-auto text-center">
                            <h2 style={{fontSize: '2rem'}}>It's free to use, and takes 5 minutes to get your shoe recommendation</h2>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-auto mx-auto">
                            <Button
                                href="/shoe-finder/intro"
                                color="secondary"
                                style={{backgroundColor: 'white', color: 'black'}}
                                size="large"
                                endIcon={<StartIcon/>}>
                                Get started
                            </Button>
                        </div>
                    </div>
                    
                </div>
            </section>  

            {/* <BlogPreviewSection /> */}
        </div>
    )
}
