import { useInView } from 'react-intersection-observer';
import './index.css'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { teamMembers } from "./teamMembers"
import { Helmet } from "react-helmet";


export default function AboutPage() {
    const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <div>
            <Helmet>
                <title>Fittir - About us</title>
                <meta name="description" content="Built by runners, for runners. We're on a mission to make sure every runner can find the perfect shoe for them." />
                <meta property="og:title" content="Fittir - About us" />
                <meta property="og:description" content="Built by runners, for runners. We're on a mission to make sure every runner can find the perfect shoe for them."/>
                <meta property="og:type" content="website" />
            </Helmet>
            <section ref={ref1} className={`hero-heading fade-in ${inView1 ? 'is-visible' : ''}`}>
                <div className="container-fluid gradient-background py-5 mt-4">
                    <div className="row my-5">
                        <div className="col-10 mx-auto text-center">
                            <h1 className="heroHeading">Our vision is that any runner, irrespective of experience or where they live, can avoid bad running shoe decissions</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={ref2} className={`how-it-started fade-in ${inView2 ? 'is-visible' : ''}`}>
                <div className="container-md my-5 py-4">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 mx-auto ">
                            <div className="row">
                                <div className="col-12 mx-auto text-center">
                                    <img className="img img-fluid" src="/assets/how_it_started.png" alt="" />
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-12 col-md-7 text-center mx-auto ms-md-0 pt-5 pt-md-0">
                            <div className="row">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <h2 style={{fontSize: '2rem'}}>How it started..</h2>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <p>At the end of 2023, after years of long distance running and hearing "what shoes should I buy" questions from new runners, we realised that buying running shoes is far more complicated than it needs to be.</p>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <p>We had also fallen victim to this issue. Our founder (Anthony, 👋) had battled for years eventually trying over 6+ pairs of shoes, bouncing between bad recommendations and an internet jam-packed with marketing jargon, contradicting opinions and 200+ options to pick from. In the process picking up a couple of injuries, and wasting $100's of dollars. We'd learn later that 62% of runners would have a similar experience.</p>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <p>All it eventually took was an unbaised recommendation from an expert based on some key information to get us to the right shoe. That same shoe would take us all the way from 42km to multiple 162km ultra-marathons without any issues.</p>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <p>It is an absolute crime to deny someone all the benefits of running due to a bad shoe choice. That's why Fittir was born, to serve runners struggling to know where to start so they make the right decission the first time around.</p><h4>It's our mission to make sure that service is available to any runner, irrespective of experience, knowledge or where they live. </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={ref3} className={`principles my-5 fade-in ${inView3 ? 'is-visible' : ''}`} style={{backgroundColor: '#f5f5f5'}}>
                <div className="container-md py-5">
                    <div className="row mt-4">
                        <div className="col-12 text-center">
                            <h2 style={{fontSize: '2rem'}}>Our principles at Fittir</h2>
                        </div>
                    </div>
                    <div className="row text-center mt-5">
                        <div className="col-12 col-md-3 my-2">
                            <div className="value-card">
                                <h4>1. Put the runner first</h4>
                                <p>It's our duty to serve the runner, putting them first in every conversation, partnership, commercial decision, and product change.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 my-2">
                            <div className="value-card">
                                <h4>2. Remove the bias</h4>
                                <p>We must ensure we never compromise on the best possible experience or recommendation for a runner in favour of a commercial interest.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 my-2">
                            <div className="value-card">
                                <h4>3. Make it simple</h4>
                                <p>At every possible point, we remove complexity and "fluffiness" in running shoes, keeping it easy to understand for all involved.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 my-2">
                            <div className="value-card">
                                <h4>4. Be transparent and share knowledge</h4>
                                <p>At every step of the way, we will be transparent about the process and underlying reasoning, and sharing knowledge back with the runner about what and why.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={ref4} className={`team fade-in ${inView4 ? 'is-visible' : ''}`}>
                <div className="container-md my-5">
                    <div className="row">
                        <div className="col-auto mx-auto">
                            <h2 style={{fontSize: '2rem'}}>Team</h2>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-8 mx-auto text-center">
                            <p>We are a small, but mighty team of die-hard, passionate runners. We live by what we build, ultimately solving problems for a version of ourselves that once needed it too..</p>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center mt-4">
                            {teamMembers.map((member, index) => 
                                <div className="col-6 col-lg-3 px-4 py-4 grey-outline">
                                    <div className="row">
                                        <div className="col-8 mx-auto text-center">
                                            <img className="img img-fluid" src={`/team/${member.imageUrl}.png`} alt="" />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col mx-auto text-center">
                                            <h4>{member.name}</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col mx-auto text-center">
                                            <p>Role: {member.position}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <p>Current shoes: {member.currentShoes}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <p>Distance: {member.distance}</p>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        {member.links.map((link) => 
                                            <div className="col-auto">
                                                <a href={link.link} target="_blank">
                                                    <img src={`/icons/social/${link.platform}.svg`} alt="" />
                                                </a>
                                            </div>
                                        )}       
                                    </div>
                                </div>
                            )}
                                <div className="col-6 col-lg-3 px-4 py-4">
                                    <div className="row">
                                        <div className="col-6 mx-auto text-center">
                                            <img className="img img-fluid" src={`/team/default.png`} alt="" />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col mx-auto text-center">
                                            <h4>Could this be you?</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col mx-auto text-center">
                                            <p>We are always looking for passionate people to help out at Fittir, no matter what skillset you have.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <p>Contact us below if you'd like to help make our mission a reality</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <a href="mailto:hi@fittir.io" style={{textDecoration: 'none'}}><p style={{color: 'black', fontWeight: 'bold', textDecoration: 'underline'}}>Contact us <ArrowRightAltIcon /></p></a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
            </section>
        </div>
    )
}
