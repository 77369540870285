import { Link, useNavigate, useHistory, useLocation } from "react-router-dom"
import { useState, useContext } from "react"
import axios from 'axios'
import Button from "@mui/material/Button"
import { useAppContext } from '../../../utils/ContextProvider.jsx';
import TextField from "@mui/material/TextField"
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from "@mui/material/CircularProgress"
import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";


export default function LoginPage() {

    const { setIsAlert, requestUrl, isProcessing, setProcessing } = useAppContext()

    const [showPassword, setShowPassword] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })

    const handleChange = (evt) => {
        const changedField = evt.target.name
        const newValue = evt.target.value

        setFormData(currData => {
            return {
                ...currData,
                [changedField]: newValue
            }
        })
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        setProcessing(true)
        const { email, password } = formData
        const url = `${requestUrl}/api/users/login`
        axios({
            url: url,
            method: "POST",
            data: {
                email: email,
                password: password
            },
            withCredentials: true
        })
        .then((res) => {
            localStorage.setItem( "isAuthenticated", true )
            mixpanel.identify(email)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Welcome back, ${res.data.user.name} 👋 `
                }
            })
            setProcessing(false)
            return (
                navigate("/dashboard")
            )
        })
        .catch((err) => {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setProcessing(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
        })
        
    }

    return (
            <div className="py-5">
                        <Helmet>
                            <title>Fittir - Login</title>
                            <meta name="description" content="Login to your Fittir account to access your running shoe recommendations and research tools." />
                            <meta property="og:title" content="Fittir -Login" />
                            <meta property="og:description" content="Login to your Fittir account to access your running shoe recommendations and research tools."/>
                            <meta property="og:type" content="website" />
                            <meta property="og:image" content="/meta/og-image.png" />
                        </Helmet>
                <div className="row my-4">
                    <div className="col-7 mx-auto text-center">
                        <a href="/"><img className="img logo" src="/logos/logo-primary.svg" alt=""/></a>
                    </div>
                </div>

                <div className="row pt-4">
                    <div className="col mx-auto text-center pt-2">
                        <h2>Sign into your account</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="row mx-auto">
                            <div className="col-10 col-md-8 mx-auto">
                            <form className="validated-form" method="POST" id="loginForm" onSubmit={handleSubmit}>
                                <div className="row mb-5">
                                <div className="col">
                                    <div className="row mt-5">
                                        <div className="col">
                                            <TextField fullWidth id="email" type="email" name="email" placeholder="name@example.com" onChange={handleChange} label="Email" variant="outlined" />
                                        </div>

                                    </div>   
                                    <div className="row mt-3">
                                        <div className="col">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="password"
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </div>
                                    </div>     
                                </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-8 mx-auto text-center">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            disabled={isProcessing == true ? true : false}
                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                        >Login
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <div className="row my-2">
                            <div className="col">
                                <h4>Don't have an account? <Link to="/shoe-finder/intro">Get started</Link></h4>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col">
                                <h4>Forgot your passsword? <Link to="/auth/forgot-password">Reset my password</Link></h4>
                            </div>
                        </div>
                    </div>         
                </div>
            </div>
                    

    )
}