import '../../services/integrations/Sentry/index'
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {


    var error = useRouteError()

     useEffect(() => {
        console.log(error)
        Sentry.captureException(error)
          
    })

    
    return (
        <div>

        </div>
        
    )
}