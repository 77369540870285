var testimonials = [
    {
        name: 'Tori Horton',
        nameSlug: 'tori_horton',
        shoe: 'Saucony Guide 17',
        shoeSlug: 'saucony_guide_17',
        starRating: 5,
        brandSlug: 'saucony',
        imageSlug: 'saucony_guide_17_white-peel',
        quote: "I actually just bought a new pair (2 weeks ago) that I haven't been super happy with, and turns out that after using Fittir I found out they are only 79% suited to me and my needs. Luckily for me my mum loves them SO I gave them to her and invested in the Saucony pair that was recommended which feel much better! Shoes are so important to avoid injury and as I'm at the start of my running journey, I want to make sure I do everything to avoid that and Fittir was a massive help in that process."
    },
    {
        name: 'Meghan Zeller',
        nameSlug: 'meghan_zeller',
        shoe: 'Asics gel-trabuco 12',
        starRating: 5,
        shoeSlug: 'asics_gel-trabuco_12',
        imageSlug: 'asics_gel-trabuco_12_blackberry-lightblue',
        quote: "I was looking for a pair of trail running shoes, but I couldn't get into a shoe store because I struggled to find the time. I then came across Fittir and it was easy to use, in-depth enough to know I could trust it, and liked that I could cross check my old shoes vs any new ones. I ended up buying a pair of Asics trail shoes, and they actually fit my narrow foot and feel great. Would recommend to any runner!"
    },
    {
        name: 'Jack McGrath',
        nameSlug: 'jack_mcgrath',
        shoe: 'Brooks Glycerin 21',
        shoeSlug: 'brooks_glycerin_21',
        starRating: 5,
        imageSlug: 'brooks_glycerin_21_coconut-forgediron-yellow',
        quote: "I recently had my gait analysed after dealing with an ongoing injury to try and figure out what shoe I needed, and was recommended Brooks Glycerin. I wasn't sure if this was just because those are the options they had so I wanted a second opinion. Fittir was so fast and easy, and also got recommended Brooks Glycerin which was amazing. It's the confirmation I needed to pull the trigger. Thanks for building this wicked website!"
    },
    {
        name: 'Alex Smith',
        nameSlug: 'alex_smith',
        shoe: 'Asics Superblast 2',
        shoeSlug: 'asics_superblast_2',
        starRating: 5,
        imageSlug: 'asics_superblast_2_coolgrey-safetyyellow',
        quote: "I had been doing a whole lot of my own research online, but wanted a second opinion. Fittir was so fast and easy, and really took into account the whole picture. My top recommendation was one of the shoes I had been looking at so was a good excuse to pull the trigger on a pair of Superblasts. They've been great so far!"
    },
    {
        name: 'Brendon Dockary',
        nameSlug: 'brendon_dockary',
        shoe: 'Nike Invincible 3',
        shoeSlug: 'nike_invincible_3',
        starRating: 5,
        imageSlug: 'nike_invincible_3_blue-green',
        quote: "My running shoes were almost 12 years old and well beyond their mileage limit, so I knew it was finally time for an upgrade. I ran a half marathon a couple of years ago and got pretty sore feet and fell out of love with running pretty sharply after completing the half marathon. I wanted to get back into running to help get into shape for my sister's wedding next year so I used Fittr to help me find the right pair to get me back on the horse. I was super impressed with the level of depth that Fittr went to (so handy to be able to use the wear pattern on my current shoes to help guide the fitting process!). Once I went through the process and had my recommendations I also really enjoyed being able to filter by price and brand (let's face it, we don't all have $400 to be fitted into the top of the line option). I eventually found one of the pairs Fittr suggested while I was shopping in Hong Kong so I jumped on the opportunity knowing I could be confident in what I was buying."
    },
    {
        name: 'Mitch Hazelhurst',
        nameSlug: 'mitch_hazelhurst',
        shoe: 'Altra Torin 7',
        shoeSlug: 'altra_torin_7',
        starRating: 5,
        imageSlug: 'altra_torin_7_greygreen',
        quote: "In the past I’d had troubles getting into running due to back pain, little did I know the right pair of shoes would make all the difference! Fittir was straightforward to use and suggested the PERFECT shoe for my runner profile and goals, now I’ve running pain and worry free for over six months and there’s no turning back! Highly recommend."
    }
]   

export { testimonials }