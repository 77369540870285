import { useEffect, useState } from "react"
import axios from "axios"
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import InfoIcon from '@mui/icons-material/Info';
import ReviewCard from "../../../../components/Dashboard/Cards/ReviewCard";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import * as Sentry from "@sentry/react";

export default function ReviewsList( { user = null, shoe = null, setRunnerReviews = null, averageReviewScore = null, setAverageReviewScore = null, type = 'amateur', websiteReviews = [] }) {

    const { isAlert, setAlertState, setIsAlert, setLoading, requestUrl, trackEvent } = useAppContext()

    var [reviews, setReviews] = useState([])

    var [isLoading, setIsLoading] = useState(false)

    const [runnerDisplayCount, setRunnerDisplayCount] = useState(10)
    const [testerDisplayCount, setTesterDisplayCount] = useState(10)

    useEffect(()=> {
        if (shoe) {
            trackEvent('View shoe ratings page')
            setIsLoading(true)
            var url = `${requestUrl}/api/shoeReviews/${shoe.shoe._id}`
            axios({
                url: url,
                withCredentials: true
            }).then((res) => {
                var reviews = res.data.reviews?.filter((review) => review?.userId?._id !== user?._id)
                setReviews(reviews)
                setRunnerReviews(reviews.length)
                if (reviews.length > 1) {
                    setAverageReviewScore((reviews?.reduce((sum, currentValue) => sum + currentValue.rating, 0) / reviews?.length).toFixed(1))
                } else {
                    setAverageReviewScore(reviews[0]?.rating)
                }
                setIsLoading(false)
            }
            ).catch((err)=> {
                Sentry.captureException(err)
                console.log(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setIsLoading(false)
            })
        }
    }, [shoe])

    if (isLoading) {
        return (
            <div className="my-4">
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                
            </div>
        )
    } else {
        return (
            <div>
                {type === 'amateur' ? (
                    <>
                        <div className="row mt-3">
                            <div className="col text-start">
                                <h4>Reviews on Fittir {averageReviewScore ? `(${averageReviewScore}/5)` : null}</h4>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                {reviews.length > 0 ? (
                                    <>
                                        {reviews.slice(0, runnerDisplayCount).map((review, index) => (
                                            <div className="row" key={review._id || index}>
                                                <div className="col">
                                                    <ReviewCard review={review} type="amateur" />
                                                </div>
                                            </div>
                                        ))}
                                        {reviews.length > runnerDisplayCount && (
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <Button 
                                                        variant="outlined" 
                                                        onClick={() => setRunnerDisplayCount(prev => prev + 10)}
                                                    >
                                                        Load more
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="row">
                                        <div className="col text-start">
                                            <p><i>We couldn't find any reviews from other runners on Fittir who own this shoe.</i></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row mt-4">
                            <div className="col text-start">
                                <h4>Wear-tester reviews {shoe?.shoe.websiteReviews?.length > 0 
                                    ? `${shoe?.shoe.averageWebsiteScore ? `${(shoe?.shoe.averageWebsiteScore / 2).toFixed(1)}/5` : '-'}`
                                    : null}
                                </h4>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                {websiteReviews?.length > 0 ? (
                                    <>
                                        {websiteReviews.slice(0, testerDisplayCount).map((review, index) => (
                                            <div className="row" key={index}>
                                                <div className="col">
                                                    <ReviewCard review={review} type="wear-tester" />
                                                </div>
                                            </div>
                                        ))}
                                        {websiteReviews.length > testerDisplayCount && (
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <Button 
                                                        variant="outlined" 
                                                        onClick={() => setTesterDisplayCount(prev => prev + 10)}
                                                    >
                                                        Load more
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="row">
                                        <div className="col text-start">
                                            <p><i>We couldn't find any reviews from wear-testers who own this shoe.</i></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}