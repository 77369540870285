import NavBar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import FlashAlert from "../../../components/Alert"
import Loader from "../../../components/Loader"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { Button } from "@mui/material"
import StartIcon from '@mui/icons-material/Start';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


export default function PrivacyPolicyPage() {
    return (
        <div>
            <div className="section mt-5">
                <div className="container-md my-5">
                    <div className="row my-4">
                        <div className="col-11 col-lg mx-auto">
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <h1>Privacy policy</h1>
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-10 mx-auto">
                                    <div className="h4">Last updated: 24/10/2023</div>
                                </div>
                                
                            </div>
                            <div className="row my-4">
                                <div className="col-10 mx-auto">
                                    <p>At Fittir, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, share, and protect your data when you use our application. Please read this policy carefully to understand how your information will be treated. By using Fittir, you consent to the practices described in this Privacy Policy.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <ol className="list">

                                        <li><p>Information We Collect
                                            <ul>
                                                <li><p>User Provided Information: We may collect personal information you provide when you use Fittir, such as your name, email address, gender, age, and other profile information. This information is essential to create a personalized running shoe recommendation.</p></li>
                                                <li><p>Device Information: We may collect information about your device, including its model, operating system, and unique identifiers. This data helps us optimize the app for your specific device.</p></li>
                                                <li><p>Usage Information: We collect data on how you use Fittir, including the features you access, the shoes you view, and other interactions with the app. This information helps us improve your user experience and provide better recommendations.</p></li>
                                                <li><p> Location Information: Fittir may request access to your device's location to provide location-based shoe recommendations. You can choose to enable or disable this feature in your device settings.</p></li>
                                            </ul>
                                        </p></li>

                                        <li><p>How we use your information
                                            <p>We use the colected information for the following purposes:</p>
                                            <ul>
                                                <li><p>Personalization: We use your provided information to create personalized running shoe recommendations that match your preferences and running style.</p></li>
                                                <li><p>App Improvement: We analyze usage and device data to improve our app, fix bugs, and optimize performance.</p></li>
                                                <li><p>Communication: We may send you service-related notifications and updates, such as recommended shoe updates or important announcements. You can choose to opt out of these communications.</p></li>
                                            </ul>
                                        </p></li>

                                        <li><p>Data sharing
                                            <p>We do not share your personal information with third parties, except as follows:</p>
                                            <ul>
                                                <li><p>Third-party Service Providers: We may share your data with third-party service providers to help us with app functionality, analytics, and marketing. These providers are contractually obligated to protect your data and use it only for authorized purposes.</p></li>
                                                <li><p>Legal Requirements: We may disclose your information if required by law or in response to a valid legal request.</p></li>
                                            </ul>
                                        </p></li>

                                        <li><p>Your choices
                                            <p>We do not share your personal information with third parties, except as follows:</p>
                                            <ul>
                                                <li><p>Access and Update: You can review and update your personal information in your Fittir profile at any time.</p></li>
                                                <li><p>Location Services: You can control location access in your device settings to enable or disable location-based recommendations.</p></li>
                                                <li><p>Communication: You can opt out of receiving promotional emails and notifications from Fittir. However, we may still send you service-related communications.</p></li>
                                            </ul>
                                        </p></li>

                                        <li><p>Changes to Privacy Policy 
                                            <p>We may update this Privacy Policy to reflect changes in our practices or the law. We will notify you of any significant changes via email or a notice in the app. Your continued use of Fittir after such modifications will constitute your agreement to the updated Privacy Policy.</p>
                                        </p></li>


                                        <li><p>Contact us
                                            <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:hi@fittir.io">hi@fittir.io</a></p>
                                            <p>Thank you for using Fittir! Your privacy is important to us, and we are committed to providing you with the best running shoe recommendations while protecting your personal information.</p>
                                        </p></li>


                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}