import SingleBedIcon from '@mui/icons-material/SingleBed';
import ChairIcon from '@mui/icons-material/Chair';
import ConstructionIcon from '@mui/icons-material/Construction';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Alert, Chip, Rating } from '@mui/material';
import { useEffect, useState } from 'react';
import { shoeTraitGroupings } from '../../../../utils/ShoeTraitGroupings';
import { useAppContext } from '../../../../utils/ContextProvider.jsx';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GeneralTooltip from '../../../../components/ToolTip';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import TraitSummaryCard from './TraitSummaryCard';
import BedIcon from '@mui/icons-material/Bed';
import CompressIcon from '@mui/icons-material/Compress';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import BuildIcon from '@mui/icons-material/Build';
import IceSkatingIcon from '@mui/icons-material/IceSkating';
import AppsIcon from '@mui/icons-material/Apps';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Skeleton from '@mui/material/Skeleton';
// Filter reviews by trait
var filterWebsiteReviews = (trait, reviews) => {
    var prosList = [];
    var consList = [];

    // Iterate over the reviews and filter pros and cons based on the given trait
    reviews.forEach((review) => {
        // Filter the pros list where the group matches the given trait
        const pros = review.prosList.filter(item => item.group === trait);
        prosList = prosList.concat(pros);  // Accumulate the filtered pros

        // Filter the cons list where the group matches the given trait
        const cons = review.consList.filter(item => item.group === trait);
        consList = consList.concat(cons);  // Accumulate the filtered cons
    });

    // Return the filtered pros and cons grouped by the trait
    return {
        pros: prosList,
        cons: consList
    };
};

const getSortedSubGroupsByType = (sortedReviews) => {
    const proSubGroupCounts = {};
    const conSubGroupCounts = {};

    // Helper function to generate a composite key from sub_group and group
    const generateKey = (subGroup, group) => `${subGroup}|${group}`;

    // Accumulate counts for each combination of subGroup and group in pros and cons
    Object.keys(sortedReviews).forEach(shoeTrait => {
        const pros = sortedReviews[shoeTrait]?.pros || [];
        const cons = sortedReviews[shoeTrait]?.cons || [];

        pros.forEach(item => {
            const subGroup = item.sub_group;
            const group = item.group;
            if (subGroup) {
                const key = generateKey(subGroup, group);
                if (!proSubGroupCounts[key]) {
                    proSubGroupCounts[key] = { subGroup, group, count: 0 };
                }
                proSubGroupCounts[key].count += 1;
            }
        });

        cons.forEach(item => {
            const subGroup = item.sub_group;
            const group = item.group;
            if (subGroup) {
                const key = generateKey(subGroup, group);
                if (!conSubGroupCounts[key]) {
                    conSubGroupCounts[key] = { subGroup, group, count: 0 };
                }
                conSubGroupCounts[key].count += 1;
            }
        });
    });

    // Convert to arrays and sort by frequency (most frequent first)
    const sortedPros = Object.values(proSubGroupCounts).sort((a, b) => b.count - a.count);
    const sortedCons = Object.values(conSubGroupCounts).sort((a, b) => b.count - a.count);

    return { sortedPros, sortedCons };
};



// Get sorted sub-groups by type
const getSortedsub_groupsByType = (sortedReviews) => {
    const sub_groupCounts = {};

    Object.keys(sortedReviews).forEach(shoeTrait => {
        const pros = sortedReviews[shoeTrait]?.pros || [];
        const cons = sortedReviews[shoeTrait]?.cons || [];

        pros.forEach(item => {
            const sub_group = item.sub_group;
            if (sub_group) {
                sub_groupCounts[sub_group] = (sub_groupCounts[sub_group] || 0) + 1;
            }
        });

        cons.forEach(item => {
            const sub_group = item.sub_group;
            if (sub_group) {
                sub_groupCounts[sub_group] = (sub_groupCounts[sub_group] || 0) + 1;
            }
        });
    });

    return Object.entries(sub_groupCounts).sort((a, b) => b[1] - a[1]);
};

export default function ShoeAnalysisSection({ websiteReviews = null, open = null, setOpen = null, openAccordian = null }) {

    var icons = {
        cushion: <CompressIcon /> ,
        comfort: <BedIcon /> ,
        durability: <BuildIcon /> ,
        performance: <ElectricBoltIcon /> ,
        fit: <FitScreenIcon /> ,
        stability: <VideoStableIcon /> ,
        grip: <IceSkatingIcon />,
        other: <AppsIcon /> 
    }

    const [isFiltering, setIsFiltering] = useState(true);
    const [sortedReviews, setSortedReviews] = useState({});
    const [selectedsub_group, setSelectedsub_group] = useState({});
    const { sessionUser, setUpgradeModalOpen, trackEvent } = useAppContext();
    const [sortedsub_groups, setSortedsub_groups] = useState([])

    const [sortedSubGroups, setSortedSubGroups] = useState({ sortedPros: [], sortedCons: [] });

    const [openVariable, setOpenVariable] = useState()

    // Sorting and storing reviews by shoe trait
    useEffect(() => {
        if (websiteReviews) {
            const sortedReviews = {};
            for (const shoeTrait of shoeTraitGroupings) {
                const output = filterWebsiteReviews(shoeTrait, websiteReviews);
                sortedReviews[shoeTrait] = output;
            }
            setIsFiltering(false);
            setSortedReviews(sortedReviews);
            setOpenVariable(localStorage.getItem('openAccordian') || null)
            setSelectedsub_group(localStorage.getItem('openAccordianSubGroup') ? prevSelectedsub_group => ({
                ...prevSelectedsub_group,
                [localStorage.getItem('openAccordian')]: localStorage.getItem('openAccordianSubGroup') || null
            }) : {});

            // Update sorted sub-groups
            const sortedsub_groups = getSortedsub_groupsByType(sortedReviews);
            setSortedsub_groups(sortedsub_groups);

            const sortedGroups = getSortedSubGroupsByType(sortedReviews);
            setSortedSubGroups(sortedGroups);
        }
    }, [websiteReviews, sessionUser]);

    const handlesub_groupClick = (shoeTrait, sub_group) => {
        trackEvent('View shoe trait sub-group', {shoeTrait: shoeTrait, sub_group: sub_group})
        setSelectedsub_group(prevSelectedsub_group => ({
            ...prevSelectedsub_group,
            [shoeTrait]: prevSelectedsub_group[shoeTrait] === sub_group ? null : sub_group,
        }));
    };

    const handleClearsub_group = (shoeTrait) => {
        setSelectedsub_group(prevSelectedsub_group => ({
            ...prevSelectedsub_group,
            [shoeTrait]: null,
        }));
    };


    const handleViewTrait = (shoeTrait, sub_group) => {
        setOpenVariable(prev => prev === shoeTrait ? null : shoeTrait); // Toggle the accordion
        trackEvent('View shoe trait sub-group', {shoeTrait: shoeTrait, sub_group: sub_group})
        setSelectedsub_group(prevSelectedsub_group => ({
            ...prevSelectedsub_group,
            [shoeTrait]: sub_group,
        }));
    };
    
    const filteredPros = (pros, shoeTrait) => {
        const sub_group = selectedsub_group[shoeTrait];
        return sub_group ? pros.filter(pro => pro.sub_group === sub_group) : pros;
    };

    const filteredCons = (cons, shoeTrait) => {
        const sub_group = selectedsub_group[shoeTrait];
        return sub_group ? cons.filter(con => con.sub_group === sub_group) : cons;
    };

    if (websiteReviews && websiteReviews.length > 0 && isFiltering) {
        return (
            <div className="col ask-ai-tile px-2 px-md-3 py-3">

                <div className="row">
                    <div className="col-auto">
                        <AutoAwesomeIcon />
                    </div>
                    <div className="col">
                        <div className="row justify-content-between">
                            <div className="col-8 col-md-auto text-start">
                                <h4>How does this shoe perform?</h4>
                            </div>
                            <div className="col-4 col-md-auto">
                                <GeneralTooltip title={`Scores are generated by our AI from our analysis of the key traits of this shoe based on wear-tester and reviews from Fittir based on the ratio of positives and negatives mentioned in each category.`} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col text-start">
                        <p>Based on our analysis of <span onClick={(evt) => setOpen(true)}>wear-tester </span>and runner reviews across Fittir and the internet, here's the most frequent things we found.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-11 col-md-6 my-1 text-center">
                        <Skeleton variant="rectangular" width="100%" height={100} />
                    </div>
                    <div className="col-11 col-md-6 my-1 text-center">
                        <Skeleton variant="rectangular" width="100%" height={100} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                        <Skeleton className='mb-1' variant="rectangular" width="100%" height={50} />
                    </div>
                </div>
            </div>
        )
    }else if (websiteReviews && websiteReviews.length > 0 && !isFiltering) {
        return (
            <div className="col ask-ai-tile px-2 px-md-3 py-3">

                <div className="row">
                    <div className="col-auto">
                        <AutoAwesomeIcon />
                    </div>
                    <div className="col">
                        <div className="row justify-content-between">
                            <div className="col-8 col-md-auto text-start">
                                <h4>How does this shoe perform?</h4>
                            </div>
                            <div className="col-4 col-md-auto">
                                <GeneralTooltip title={`Scores are generated by our AI from our analysis of the key traits of this shoe based on wear-tester and reviews from Fittir based on the ratio of positives and negatives mentioned in each category.`} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col text-start">
                        <p>Based on our analysis of <span onClick={(evt) => setOpen(true)}>wear-tester </span>and runner reviews across Fittir and the internet, here's the most frequent things we found.</p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-11 col-md-6 my-1 text-center">
                        <Alert severity='success' icon={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >
                            <InsertEmoticonIcon /> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedPros[0]?.group, sortedSubGroups.sortedPros[0]?.subGroup )}>{sortedSubGroups.sortedPros[0]?.group}: {sortedSubGroups.sortedPros[0]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedPros[1]?.group, sortedSubGroups.sortedPros[1]?.subGroup )}>{sortedSubGroups.sortedPros[1]?.group}: {sortedSubGroups.sortedPros[1]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedPros[2]?.group, sortedSubGroups.sortedPros[2]?.subGroup )}>{sortedSubGroups.sortedPros[2]?.group}: {sortedSubGroups.sortedPros[2]?.subGroup}</span> <br></br>
                        </Alert>
                    </div>
                    <div className="col-11 col-md-6 my-1 text-center">
                        <Alert severity='warning' icon={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >
                            <SentimentVeryDissatisfiedIcon/> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedCons[0]?.group, sortedSubGroups.sortedCons[0]?.subGroup )}>{sortedSubGroups.sortedCons[0]?.group}: {sortedSubGroups.sortedCons[0]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedCons[1]?.group, sortedSubGroups.sortedCons[1]?.subGroup )}>{sortedSubGroups.sortedCons[1]?.group}: {sortedSubGroups.sortedCons[1]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedCons[2]?.group, sortedSubGroups.sortedCons[2]?.subGroup )}>{sortedSubGroups.sortedCons[2]?.group}: {sortedSubGroups.sortedCons[2]?.subGroup}</span> <br></br>
                        </Alert>
                    </div>
                </div>

                {/* Add back in unselectable */}
                <div className="row unselectable mt-3"> 
                    <div className="col" style={{ position: 'relative' }}>
                        {shoeTraitGroupings?.map((shoeTrait, index) => {
                            const pros = sortedReviews[shoeTrait]?.pros || [];
                            const cons = sortedReviews[shoeTrait]?.cons || [];
                            const sortedsub_groups = getSortedsub_groupsByType({ [shoeTrait]: { pros, cons } });
                            const proUrlMap = websiteReviews.reduce((map, review) => {
                                review.prosList.forEach(pro => {
                                    map[pro.summary] = {
                                        url: review.url,
                                        reviewer: review.reviewer,
                                        title: review.title,
                                        type: review.type
                                    };  // Map pro.summary to an object containing multiple fields
                                });
                                return map;
                            }, {});
                            const conUrlMap = websiteReviews.reduce((map, review) => {
                                review.consList.forEach(con => {
                                    map[con.summary] = {
                                        url: review.url,
                                        reviewer: review.reviewer,
                                        title: review.title,
                                        type: review.type
                                    };  // Map con.summary to an object containing multiple fields
                                });
                                return map;
                            }, {});
                            return (
                                <div key={`${shoeTrait}RatingRow`} className="row align-items-center my-1">
                                    <div className="col">
                                    <Accordion 
                                            style={{ boxShadow: 'none', border: '1px solid #EEEBEB' }}
                                            expanded={openVariable === shoeTrait}  // Make sure the comparison is correct
                                            onChange={() => {
                                                setOpenVariable(openVariable === shoeTrait ? null : shoeTrait);
                                            }}  // Toggling the accordion open/close
                                            key={`${openVariable}isOpen`}
>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${shoeTrait}RatingRow-content`} id={`${shoeTrait}RatingRow-header`}>
                                                <div className="col">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col">
                                                            <div className="row justify-content-between">
                                                                <div className="col-auto">
                                                                    <div className="row">
                                                                        <div className="col-auto d-none d-sm-inline">
                                                                            {icons[shoeTrait]}
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <h3 className="my-0">{shoeTrait}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {sortedReviews && (
                                                                    <div className="col-auto me-3">
                                                                        <Tooltip title={`Pros: ${sortedReviews[`${shoeTrait}`]?.pros.length}, Cons: ${sortedReviews[`${shoeTrait}`]?.cons.length}`}>
                                                                            <div className="row align-items-center">
                                                                                <Rating
                                                                                    name={`${shoeTrait}-rating`}
                                                                                    value={Math.round(
                                                                                        (sortedReviews[`${shoeTrait}`]?.pros.length /
                                                                                        (sortedReviews[`${shoeTrait}`]?.pros.length + sortedReviews[`${shoeTrait}`]?.cons.length)) * 5 + 0.5
                                                                                    )}
                                                                                    readOnly
                                                                                    precision={0.5}
                                                                                />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="row">
                                                    <div className="col">
                                                        <TraitSummaryCard shoeTrait={shoeTrait} selectedsub_group={selectedsub_group} prosSummaryList={filteredPros(pros, shoeTrait)} consSummaryList={filteredCons(cons, shoeTrait)} />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        {/* Render Chips for sub_groups */}
                                                        {sortedsub_groups.slice(0, 8).map(([sub_group]) => (
                                                            <Chip
                                                                key={sub_group}
                                                                size='small'
                                                                label={`${sub_group}`}
                                                                clickable
                                                                color={selectedsub_group[shoeTrait] === sub_group ? 'primary' : 'default'}
                                                                onClick={() => handlesub_groupClick(shoeTrait, sub_group)}
                                                                onDelete={selectedsub_group[shoeTrait] === sub_group ? () => handleClearsub_group(shoeTrait) : null}
                                                                deleteIcon={selectedsub_group[shoeTrait] === sub_group ? <CloseIcon /> : undefined}
                                                                style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                {/* Display filtered pros and cons */}
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <h5>Pros</h5>
                                                        {filteredPros(pros, shoeTrait).map((pro, index) => {
                                                            var review = proUrlMap[pro.summary]
                                                            return (
                                                                <div key={index}>
                                                                    <GeneralTooltip 
                                                                        title={review.type === 'youtube' ? <span><i>'{review.title}'</i> - {review.reviewer} (youtube)</span> : <span>{review.url} (written)</span> || 'No URL found'}  // Use the preprocessed map to find the URL
                                                                        content={review.type === 'youtube' ? <YouTubeIcon /> : <LibraryBooksIcon />}
                                                                    />
                                                                    <p className='mt-2'>{pro.summary}</p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>


                                                    
                                                    <div className="col">
                                                        <h5>Cons</h5>
                                                        {filteredCons(cons, shoeTrait).map((con, index) => {
                                                            var review = conUrlMap[con.summary]
                                                            // Find the review that contains this specific con

                                                            return (
                                                                <div key={index}>
                                                                    <GeneralTooltip 
                                                                        title={review.type === 'youtube' ? <span><i>'{review.title} </i> - {review.reviewer} (youtube)</span> : <span>{review.url} (written)</span> || 'No URL found'}  // Use the preprocessed map to find the URL
                                                                        content={review.type === 'youtube' ? <YouTubeIcon /> : <LibraryBooksIcon />}
                                                                    />
                                                                    <p className='mt-2'>{con.summary}</p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return null;
}