import { Form, ScrollRestoration, useNavigate } from "react-router-dom"
import { useState, useEffect, Suspense } from "react"
import axios from "axios"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './index.css'
import { useAppContext } from "../../../../utils/ContextProvider.jsx"
import Loader from "../../../../components/Loader"
import * as Sentry from "@sentry/react";
import Button from "@mui/material/Button"

export default function BrandPage({ progress, section, isAutoComplete} ) {

    const [shoeBrands, setShoeBrands] = useState([]);
    const [allBrands, setAllBrands] = useState([])

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl, partner } = useAppContext()

    const navigate = useNavigate()

    useEffect(() => {
        var url = null
        if (!partner) {
            url = `${requestUrl}/api/brands`
        } else if (partner) {
            url = `${requestUrl}/api/brands?partner=${partner._id}`
        }
        
        setLoading(true)
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setAllBrands(res.data.allBrands)
            // If no brands, set shoeBrands to empty array
            if (res.data.allBrands.length === 0) {
                var preferencesString = localStorage.getItem("preferences")
                var preferencesObject = JSON.parse(preferencesString)
                var newPreferencesObject = {...preferencesObject, "shoeBrands": []}
                var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
                localStorage.setItem("preferences", newPreferencesObjectString)
                navigate('/shoe-finder/preferences/price')
            }
            setLoading(false)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setLoading(false)
        })
    }, [])

    const handleClick = (evt) => {
        var value = ""
        if (!evt.target.value) {
            value = evt.target.parentElement.value
        } else {
            value = evt.target.value
        }
        if (shoeBrands.includes(value)) {
            let updatedShoeBrands = shoeBrands.filter(brand => brand !== value)
            setShoeBrands(updatedShoeBrands)
        } else {
            var brand = value
            setShoeBrands(currData => {
                return [
                    ...currData,
                    brand
                ]
            })
        }
        
    }

    const handleSubmit = (evt) => {
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, "shoeBrands": shoeBrands}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        navigate('/shoe-finder/preferences/price')
    }
    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <FormHeader progress={progress} section={section} />
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-lg py-5 main-form-body">
                <FormHeader progress={progress} section={section} />
                <div className="container form-body mt-4">
                    <div className="row">
                        <div className="col text-center text-sm-start">
                            <h2>What brands are you interested in?</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center text-sm-start">
                            <p>You can skip this question if you don't have any preferences.</p>
                        </div>
                    </div>
                    <div className="row">
                    {allBrands.map((brand, index) => 
                                <div className={`col-6 col-md-3 col-lg-2 py-2 mt-md-2 mx-auto`} key={brand.name + 'Button'}>
                                    <Button 
                                        type="button" 
                                        className={"btn form-card-button py-4 py-md-5 mx-auto text-center"} 
                                        name="brand" 
                                        value={brand.name} 
                                        onClick={handleClick} 
                                        aria_pressed={shoeBrands.includes(brand.name) ? "true" : "false"} 
                                        sx={{
                                            color: 'black',
                                            border: shoeBrands.includes(brand.name) ? `1px solid ${partner ? partner.primary : '#1f8bf4'} !important` : '1px solid #EEEBEB !important',
                                            textTransform: 'none', // Default and selected border color
                                            '&:hover': {
                                              borderColor: partner? `${partner.primary} !important` : null,
                                              backgroundColor: 'white' // Hover border color depending on selected state
                                            },
                                            '&:active': {
                                                borderColor: partner? `${partner.primary} !important` : null,
                                                backgroundColor: 'white' // Hover border color depending on selected state
                                            }
                                            ,
                                            '&:focus': {
                                                borderColor: partner? `${partner.primary} !important` : null,
                                                backgroundColor: 'white' // Hover border color depending on selected state
                                              }
                                              
                                          }}
                                        >
                                        <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/grey-scale/${brand.slug}.png`} alt="" />
                                    </Button>
                            </div>
                            )}
                    </div>
                    
                </div>
                
                <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit}  />    
            </div>
                    
        )
    }
}