var shoeTraitGroupings = [
    'cushion',
    'comfort',
    'durability',
    'performance',
    'fit',
    'stability',
    'grip',
    'other'
]

export { shoeTraitGroupings }