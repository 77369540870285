import { useNavigate, useLocation, Link, useOutletContext } from "react-router-dom"
import { useState, useEffect, Suspense, useContext } from "react"
import FormHeader from "../../../components/Form/FormHeader"
import FormFooter from "../../../components/Form/FormFooter"
import { useAppContext } from '../../../utils/ContextProvider.jsx';
import RecommendationReasoningModal from "../../../components/Dashboard/Modal/RecommendationReasoningModal";
import ShoeCardResult from "./ShoeCardResult";
import { Button } from "@mui/material";
import axios from "axios";
import Pagination from "@mui/material/Pagination";



export default function ResultPage({ progress, section, isAutoComplete} ) {

    const { requestUrl, partner } = useAppContext()

    var { recommendedShoe, recommendation, alert } = useLocation().state || {}

    var [recommendationId, setRecommendationId] = useState()

    var [partnerDeals, setPartnerDeals] = useState([])

    console.log(partnerDeals)

    var [recommendedShoeObject, setRecommendedShoeObject] = useState()

    var [recommendationObject, setRecommendationObject] = useState()

    var [page, setPage] = useState(1)


    const navigate = useNavigate()

    useEffect(() => {
        if (recommendedShoe) {
            var shoe = JSON.parse(recommendedShoe)
            setRecommendedShoeObject(shoe)  
        }

        if (partner) {
            axios.get(`${requestUrl}/api/partnerDeals/partner/${partner._id.toString()}`)
            .then((res) => {
                setPartnerDeals(res.data)
            })
        }
        
        if (recommendation) {
            var rec = JSON.parse(recommendation)      
            setRecommendationObject(rec)
        } else {
            navigate('/shoe-finder/intro')
        }

    }, [partner])

    const handlePageChange = (evt, page) => {
        setPage(page)
    }


    const [open, setOpen] = useState(false);

    const filteredShoes = recommendationObject?.shoes.filter((shoe) => 
        partnerDeals.some(deal => deal.shoe._id.toString() === shoe.shoe._id.toString())
    );

    const totalPages = Math.ceil(filteredShoes?.length / 3);

    var [expanded, setExpanded] = useState(false)

    const handleAccordianClick = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="container-lg py-5 main-form-body">
            
            <FormHeader progress={progress} section={section} />
            <div className="container form-body mt-4">
                <div className="row">
                    <div className="col text-center text-md-start">
                        <h2>Your running shoe recommendations</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center text-md-start">
                        <p>Based on your requirements, goals, and preferences (<span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => setOpen(true)}>learn more</span>)</p>
                    </div>
                </div>
                <div className="row align-items-top">
                    {filteredShoes
                        ?.sort((a, b) => {
                            return b.score - a.score || 
                                   (b.shoe.averageWebsiteScore * b.shoe.websiteReviews.length) - 
                                   (a.shoe.averageWebsiteScore * a.shoe.websiteReviews.length)
                        })
                        .slice((page - 1) * 3, page * 3)
                        .map((shoe) => {
                            return (
                                <ShoeCardResult 
                                    shoe={shoe} 
                                    requirements={recommendationObject.requirements} 
                                    expanded={expanded}
                                    handleAccordianClick={handleAccordianClick}
                                    open={open} 
                                    setOpen={setOpen} 
                                    partnerDeals={partnerDeals} 
                                    recommendation={recommendationObject}
                                />
                            )
                        })}
                    
                </div>
                <div className="row justify-content-center">
                    <div className="col-auto pt-4">
                        <Pagination 
                        boundaryCount={1} 
                        count={totalPages} 
                        variant="outlined" 
                        onChange={handlePageChange} />
                    </div>
                    
                </div>
            </div>
            <FormFooter isAutoComplete={isAutoComplete} /> 
            <RecommendationReasoningModal openState={open} setOpenState={setOpen} recommendation={recommendationObject} />  

        </div>
                 
    )
}
