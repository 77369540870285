import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"
import { runningProfileOptions } from "../../../../../utils/ProfileOptions"

export default function InjuryLocationPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        navigate(`${evt.target.value}`, { state: { injuryLocation: evt.target.value } })
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"What part of your body was your injury in?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={runningProfileOptions.injuryLocation}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}