import { useEffect, useState } from "react"
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import React from "react"
import FormHeader from "../../../components/Form/FormHeader"
import FormBody from "../../../components/Form/FormBody"
import FormFooter from "../../../components/Form/FormFooter"
import { useAppContext } from "../../../utils/ContextProvider.jsx"
import { partners } from "../../../utils/Partners"
import axios from 'axios'

export default function FormIntro({ progress = "0", section = null, isAutoComplete }) {

    var { partner, setPartner, requestUrl, setIsAlert, trackEvent } = useAppContext()

    // Fetch to see if the form is being used by a partner or not
    var [ searchParams, setSearchParams ] = useSearchParams()
    var partnerId = searchParams.get('partner')

    const navigate = useNavigate()

    useEffect(()=> {

        // if user, redirect to the end of the form
       var user = localStorage.getItem('isAuthenticated')
       if (user) {
        navigate('/shoe-finder/preferences/surface')
       }

        // If coming from partner view, set partner information based on what was available in the url.
       if (partnerId) {
            var url = `${requestUrl}/api/partners/${partnerId}`
            axios({
                url: url,
                method: "GET",
                withCredentials: true
            })
            .then((res) => {
                setPartner(res.data.partner)
            })
            .catch((err) => {
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: errorMessage
                    }
                })
            })
       }

    },[])

    const handleSubmit = (evt) => {
        trackEvent('Form start', { partner: partner?.name || null })
        navigate('/shoe-finder/running-profile')
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={partnerId ? "Find the perfect running shoes for you" : "Let's find you the right running shoe"} 
                subHeading={"Complete a short questionnaire, and we'll match you to the perfect shoe based on your needs and preferences."}
                completeTime={"5"} 
                formBodyType={"Intro"}
            />
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit} partner={partner}  />    
        </div>
                
    )
}
