import { Outlet, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../utils/ContextProvider.jsx";
import FlashAlert from "../../components/Alert/index.jsx";
import Loader from "../../components/Loader/index.jsx";
import Footer from "../../components/Footer/index.jsx";
import { ScrollRestoration } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function FormPage() {

    const { isLoading, isAlert, setAlertState, setIsAlert, setIsLoading, requestUrl, partner, setPartner, userLocation } = useAppContext()

    return (
        <div>
            <Helmet>
                <title>Fittir - Shoe Finder</title>
                <meta name="description" content="Complete a short questionnaire to help us recommend you the perfect running shoe based on your needs and preferences.r" />
                <meta property="og:title" content="Fittir - Shoe Finder" />
                <meta property="og:description" content="Complete a short questionnaire to help us recommend you the perfect running shoe based on your needs and preferences." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/meta/og_image.png" />
            </Helmet>
            <Outlet context={[partner]}/>   
            {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
            {isAlert.success ? <FlashAlert severity={"success"} message={isAlert.success} setAlertState={setAlertState} />: null}
            {!partner ? <Footer /> : null} 
            <ScrollRestoration />
        </div>
    )
}
