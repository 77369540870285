import { Form, useNavigate, useParams } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"
import { runningProfileOptions } from "../../../../../utils/ProfileOptions"

export default function InjuryTypePage({ progress, section, isAutoComplete} ) {
    
    const {injuryLocation} = useParams()

    var selectionOptions = runningProfileOptions.injuryType[injuryLocation]

    const navigate = useNavigate()

    const handleClick = (evt) => {
        var injury = 
            {
                [injuryLocation]: evt.target.value
            }

        // Fetch the running profile from window.localStorage and parse it into an object
        var runningProfileString = localStorage.getItem('runningProfile')
        var runningProfileObject = JSON.parse(runningProfileString)

        
        if (runningProfileObject.injuries) {
            // If the injury object exists (aka this is a repeat injury submission), fetch, push, stringify and reset
            runningProfileObject.injuries.push(injury)
            let newRunningProfileObjectString = JSON.stringify(runningProfileObject)
            localStorage.setItem('runningProfile', newRunningProfileObjectString)
        } else {
            // If the injury object does not exist (aka this is the first injury submission), add an array of injuries to the 'injuries' key in the runningProfile object
            runningProfileObject['injuries'] = [injury]
            let newRunningProfileObjectString = JSON.stringify(runningProfileObject)
            localStorage.setItem('runningProfile', newRunningProfileObjectString)
        }
        navigate('/shoe-finder/running-profile/injury-repeat')
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={`What kind of ${injuryLocation} injury did you have?`} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}