import { Link, Outlet } from "react-router-dom";
import './index.css'
import { useAppContext } from "../../utils/ContextProvider.jsx";
import FlashAlert from "../../components/Alert";

export default function AuthPage() {
    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl } = useAppContext()
    const url = window.location.pathname
    return (
        <div className="no-nav-or-footer">
            <div className="container-md align-self-center my-3 pt-5">
                <div className="row">
                    <div className="col-11 col-md-10 col-xl-8 mx-auto form-container mb-5">
                        <Outlet />  
                    </div>
                </div>
            </div>
        </div>
        
    )
}