import Skeleton from '@mui/material/Skeleton';

export default function ResponseSection({ isProcessing = null, setIsProcessing = null, response = null }) {


    if (isProcessing) {
        return (
            <div className="row mt-3">
                <div className="col">
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </div>
            </div>
        )
    } else {
        return (
            <div className={response ? 'row mt-3' : 'row'}>
                <div className="col">
                    <p><i>{response}</i></p>
                </div>
            </div>
        )
    }
}