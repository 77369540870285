import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "Yes",
        subHeading: null,
        name: "ArchHeightKnown",
        value: true

    },
    {
        title: "No",
        subHeading: null,
        name: "ArchHeightKnown",
        value: false

    }
]

export default function ArchHeightYesNoPage({ progress, section, isAutoComplete} ) {

    const navigate = useNavigate()

    const handleClick = (evt) => {
        if (!evt.target.value) {
            navigate('/shoe-finder/running-profile/arch/unknown')
        } else {
            navigate('/shoe-finder/running-profile/arch/known')
        }
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Do you know how high the arch of your foot is?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
                toolTip={"Arch height refers to the distance between the ground and the top of the arch of your foot, commonly grouped into low (flat-feet), normal and high."}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}