import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"
import { preferencesOptions } from "../../../../../utils/PreferencesOptions"

var selectionOptions = [
    {
        title: "Yes",
        subHeading: null,
        name: "rockPlate",
        value: true

    },
    {
        title: "No",
        subHeading: null,
        name: "rockPlate",
        value: false

    }
]

export default function RockPlatePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, "rockPlate": evt.target.value}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        if (preferencesObject.trailSurface === 'technical' || preferencesObject.trailSurface === 'moderate') {
            navigate('/shoe-finder/preferences/surface/conditions')
        } else {
            navigate('/shoe-finder/preferences/usage')
        }
        
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Do you expect to be running on rocky surfaces frequently?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={preferencesOptions.rockPlate}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
                toolTip={"This helps us understand whether or not you need protection from rocks, commonly given in shoes with rock-plates."}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}