import axios from 'axios';
var requestUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : 'https://fittir.io';

export const createEvent = async (eventData) => {
  try {
    const response = await axios.post(`${requestUrl}/api/events`, eventData, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};
