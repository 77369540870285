import { RoadHorizon, Tree, ArrowsMerge, Speedometer, Ruler, Hammer, Cloud, Scales, FastForward, FlagCheckered, Barbell, Wind } from '@phosphor-icons/react'

var preferencesOptions = {
    shoeSurface: [
        {
            title: "Road running",
            subHeading: null,
            name: "shoeSurface",
            value: "road",
            icon: <RoadHorizon />
    
        },
        {
            title: "Trail running",
            subHeading: null,
            name: "shoeSurface",
            value: "trail",
            icon: <Tree />
    
        },
        {
            title: "A bit of both",
            subHeading: "(best for light trails and short stints on road)",
            name: "shoeSurface",
            value: "hybrid",
            icon: <ArrowsMerge />
    
        }
    ],
    shoeUsage: [
        {
            title: "A balanced all-rounder",
            subHeading: "(everyday shoe)",
            name: "shoeUsage",
            value: "everyday",
            icon: <Hammer />
    
        },
        {
            title: "Specifically for long training runs & events",
            subHeading: "(long distance shoe)",
            name: "shoeUsage",
            value: "long_distance",
            icon: <Ruler />
    
        },
        {
            title: "Specifically for faster runs & racing",
            subHeading: "(speed shoe)",
            name: "shoeUsage",
            value: "speed",
            icon: <Speedometer />
    
        }
    ],
    shoeBrands: [
        {
            title: "0-18y/o",
            subHeading: null,
            name: "age",
            value: "0-18y/o"
    
        },
        {
            title: "19-35y/o",
            subHeading: null,
            name: "age",
            value: "19-35y/o"
    
        },
        {
            title: "36-50y/o",
            subHeading: null,
            name: "age",
            value: "36-50y/o"
    
        }
    ],
    shoeBudget: {
        low: 0,
        high: 550
    },
    everydayShoeType: [
        {
            title: "soft & comfortable",
            subHeading: "",
            name: "everydayShoeType",
            value: "comfort",
            icon: <Cloud />
    
        },
        {
            title: "a balanced all-rounder",
            subHeading: null,
            name: "everydayShoeType",
            value: "balanced",
            icon: <Scales />
    
        },
        {
            title: "fast and responsive",
            subHeading: "",
            name: "everydayShoeType",
            value: "responsive",
            icon: <FastForward />
    
        }
        ,
        {
            title: "I don't mind",
            subHeading: null,
            name: "everydayShoeType",
            value: "n/a"
    
        }
    ],
    trailSurface: [
        {
            title: "Light trails",
            subHeading: "e.g gravel roads, walking trails",
            name: "trailSurface",
            value: "light"
    
        },
        {
            title: "Moderate trails",
            subHeading: "e.g maintained single track, mountain-bike trails ",
            name: "trailSurface",
            value: "moderate"
    
        },
        {
            title: "Technical trails",
            subHeading: "e.g muddy trails, back-country, mountain ridge lines ",
            name: "trailSurface",
            value: "technical"
    
        }
    ],
    rockPlate: [
        {
            title: "Yes",
            subHeading: null,
            name: "rockPlate",
            value: "true"
    
        },
        {
            title: "No",
            subHeading: null,
            name: "rockPlate",
            value: "false"
    
        }
    ],
    conditions: [
        {
            title: "Yes",
            subHeading: null,
            name: "conditions",
            value: "wet"
    
        },
        {
            title: "No",
            subHeading: null,
            name: "conditions",
            value: "dry"
    
        }
    ],
    speedType: [
        {
            title: "best for short 5k or 10k races",
            subHeading: "(Lightweight & responsive)",
            name: "speedType",
            value: "min",
            icon: <Wind />

    
        },
        {
            title: "versatile for training and races",
            subHeading: "(Firm, cushioned & responsive)",
            name: "speedType",
            value: "med",
            icon: <Barbell />
    
        },
        {
            title: "best for marathons & half-marathon races",
            subHeading: "(Max cushion & carbon-plated)",
            name: "speedType",
            value: "max",
            icon: <FlagCheckered />
    
        }
    ],
}

export { preferencesOptions }