import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import { runningProfileOptions } from "../../../../utils/ProfileOptions"

export default function ExperiencePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var object = {
            [evt.target.name]: evt.target.value
        }
        var objectString = JSON.stringify(object)
        localStorage.setItem("runningProfile", objectString)
        navigate('/shoe-finder/running-profile/weekly-distance')
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"How experienced of a runner are you?"} 
                subHeading={"Please select one."}
                completeTime={"5"} 
                formBodyType={"Selection"}
                selectionOptions={runningProfileOptions.experience}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}