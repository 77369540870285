import Modal from "../../../Modal"
import * as React from 'react'
import axios from "axios";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import { countries } from "../../../../utils/Countries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import ChipComponent from "../../../Chip";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import * as Sentry from "@sentry/react";

export default function UpgradePlanModal({openState = null, setOpenState = null, shoe = null, colorway = null, requirements = null, user = null}) {

    const { upgradeModalOpen, setUpgradeModalOpen, requestUrl, setIsAlert, setAlert, isProcessing, setProcessing, trackEvent } = useAppContext()

    var navigate = useNavigate()

    useEffect(()=> {
        if (upgradeModalOpen) {
            trackEvent('View upgrade modal', { page: window.location.pathname })
        }
    },[upgradeModalOpen])

    var handleClick = (evt) => {
        setProcessing(true)
        trackEvent('Click to upgrade', { page: window.location.pathname })
        axios({
            method: 'POST',
            url: `${requestUrl}/api/users/upgrade`,
            data: {
                originalUrl: window.location.pathname
            },
            withCredentials: true
        })
        .then((res) => {
            setProcessing(false)
            window.location.href = res.data.url;
        })
        .catch((err) => {
            console.log(err)
            setProcessing(false)
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: errorMessage
                    }
                })
        })
    }

    const content = 
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <p>Make the most of our database of online reviews, AI tools, and easy summaries to save you time reading reviews & comparing shoes.</p>
                        </div>
                    </div>
                    <div className="row justify-content-between mt-4">
                        <div className="col d-none d-md-inline">
                            <div className="row">
                                <div className="col grey-outline mx-0 mx-md-4">
                                    <div className="row">
                                        <div className="col px-4 py-4">
                                            {user?.planType == 'free' ?
                                            <div className="row">
                                                <div className="col">
                                                    <ChipComponent
                                                        size={'small'}
                                                        label={`Current`}
                                                        color={'default'}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h3>Recommendations & Guidance</h3>
                                                </div> 
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <h2 style={{fontSize: '2.5rem'}}>Free</h2>
                                                </div> 
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <p><DoneIcon/> Multiple tailored recommendations for shoes based on surface, intended use and other factors</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <p><DoneIcon/> Match score for each shoe based on your needs and the intended use</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Expert guidance of your running shoe needs based on your profile</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Ability to compare shoes from all brands</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Access to reviews from similar runners on Fittir</p>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col blue-outline mx-0 mx-md-4">
                                    <div className="row">
                                        <div className="col px-4 py-4">
                                            {user?.planType == 'paid' ?
                                            <div className="row">
                                                <div className="col">
                                                    <ChipComponent
                                                        size={'small'}
                                                        label={`Current`}
                                                        color={'primary'}
                                                    />
                                                </div>
                                            </div>
                                            : <AutoAwesomeIcon color="primary"/>}
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h3>Research Assistant</h3>
                                                </div> 
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <h2 style={{fontSize: '2.5rem'}}>$4.99 <span style={{fontSize: '1rem'}}>(USD, one-time)</span></h2>
                                                </div> 
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <p><i>Everything in basic plus...</i></p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Use our personal research assistant to ask questions about running shoes and get answers based on all reviews</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Summaries of pros and cons for shoes from all written reviews from google to save time researching</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Summaries in the comparison view to help compare different shoes based on their key traits</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p><DoneIcon/> Advanced insights based on wear tester reviews to inform your recommendations</p>
                                                </div> 
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-auto mx-auto">
                                                        <Button
                                                            size="large"
                                                            onClick={handleClick}
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={isProcessing == true ? true : false}
                                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                                        >Upgrade
                                                        </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <h2>What some of our premium users have to say..</h2>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col">
                        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
                        <div className="elfsight-app-bcb30900-50b5-4432-85ab-3237c3670510" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                </div>

    return (
        <Modal
            heading={`Upgrade Fittir one-time to be your personlised, AI shoe research & comparison assistant.`}
            width="md"
            content={content}
            openState={upgradeModalOpen}
            setOpenState={setUpgradeModalOpen}
        />
    )
}