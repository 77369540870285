import { Form, ScrollRestoration, useNavigate } from "react-router-dom"
import { useState, useEffect, Suspense } from "react"
import axios from "axios"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './index.css'
import LinearProgress from '@mui/material/LinearProgress';
import { useAppContext } from "../../../../utils/ContextProvider.jsx"
import Loader from "../../../../components/Loader"
import ChipComponent from "../../../../components/Chip"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from '@mui/material/TextField';
import Badge from "@mui/material/Badge"
import IconButton from "@mui/material/IconButton"
import CancelIcon from '@mui/icons-material/Cancel';
import GeneralTooltip from "../../../../components/ToolTip"
import ButtonGroup from "@mui/material/ButtonGroup"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import { Tooltip } from "@mui/material"
import Rating from "@mui/material/Rating"
import * as Sentry from "@sentry/react";


export default function CurrentShoePage({ progress, section, isAutoComplete} ) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl } = useAppContext()

    const [currentShoes, setCurrentShoes] = useState([]);

    const [currentShoesData, setCurrentShoesData] = useState([])

    const [allShoes, setAllShoes] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        var url = `${requestUrl}/api/shoes`
        setLoading(true)
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setAllShoes(res.data.allShoes)
            setLoading(false)
            
        })
        .catch((err)=> {     
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setLoading(false)
            
        })
        
    }, [])

    const handleChange = (evt, value) => {
        if (value) {
            var shoeData = []
            for (let i = 0; i < value.length; i++) {
                shoeData.push({
                    shoeId: value[i]._id,
                    reviewData: {
                        rating: currentShoesData[i]?.reviewData.rating || 0,
                        usage: currentShoesData[i]?.reviewData.usage || '0-50km',
                        description: currentShoesData[i]?.reviewData.description || ""
                    }
                })
            }
            setCurrentShoes(value)
            setCurrentShoesData(shoeData)

        }
        
        
    };

    const clearCurrentShoe = (evt, shoeId) => {
        const updatedCurrentShoes = currentShoes.filter((shoe) => shoe._id !== shoeId)
        const updatedCurrenShoesData = currentShoesData.filter((shoe) => shoe.shoeId !== shoeId)
        setCurrentShoes(updatedCurrentShoes)
        setCurrentShoesData(updatedCurrenShoesData)
    }

    const updateCurrentShoeData = (evt, index, shoeId) => {
        const changedField = evt.target.name
        const newValue = evt.target.value
        var data = currentShoesData.slice()
        data[index].reviewData[changedField] = newValue
        setCurrentShoesData(data)
        
    }

    const handleSubmit = (evt) => {
        var error = null
        for (let n = 0; n < currentShoesData.length; n++) {
            if (currentShoesData[n].reviewData.rating === 0 || currentShoesData[n].reviewData.description === "") {
                error = "Please fill out the required information."
            }
        }
        if (!error) {
            var runningProfileString = localStorage.getItem("runningProfile")
            var runningProfileObject = JSON.parse(runningProfileString)
            var newRunningProfileObject = {...runningProfileObject, ['currentShoe']: currentShoesData}
            var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
            localStorage.setItem("runningProfile", newRunningProfileObjectString)
            navigate('/shoe-finder/running-profile/past-shoe', {
                state: {
                    shoeList: allShoes
            }
            })
        } else {
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: error
                }
            })
        }
        
    }

    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <FormHeader progress={progress} section={section} />
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
            
                
        )
    } else {
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            
            <div className="container form-body mt-4">
                <div className="row">
                    <div className="col-auto text-center text-sm-start">
                        <h2>What shoes are you currently running in? </h2>
                    </div>
                    <GeneralTooltip title={"We use your current shoes to inform your recommendation, including features of your shoes to reduce any impacts of change and also what is/isn't currently working for you."}/>
                </div>
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <p>You can add multiple. If you can't find your current running shoe, you can skip this question.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 mx-auto mx-md-0">
                    <Autocomplete
                        id="country-select-demo"
                        options={allShoes}
                        value={currentShoes}
                        multiple
                        autoHighlight
                        getOptionLabel={(shoe) => `${shoe.brand?.name} ${shoe.model} ${shoe.version} (${shoe.gender})`}
                        onChange={(evt, value) => handleChange(evt, value)}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Select a shoe"
                            autoComplete="false"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                            />
                        )}
                        />
                    </div>
                </div>

                    <div className="row align-items-center mt-3">

                        {currentShoes.map((currentShoe, index) => {
                            return (
                                <div className="col-12 mx-auto my-1" key={`${currentShoe?._id}ShowShoeRow`}>
                                    <div className="row">
                                        <div className="col grey-outline">
                                        <Badge badgeContent={<IconButton size="large" onClick={(evt) => clearCurrentShoe(evt, currentShoe?._id)}><CancelIcon fontSize="inherit"/></IconButton>} >
                                            <div className="row align-items-center">
                                                <div className="col-10 col-lg-5 mx-auto">
                                                    <div className="row">
                                                        <div className="col-6 mx-auto text-center">
                                                            <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${currentShoe?.brand.slug}.png`} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-10 mx-auto text-center">
                                                            <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${currentShoe?.brand.slug}_${currentShoe?.modelSlug}_${currentShoe?.version}_${currentShoe?.colorways[0]?.colorwayCode}.png`} alt="" />        
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col text-center">
                                                            <h1>{currentShoe?.brand.name} {currentShoe?.modelSlug} {currentShoe?.version}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-10 mx-auto col-lg-7">

                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h2>Your experience</h2>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p>Tell us about your experience with this shoe to improve your recommendation.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <h4>Rating</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                                <Rating
                                                                        name="rating"
                                                                        size="large"
                                                                        value={currentShoesData[index].reviewData.rating} 
                                                                        onChange={(evt) => updateCurrentShoeData(evt, index, currentShoe?._id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  

                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h4>Description</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                                <TextField 
                                                                    // error={formDataError.description === true ? true : null} 
                                                                    // helperText={formDataError.description === true? "Description required": null} 
                                                                    id="descriptionTextField" 
                                                                    label="Description" 
                                                                    variant="outlined" 
                                                                    name="description" 
                                                                    multiline 
                                                                    sx={{width: '90%'}}
                                                                    value={currentShoesData[index].reviewData.description}
                                                                    helperText="The more detail the better, we use this to support your recommendation. e.g I like ___, I don't like ___"
                                                                    onChange={(evt) => updateCurrentShoeData(evt, index, currentShoe?._id)}  
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                
                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h4>Usage</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                            <FormControl sx={{width: '50%'}}>
                                                                <InputLabel id="usageSelector">Distance used for</InputLabel>
                                                                <Select
                                                                labelId="distanceRanSelect"
                                                                id="usageSelector"
                                                                value={currentShoesData[index].reviewData.usage} 
                                                                label="Distance used for"
                                                                name="usage"
                                                                onChange={(evt) => updateCurrentShoeData(evt, index, currentShoe?._id)} 
                                                                >
                                                                    <MenuItem value={"0-50km"}>0-50km</MenuItem>
                                                                    <MenuItem value={"50-100km"}>50-100km</MenuItem>
                                                                    <MenuItem value={"100-150km"}>100-150km</MenuItem>
                                                                    <MenuItem value={"150-200km"}>150-200km</MenuItem>
                                                                    <MenuItem value={">250km"}> More than 250km</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                </div>
                                            </div>
                                            </Badge>
                                        </div>
 
                                    </div>
                                </div> 
                            )
                        })}

                        

                    </div>

                
            </div>
            
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit}  />    
        </div>
                 
    )
}}