import './index.css'

export default function FormBodyIntro({ completeTime = "5" }) {
    return (

        <div className="row mb-0 mt-auto form-body-intro">
            <div className="col text-center text-sm-start">
                <p className="px-0"> Estimated time to complete: {completeTime} minutes</p>
            </div>
        </div>

    )
}