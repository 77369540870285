import { Form, useLocation, useNavigate, useParams } from "react-router-dom"
import FormHeader from "../../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../../components/Form/FormFooter"
import FormBody from "../../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "Yes",
        subHeading: null,
        name: "footSpillOver",
        value: true

    },
    {
        title: "No",
        subHeading: null,
        name: "footSpillOver",
        value: false

    }
]

export default function FootWidthSpillOverPage({ progress, section, isAutoComplete} ) {

    const navigate = useNavigate()
    
    var { shoeWidth } = useLocation().state

    const handleClick = (evt) => {
        var name = "footWidth"
        var value = ""
        if (evt.target.value) {
            if (shoeWidth === "narrow") {
                value = "normal"
            } else if (shoeWidth === "normal") {
                value = "wide"
            } else if (shoeWidth === "wide") {
                value = "extra wide"
            } else if (shoeWidth === "extra wide") {
                value = "extra wide"
            }
        } else {
            value = shoeWidth
        }
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        var newRunningProfileObject = {...runningProfileObject, [name]: value}
        var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
        localStorage.setItem("runningProfile", newRunningProfileObjectString)
        navigate('/shoe-finder/running-profile/age')
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Do your feet spill-over the edge of your current shoes, or feel tight across the width?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}