import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, PointElement, Tooltip as ChartTooltip, Legend } from 'chart.js';

ChartJS.register(LinearScale, PointElement, ChartTooltip, Legend);

export default function ShoeSimilarityChart({ similarShoes, similarShoesType }) {
    const scatterData = {
        datasets: similarShoes?.map((shoe) => ({
            label: shoe.shoe.model,
            data: [{
                x: shoe.shoe[similarShoesType], // Ensure this is a numeric value
                y: (shoe.score / shoe.maxScore) * 100
            }],
            backgroundColor: 'rgba(75, 192, 192, 1)',
        })) || [],
    };

    const scatterOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: similarShoesType,
                },
                position: 'bottom',
            },
            y: {
                title: {
                    display: true,
                    text: 'Recommended Score (%)',
                },
                min: 0,
                max: 100,
                position: 'left',
            },
            x2: {
                type: 'linear',
                position: 'top',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                title: {
                    display: true,
                    text: 'Top Axis',
                },
            },
            y2: {
                type: 'linear',
                position: 'right',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                title: {
                    display: true,
                    text: 'Right Axis',
                },
            },
        },
    };

    return (
        <div>
            <h1>Shoe Similarity Chart</h1>
            <div className="scatter-plot-container">
                <Scatter data={scatterData} options={scatterOptions} />
            </div>
        </div>
    );
}