import Tooltip from "@mui/material/Tooltip"
import FormBodyIntro from "./FormBodyIntro"
import FormBodySelection from "./FormBodySelection"
import FormBodySubmit from "./FormBodySubmit"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './index.css'
import { useState } from 'react'
import Switch from '@mui/material/Switch';
import { useAppContext } from '../../../utils/ContextProvider.jsx/index.jsx';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function FormBody({ heading, subHeading, formBodyType, selectionOptions, isAutoComplete, completeTime, handleClick, toolTip = null, toggleAlt = false }) {

    var { userLocation } = useAppContext()
    
    // Use the users location to determine the default unit system
    const [toggle, setToggle] = useState(userLocation.country === "US" ? true : false)

    return (
        <div className="container pt-5 main-form-body d-flex flex-column">
            <div className="row">
                <div className="col-auto text-center text-sm-start">
                    <h2>{heading} {toolTip ? <span className="text-muted"><Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={toolTip}>
                        <InfoOutlinedIcon/>
                    </Tooltip></span> : null}</h2>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col my-auto text-center text-sm-start">
                    <p>{subHeading}</p>
                </div>
            </div>
             {/* Render a switch to toggle between metric and imperial if applicable */}
             {toggleAlt && userLocation.country === "UK" ? <div className="row">
                <div className="col-auto my-auto text-center text-sm-start">
                    <ButtonGroup size="small" aria-label="metric imperial button group">
                    <Button 
                            variant="contained"
                            sx={{
                                backgroundColor: !toggle ? 'grey' : 'white',
                                color: !toggle ? 'white' : 'grey',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: !toggle ? 'grey' : 'white',
                                }
                            }}
                            onClick={() => setToggle(false)}
                        >
                            Metric
                        </Button>
                        <Button 
                            variant="contained"
                            sx={{
                                backgroundColor: toggle ? 'grey' : 'white',
                                color: toggle ? 'white' : 'grey',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: toggle ? 'grey' : 'white',
                                }
                            }}
                            onClick={() => setToggle(true)}
                        >
                            Imperial
                        </Button>

                    </ButtonGroup>
                </div>
            </div> : null}
            {formBodyType === "Intro" ? <FormBodyIntro completeTime={completeTime} /> : formBodyType === "Selection" ? <FormBodySelection selectionOptions={selectionOptions} isAutoComplete={isAutoComplete} handleClick={handleClick} toggleAlt={toggle} /> : <FormBodySubmit />}
                       

        </div>
    )
}