import { useState, useEffect, Suspense, Children, useRef } from "react"
import { ScrollRestoration, redirect, useParams, useOutletContext } from "react-router-dom";
import axios from "axios"
import { useAppContext } from '../../../utils/ContextProvider.jsx'
import Loader from "../../../components/Loader/index.jsx";
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx";
import HintCard from "../../../components/Dashboard/Cards/HintCard/index.jsx";
import ShoeCard from "../../../components/Dashboard/Cards/ShoeCard/index.jsx";
import { Button, Divider, Icon, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SortButton from "../../../components/Dashboard/Buttons/SortButton/index.jsx";
import FilterPanel from "../../../components/Dashboard/Panels/FilterPanel/index.jsx";
import CircularProgress from '@mui/material/CircularProgress';
import SearchPanel from "../../../components/Dashboard/Panels/SearchPanel/index.jsx";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import RecommendationReasoningModal from "../../../components/Dashboard/Modal/RecommendationReasoningModal/index.jsx";
import RecommendationReasoningPanel from "../../../components/Dashboard/Panels/RecommendationReasoningPanel/index.jsx"
import { sortingMethodsRecommendation } from "../../../utils/SortingMethods/index.js";
import Alert from "@mui/material/Alert";
import InfoOutlined from "@mui/icons-material/InfoOutlined.js";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom"; 
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { createEvent } from "../../../utils/CreateEvent/index.js";
import { shoeTypeDescriptions } from "../../../utils/ShoeTypeDescriptions/index.js";
import { shoeTraitMapping } from "../../../utils/ValueMapping/index.js";


export default function RecommendationPage({ user = null, userShoes = null }) {

    var [ user, userShoes ] = useOutletContext()

    var navigate = useNavigate()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, trackEvent, requestUrl, isProcessing, setProcessing, upgradeModalOpen, setUpgradeModalOpen } = useAppContext()
    
    const [recommendation, setRecommendation] = useState({})
    const [shoes, setShoes] = useState([])
    
    var [firstShoe, setFirstShoe] = useState()

    const [filters, setFilters] = useState({
        surface: 'road',
        width: '',
        stability: '',
        heelToeDrop: { low: 0, high: 16 }, // Assuming max heel-toe drop is 16mm
        cushion: '',
        weight: { low: 0, high: 500 },
        grip: '',
        rockPlate: '',
        carbonPlate: '',
        shoeShape: '',
        rocker: '',
        cushionType: '',
        brands: [],
        price: 550,
        matchScore: 0,
        offers: false,
        colors: [],
        currentShoes: true
    });

    const [filtersActive, setFiltersActive] = useState(false)

    const [reviews, setReviews] = useState([])

    const [sort, setSort] = useState('suitability')
    const [search, setSearch] = useState()
    const [page, setPage] = useState(1)
    const [shoeCount, setShoeCount] = useState();
    const [open, setOpen] = useState()
    const parentRef = useRef(null)
    const [partnerDeals, setPartnerDeals] = useState()

    const [selectedShoes, setSelectedShoes] = useState([]);

    const [alert, setAlert] = useState(false)

    const { recommendationId } = useParams()

    var breadcrumb = [
        {
            title: 'recommendations',
            href: '/dashboard/recommendations'
        },
        {
            title: `${recommendation.preferences? recommendation.preferences.shoeUsage : null}, ${recommendation.preferences? recommendation.preferences.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        }
    
    ]
    

    useEffect(() => {
        setLoading(true);
    
        const recommendationUrl = `${requestUrl}/api/recommendations/${recommendationId}`;
        const partnerDealsUrl = `${requestUrl}/api/partnerDeals`;
        const reviewsUrl = `${requestUrl}/api/shoeReviews`;
        const recommendationRequest = axios({
            url: recommendationUrl,
            method: "GET",
            withCredentials: true
        });
    
        const partnerDealsRequest = axios({
            url: partnerDealsUrl,
            method: "GET",
            withCredentials: true
        });

        const reviewsRequest = axios({
            url: reviewsUrl,
            method: "GET",
            withCredentials: true
        });
    
        Promise.all([recommendationRequest, partnerDealsRequest, reviewsRequest])
            .then(([recommendationRes, partnerDealsRes, reviewsRes]) => {
                const recommendation = recommendationRes.data.recommendation || {};
    
                setRecommendation(recommendation);
                setReviews(reviewsRes.data.reviews)
                console.log(reviewsRes.data.reviews)
                setFilters(filters => ({
                    ...filters,
                    brands: recommendation.preferences?.shoeBrands || [],
                    colors: recommendation.preferences?.shoeColors || [],
                    price: recommendation.preferences?.shoeBudget?.highPrice || 550
                }));
                const filteredShoes = recommendation.shoes?.filter(shoe => 
                    (recommendation.preferences?.shoeBrands?.length > 0 
                        ? recommendation.preferences.shoeBrands.includes(shoe.shoe.brand.name) 
                        : true) &&
                    parseInt(shoe.shoe.msrp) <= (recommendation.preferences?.shoeBudget?.highPrice || 0)
                ) || [];
    
                setShoes(filteredShoes);
                setFirstShoe(filteredShoes.sort(sortingMethodsRecommendation[sort].method)[0])
                setSearch(null);
                setPartnerDeals(partnerDealsRes.data);
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }));
                redirect('/dashboard');
            })
            .finally(() => {
                setLoading(false);
            });

    }, [recommendationId, requestUrl, redirect]);
    
    const handlePageChange = (evt, value) => {
        setPage(value)
        const element = document.getElementById("sortButton");
        trackEvent('Change page')
        setTimeout(function() {
           element.scrollIntoView()
        }, 1)
    }

    const clearFilters = (evt) => {
        setFilters({
            width: '',
            stability: '',
            heelToeDrop: { low: 0, high: 16 }, // Assuming max heel-toe drop is 16mm
            cushion: '',
            weight: { low: 0, high: 500 },
            grip: '',
            rockPlate: '',
            carbonPlate: '',
            shoeShape: '',
            rocker: '',
            cushionType: '',
            brands: [],
            colors: [],
            price: 550,
            matchScore: 0,
            offers: false,
            currentShoes: false
          })
        setShoes(recommendation.shoes)
        setFirstShoe(recommendation.shoes.sort(sortingMethodsRecommendation[sort].method)[0])
        setFiltersActive(false)
        setPage(1)

    }

    const handleSearch = (evt) => { 
        if (search && search !== '') {
            clearFilters()
            var filteredShoes = recommendation.shoes?.filter((shoe) => 
            (shoe.shoe.brand.name + shoe.shoe.model).replace(/\s/g, '').replace('-','').toLowerCase().includes(search.replace(/\s/g, '').replace('-','').toLowerCase())
            )
            setShoes(filteredShoes)
            trackEvent('Search shoes', {searchValue: search})
            createEvent({   
                action: 'search_shoes',
                category: 'interaction',
                data: {
                    searchValue: search,
                    page: 'recommendations'
                }
            })
        }
        
    }

    const clearSearch = (evt) => {
        setSearch('')
        clearFilters()
        setShoes(recommendation.shoes)
    }



    const handleChange = (evt) => {
        setSearch(evt.target.value)
    }


    const handleComparisonPageClick = () => {
        var url = `/dashboard/recommendations/${recommendationId}/compare`
        navigate(url, {state: {shoe: [selectedShoes], rec: recommendation}})

    }

      // Function to handle adding/removing shoe when checkbox is clicked
      const toggleShoe = (evt, shoe) => {
        setSelectedShoes((prevSelectedShoes) => {
          if (prevSelectedShoes.some(selectedShoe => selectedShoe.shoe._id === shoe.shoe._id)) {
            // Remove shoe if it exists in the array
            return prevSelectedShoes.filter(selectedShoe => selectedShoe.shoe._id !== shoe.shoe._id);
          } else {
            // Add shoe if it doesn't exist in the array
            return [...prevSelectedShoes, shoe];
          }
        });
      }


    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '100vh'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {

    return (
            <div>
            <DashboardHeader
            heading={`${recommendation?.preferences ? ((recommendation?.preferences?.shoeUsage).charAt(0).toUpperCase() + recommendation?.preferences?.shoeUsage.slice(1)).replace('_', ' ') : null}, ${recommendation?.preferences ? recommendation?.preferences?.shoeSurface : null} running shoes ${(recommendation?.preferences?.speedType ? ` (${recommendation?.preferences?.speedType == 'max' ? 'long distance' : recommendation?.preferences.speedType == 'med' ? 'versatile' : 'short distance'})` : (recommendation?.preferences?.shoeUsage === 'everyday' && recommendation?.preferences?.everydayShoeType ? ` ${recommendation?.preferences?.everydayShoeType == 'responsive' ? '(responsive)' : recommendation?.preferences?.everydayShoeType == 'balanced' ? '(versatile)' : recommendation?.preferences?.everydayShoeType == 'comfort' ? '(comfort)' : ""}` : ""))}`}
            subHeading={shoeTypeDescriptions[recommendation?.preferences?.shoeUsage]?.[recommendation?.preferences?.shoeSurface] || 'Based on your running profile and intended use, this contains a ranked list of running shoes that match your needs and preferences.'}
            breadcrumb={breadcrumb}
            />

            <div className="container-fluid dashboard-body pt-4 pb-5" id="recommendationsArea">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-xl-11 mx-auto">
                            <div className="row">
                                {/* List */}
                                <div className="col-12 col-xl-9 shoe-list-column pe-lg-3 pe-xl-5 mx-auto mb-2 order-2 order-xl-1 mt-3 mt-xl-0">
            
                                    {/* Shoe results */}
                                    { firstShoe?.score / firstShoe?.maxScore < 1.0 && filtersActive ?
                                        <div className="row align-items-center mt-3 mt-lg-0 mb-3">
                                            <div className="col">
                                                <Alert color="warning" icon={<InfoOutlined/>} onClick={clearFilters}>
                                                    There are more suitable shoe options outside of your brand and price preferences. <a style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear your current filters</a> to view these shoes.
                                                </Alert>
                                            </div>
                                        </div>
                                    : null}

                                    <div className="row mt-3 mt-lg-0 ps-lg-2 align-items-center justify-content-center">
                                        {/* Search and results number */}
                                        <div className="col-10 mx-auto col-md mx-md-0 my-1">
                                            <div className="row align-items-center">
                                                <div className="col-11 col-lg-7 px-0 mx-auto mx-md-0 my-2 my-md-0 search-input">
                                                    <SearchPanel search={search} handleChange={handleChange} handleSearch={handleSearch} />
                                                </div>
                                                {search ?
                                                <div className="col-auto">
                                                    <a onClick={clearSearch}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear</p></a>
                                                </div>
                                                : null}
                                            </div>
                                        </div>
                                        {/* Sorting and compare shoes option */}
                                        <div className="col-auto mx-auto my-1" id="sortButton">
                                            <div className="row align-items-center justify-content-center justify-content-md-end">
                                                <div className="col-auto">
                                                    <Button
                                                        key={`${user?.planType}CompareButton`}
                                                        variant="contained" 
                                                        endIcon={<CompareArrowsIcon />}
                                                        onClick={handleComparisonPageClick}
                                                        > 
                                                        Compare
                                                    </Button>
                                                </div>
                                                <div className="col-auto">
                                                    <SortButton sort={sort} setSort={setSort} />
                                                </div>
                                            </div>
                                        </div>                             
                                    </div>

                                    {(recommendation?.shoes && shoes?.length === 0)  ?
                                        <div className="row my-4">
                                            <div className="col-auto mx-auto">
                                            <p>No results. Try clearing your <a style={{textDecoration: 'underline' ,cursor: 'pointer'}} onClick={clearFilters}>preferences</a></p> 
                                            </div>
                                        </div>
                                    
                                    : null}
        
                                    
                                    

                                    <div className="row mt-3" ref={parentRef} id="shoeList" key={`shoeList${shoes?.length}`}>
                                        {(() => {
                                            const filteredAndSortedShoes = shoes
                                                ?.filter((shoe) =>
                                                    shoe.shoe.discontinued === 'FALSE' &&
                                                    shoe.shoe.latestModel === "TRUE" &&
                                                    (filters.currentShoes
                                                        ? (userShoes?.filter((userShoe) => userShoe.shoeId._id === shoe.shoe._id)[0]?.shoeReviewId?.rating > 3 ||
                                                            !userShoes?.filter((userShoe) => userShoe.shoeId._id === shoe.shoe._id)[0])
                                                        : shoe)
                                                )
                                                .sort(sortingMethodsRecommendation[sort].method);

                                            // Track the shoes being viewed
                                            createEvent({
                                                action: 'view_shoes_list',
                                                category: 'interaction',
                                                data: {
                                                    shoeIds: filteredAndSortedShoes.slice((page - 1) * 6, page * 6).map(shoe => shoe.shoe._id),
                                                    page: page,
                                                    route: 'recommendations',
                                                    search: search,
                                                    sortMethod: sort,
                                                    filters: filters,
                                                    recommendationId: recommendationId
                                                }
                                            });

                                            return filteredAndSortedShoes
                                                .slice((page - 1) * 6, page * 6)
                                                .map((shoe, index) => {
                                                    const currentShoe = userShoes?.some(userShoe =>
                                                        userShoe.shoeId._id.includes(shoe.shoe._id)
                                                    );
                                                    return (
                                                        <div key={`${shoe.shoe._id}`} className="col-12 col-md-6 my-2">
                                                            <ShoeCard
                                                                key={`${shoe.shoe._id}`}
                                                                shoe={shoe}
                                                                recommendationId={recommendationId}
                                                                requirements={recommendation.requirements}
                                                                preferences={recommendation.preferences}
                                                                currentShoe={currentShoe}
                                                                partnerDeals={partnerDeals}
                                                                toggleShoe={toggleShoe}
                                                                selectedShoes={selectedShoes}
                                                                reviews={reviews}
                                                            />
                                                        </div>
                                                    );
                                                });
                                        })()}
                                    </div>



                                    {Math.ceil(shoes?.length/6) > 0 ?
                                    <div className="row mt-4">
                                        <div className="col-auto mx-auto">
                                            <Stack spacing={2}>
                                                <Pagination 
                                                    key={`page${page}Shoes`}
                                                    count={Math.ceil(shoes?.length/6)} 
                                                    color="primary"
                                                    boundaryCount={1}
                                                    defaultPage={page}
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                    : null}
                                    <div className="row mt-3">
                                        <div className="col-auto mx-auto">
                                            <p className="my-0">Showing {recommendation.shoes? shoes.length: null} options</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Filters */}
                                <div className="col-12 mx-auto mx-lg-0 col-xl-3 order-1 order-xl-2">
                                    
                                <FilterPanel shoes={shoes} setShoes={setShoes} filters={filters} setFilters={setFilters} recommendation={recommendation} partnerDeals={partnerDeals} setPage={setPage} filtersActive={filtersActive} setFiltersActive={setFiltersActive} firstShoe={firstShoe} setFirstShoe={setFirstShoe}/>

                                <div className="row my-2">
                                    <div className="col-12 mx-auto px-3">
                                        <RecommendationReasoningPanel requirements={recommendation?.requirements} setOpenState={setOpen} />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12 show-shoe-tile">
                                        <div className="row">
                                            <div className="col  px-3 py-3">
                                            <div className="row justify-content-between">
                                            <div className="col-auto">
                                                <h3>Recommendation</h3>

                                            </div>
                                            <div className="col-auto">
                                                <p><span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/shoe-finder/preferences')}>Update</span></p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <ul>
                                                    {recommendation.preferences && Object.entries(recommendation.preferences).map(([key, value]) => (
                                                        <li key={key}>
                                                            <strong>{(shoeTraitMapping[key] || key).charAt(0).toUpperCase() + (shoeTraitMapping[key] || key).slice(1)}:</strong> {JSON.stringify(value).replace(/[{}"]/g, '').replace('lowPrice', 'low').replace('highPrice', 'high').replace('[]', 'none').replace(",", ", ").replace("_", " ")}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                                
                        </div>
                    </div>
                    { selectedShoes.length > 0 ? 
                    <div className="row">
                        <Fab style={{margin: 0, top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed'}} variant="extended" color="primary" onClick={(evt) => handleComparisonPageClick(evt)}>
                            <CompareArrowsIcon sx={{ mr: 1 }} />
                            Compare
                        </Fab>
                        
                    </div>
                    : null }

                        </div>
                    </div>
                    
                    
                   
                </div>
                <RecommendationReasoningModal openState={open} setOpenState={setOpen} recommendation={recommendation} maxScore={shoes[0]?.maxScore} />
            </div>
        </div>
        
    )
}
}