import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import './index.css';
import '@iframe-resizer/child'
import HttpsRedirect from 'react-https-redirect';

// Context
import { AppProvider } from './utils/ContextProvider.jsx';
import { IconContext } from '@phosphor-icons/react';


// Layouts
import FormPage from './views/Form';
import AuthPage from './views/Auth';
import DashboardPage from './views/Dashboard';

// Website pages
import HomePage from './views/Website/HomePage';
import PrivacyPolicyPage from './views/Website/PrivacyPolicy';
import AboutPage from './views/Website/About';
import HowItWorksPage from './views/Website/HowItWorks';


// Auth pages
import LoginPage from './views/Auth/Login';
import ForgotPassword from './views/Auth/ForgotPasword';
import ResetPasswordPage from './views/Auth/ResetPassword';

// Shoe finder - running profile form pages
import FormIntro from './views/Form/FormIntro';
import RunningProfileIntroPage from './views/Form/RunningProfile/RunningProfileIntro';
import ExperiencePage from './views/Form/RunningProfile/Experience';
import WeeklyDistancePage from './views/Form/RunningProfile/WeeklyDistance';
import AveragePacePage from './views/Form/RunningProfile/AveragePace';
import GoalPage from './views/Form/RunningProfile/Goal';
import CurrentShoePage from './views/Form/RunningProfile/CurrentShoe'
import PastShoePage from './views/Form/RunningProfile/PastShoe';
import InjuryYesNoPage from './views/Form/RunningProfile/Injuries/InjuryYesNo';
import InjuryRepeatYesNoPage from './views/Form/RunningProfile/Injuries/InjuryRepeat';
import InjuryLocationPage from './views/Form/RunningProfile/Injuries/InjuryLocation';
import InjuryTypePage from './views/Form/RunningProfile/Injuries/InjuryType';
import TightnessYesNoPage from './views/Form/RunningProfile/Tightness/TightnessYesNo';
import TightnessLocationPage from './views/Form/RunningProfile/Tightness/TightnessLocation';
import FootStrikeYesNoPage from './views/Form/RunningProfile/FootStrike/FootStrikeYesNo';
import FootStrikeKnownPage from './views/Form/RunningProfile/FootStrike/FootStrikeKnown';
import FootStrikeUnknownPage from './views/Form/RunningProfile/FootStrike/FootStrikeUnkown';
import PronationYesNoPage from './views/Form/RunningProfile/Pronation/PronationYesNo';
import PronationKnownPage from './views/Form/RunningProfile/Pronation/PronationKnown';
import PronationUnknownPage from './views/Form/RunningProfile/Pronation/PronationUnknown';
import ArchHeightYesNoPage from './views/Form/RunningProfile/Arch/ArchYesNo';
import ArchKnownPage from './views/Form/RunningProfile/Arch/ArchKnown';
import ArchUnknownPage from './views/Form/RunningProfile/Arch/ArchUnkown';
import FootWidthYesNoPage from './views/Form/RunningProfile/FootWidth/FooWidthYesNo';
import FootWidthKnownPage from './views/Form/RunningProfile/FootWidth/FootWidthKnown';
import FootWidthUnknownPage from './views/Form/RunningProfile/FootWidth/FootWidthUnknown';
import FootWidthSpillOverPage from './views/Form/RunningProfile/FootWidth/FootWidthUnknown/FootWidthSpillOver';
import AgePage from './views/Form/RunningProfile/Age';
import WeightPage from './views/Form/RunningProfile/Weight';
import HeightPage from './views/Form/RunningProfile/Height';
import GenderPage from './views/Form/RunningProfile/Gender';

// Shoe finder - preferences pages
import PreferencesIntro from './views/Form/Preferences/PreferencesIntro';
import SurfacePage from './views/Form/Preferences/Surface';
import UsagePage from './views/Form/Preferences/Usage';
import BrandPage from './views/Form/Preferences/Brand';
import PricePage from './views/Form/Preferences/Price';
import SpeedTypePage from './views/Form/Preferences/Usage/SpeedType';
import RockPlatePage from './views/Form/Preferences/Surface/RockPlate';
import ConditionsPage from './views/Form/Preferences/Surface/ConditionsPage';
import EverydayTypePage from './views/Form/Preferences/Usage/EverydayShoeType';
import ColorOptionsPage from './views/Form/Preferences/Color';


// Shoe finder - submit/result page
import SubmitPage from './views/Form/Submit';
import ResultPage from './views/Form/Result';


// Dashboad pages
import DashboardHomePage from './views/Dashboard/Home';
import RecommendationPage from './views/Dashboard/Recommendation';
import AllRecommendationsPage from './views/Dashboard/allRecommendations'
import ShoePage from './views/Dashboard/Shoe';
import ProfilePage from './views/Dashboard/Profile';
import CompareShoePage from './views/Dashboard/CompareShoes';
import ShoeLockerPage from './views/Dashboard/ShoeLocker';
import BrowseShoesPage from './views/Dashboard/BrowseShoes';

// Maintenance & error pages
import ErrorPage from './views/Error';
import TrailSurfacePage from './views/Form/Preferences/Surface/TrailSurface';
import WebsitePage from './views/Website';
import Redirect from './components/Redirect';
import MaintenancePage from './views/Maintenance';



// Admin pages
import AdminLayout from './views/Admin';
import AllWebsiteReviewsPage from './views/Admin/WebsiteReviews/AllWebsiteReviews';
import WebsiteReviewPage from './views/Admin/WebsiteReviews/WebsiteReview';
import ShoeSizePage from './views/Form/RunningProfile/shoeSize';
import AllPartnerMappingsPage from './views/Admin/Partners/AllPartnerMappings';
import MapShoePage from './views/Admin/Partners/MapShoe';

const maintenanceRouter = createBrowserRouter([
  {
    path: '/maintenance',
    element: <MaintenancePage />
  },
  {
    path: '*',
    element: <Redirect url={'/maintenance'} />
  }
]);


const liveRouter = createBrowserRouter([
  // Marketing website routes
  {
    path: '',
    element: <WebsitePage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'how-it-works',
        element: <HowItWorksPage />,
      },
      {
        path: "auth",
        element: <AuthPage />,
        children: [
          // {
          //   path: 'register',
          //   element: <RegisterPage />
          // },
          {
            path: 'login',
            element: <LoginPage />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
            
          }
        ]
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />
      },
      {
        path: "about",
        element: <AboutPage />
      }
      // {
      //   path: "browse-shoes",
      //   element: <BrowseShoesPage />
      // },

    ]
  },
  // Dashboard routes
  {
    path: "/dashboard",
    errorElement: <ErrorPage />,
    element: <DashboardPage />,
    children: [
      {
        path: '',
        element: <DashboardHomePage 
        
                />
      },
      {
        path: 'recommendations',
        children: [
          {
            path: '',
            element: <AllRecommendationsPage />
          },
          {
            path: ':recommendationId',
            element: <RecommendationPage 
                    />
          },
          {
            path: ':recommendationId/compare',
            element: <CompareShoePage
                    />
          },
          {
            path: ':recommendationId/shoes/:shoeName',
            element: <ShoePage />
          },
          // {
          //   path: ':recommendationId/shoes/:shoeName/submit-review',
          //   element: <SubmitShoeReviewPage />
          // }
        ]
      },
      {
        path: 'profile',
        element: <ProfilePage />
      },
      {
        path: 'shoe-locker',
        element: <ShoeLockerPage />
      },
      {
        path: 'browse',
        children: [
          {
            path: '',
            element: <BrowseShoesPage notRec={true} />
          },
          {
            path: ':recommendationId/compare',
            element: <CompareShoePage notRec={true}
                    />
          },
          {
            path: ':recommendationId/shoes/:shoeName',
            element: <ShoePage notRec={true} />
          }
          
        ]
        
      }
    ]
  },
  // Shoe finder routes
  {
    path: "/shoe-finder",
    errorElement: <ErrorPage />,
    element: <FormPage />,
    children: [ 
      {
        path: 'intro',
        element: <FormIntro 
                    progress={'0'}
                    isAutoComplete={false}
                    section={null}
                  />
      },
      {
        path: 'running-profile',
        children: [
          {
            path: '',
             element: <RunningProfileIntroPage 
                    progress={'2'}
                    isAutoComplete={false}
                    section={'Running profile'}
                />,
          },
          {
            path: 'experience',
            element: <ExperiencePage
                        progress={'5'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'weekly-distance',
            element: <WeeklyDistancePage
                        progress={'8'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'pace',
            element: <AveragePacePage
                        progress={'11'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'goal',
            element: <GoalPage
                        progress={'14'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'current-shoe',
            element: <CurrentShoePage
                        progress={'18'}
                        isAutoComplete={false}
                        section={'Running profile'}
                    />
          },
          {
            path: 'past-shoe',
            element: <PastShoePage
                        progress={'21'}
                        isAutoComplete={false}
                        section={'Running profile'}
                    />
          },
          {
            path: 'injury',
            element: <InjuryYesNoPage
                        progress={'23'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'injury-repeat',
            element: <InjuryRepeatYesNoPage
                        progress={'31'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'injury-type',
            element: <InjuryLocationPage
                        progress={'25'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'injury-type/:injuryLocation',
            element: <InjuryTypePage
                        progress={'31'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'tightness',
            element: <TightnessYesNoPage
                        progress={'36'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'tightness-type',
            element: <TightnessLocationPage
                        progress={'39'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-strike',
            element: <FootStrikeYesNoPage
                        progress={'42'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-strike/known',
            element: <FootStrikeKnownPage
                        progress={'47'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-strike/unknown',
            element: <FootStrikeUnknownPage
                        progress={'47'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'pronation',
            element: <PronationYesNoPage
                        progress={'53'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'pronation/known',
            element: <PronationKnownPage
                        progress={'57'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'pronation/unknown',
            element: <PronationUnknownPage
                        progress={'57'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'arch',
            element: <ArchHeightYesNoPage
                        progress={'61'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'arch/known',
            element: <ArchKnownPage
                        progress={'61'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'arch/unknown',
            element: <ArchUnknownPage
                        progress={'61'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-width',
            element: <FootWidthYesNoPage
                        progress={'64'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-width/known',
            element: <FootWidthKnownPage
                        progress={'67'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-width/unknown',
            element: <FootWidthUnknownPage
                        progress={'67'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'foot-width/spill-over',
            element: <FootWidthSpillOverPage
                        progress={'70'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'age',
            element: <AgePage
                        progress={'72'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'height',
            element: <HeightPage
                        progress={'76'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'weight',
            element: <WeightPage
                        progress={'81'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          },
          {
            path: 'shoe-size',
            element: <ShoeSizePage
                        progress={'83'}
                        isAutoComplete={false}
                        section={'Running profile'}
                    />
          },
          {
            path: 'gender',
            element: <GenderPage
                        progress={'86'}
                        isAutoComplete={true}
                        section={'Running profile'}
                    />
          }
        ]
      },
      {
        path: 'preferences',
        children: [
          {
            path: '',
            element: <PreferencesIntro
                        progress={'88'}
                        isAutoComplete={false}
                        section={'Shoe preferences'}
             />
          },
          {
            path: 'surface',
            element: <SurfacePage
                        progress={'89'}
                        isAutoComplete={true}
                        section={'Shoe preferences'}
            />
          },
          {
            path: 'surface',
            element: <SurfacePage
                        progress={'89'}
                        isAutoComplete={true}
                        section={'Shoe preferences'}
            />
          },
          {
            path: 'surface/trail_type',
            element: <TrailSurfacePage 
                    progress={'90'}
                    isAutoComplete={true}
                    section={'Shoe preferences'}
            />
          },
          {
            path: 'surface/rock_plate',
            element: <RockPlatePage
                    progress={'90'}
                    isAutoComplete={true}
                    section={'Shoe preferences'}
            />
          },
          {
            path: 'surface/conditions',
            element: <ConditionsPage 
                    progress={'90'}
                    isAutoComplete={true}
                    section={'Shoe preferences'}
            />
          },
          {
            path: 'usage',
            element: <UsagePage
                        progress={'89'}
                        isAutoComplete={true}
                        section={'Shoe preferences'}
            />
          },
          {
            path: 'feel',
            element: <EverydayTypePage
                        progress={'90'}
                        isAutoComplete={true}
                        section={'Shoe preferences'}
            />
          },
          {
            path: 'speed_type',
            element: <SpeedTypePage
                        progress={'90'}
                        isAutoComplete={true}
                        section={'Shoe preferences'}
            />
          },
          {
            path: 'brand',
            element: <BrandPage
                        progress={'94'}
                        isAutoComplete={false}
                        section={'Shoe preferences'}
            />
          },
          // {
          //   path: 'color',
          //   element: <ColorOptionsPage
          //               progress={'96'}
          //               isAutoComplete={false}
          //               section={'Shoe preferences'}
          //   />
          // },
          {
            path: 'price',
            element: <PricePage
                        progress={'97'}
                        isAutoComplete={false}
                        section={'Shoe preferences'}
            />
          }
        ]
      },
      {
        path: 'submit',
        element: <SubmitPage 
                    progress={'99'}
                    isAutoComplete={true}
                    section={'Submit'}
        
        />
      },
      {
        path: 'result',
        element: <ResultPage 
                    progress={'100'}
                    isAutoComplete={true}
                    section={'Result'}
        
        />
      },
      {
        path: 'habits-and-goals',
        element: <Redirect url={'/shoe-finder/intro'}/>
      }
    ]
  },
  {
    path: '/admin',
    errorElement: <ErrorPage />,
    element: <AdminLayout />,
    children: [
      {
        path: '',
        element: <div style={{height: '100vh'}}></div>
      },
      {
        path: 'reviews', 
        element: <AllWebsiteReviewsPage />
      },
      {
        path: 'reviews/:websiteReviewId',
        element: <WebsiteReviewPage />
      },
      {
        path: 'partners/mappings',
        element: <AllPartnerMappingsPage />
      }, 
      {
        path: 'partners/mappings/:mappingId',
        element: <MapShoePage />
      }
    ]
  }
]);



function App() {

  return (
    <HttpsRedirect>
      <AppProvider>
      <IconContext.Provider value={{ size: 64 }}>
        <RouterProvider 
          router={liveRouter} 
          fallbackElement={<p>Loading..</p>} 
        />
      </IconContext.Provider>
      </AppProvider>
    </HttpsRedirect>
  );
}

export default App;


