var shoeTypeDescriptions = {
    everyday: {
        road: "Everyday road shoes are designed to be a do it all, durable shoe for those runners who only want one shoe. They come in different flavours based on whether you care most about running faster (responsive), comfort (comfort) or just want a true all-rounder (versatile).",
        trail: "Everyday trail shoes are your dependable, versatile option for runners who want one shoe. They are comfortable, durable, and adaptable for various surfaces, with variations based on the terrain.",
        hybrid: "Everyday hybrid shoes are your go-to for road-to-trail runs. Best for light trails, they feature an outsole design that handles pavement better than true trail shoes."
    }, 
    long_distance: {
        road: "Long distance road shoes are built for marathons or ultras, offering maximal cushioning for comfort over long periods, but are less responsive and heavier.",
        trail: "Long distance trail shoes are designed for marathons or ultras, with high cushioning for comfort over long periods, sacrificing stability on technical trails.",
        hybrid: "Long distance hybrid shoes are for marathons or ultras, providing high cushioning for comfort over long periods, but less stability on technical trails."
    },
    speed: {
        road: "Speed shoes are for runners prioritizing speed over comfort. They are responsive, lightweight, and promote leg turnover, with variations for different race distances.",
        trail: "Speed trail shoes are for runners prioritizing speed over comfort. They are responsive, lightweight, and promote leg turnover, with variations for different race distances.",
        hybrid: "Speed hybrid shoes are for runners prioritizing speed over comfort. They are responsive, lightweight, and promote leg turnover, with variations for different race distances."
    }
}

export { shoeTypeDescriptions }