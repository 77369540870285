import DashboardHeader from "../../../components/Dashboard/Header"
import { useParams, useLocation, useNavigate, useOutletContext } from "react-router-dom"
import PropTypes from 'prop-types';
import { Divider } from "@mui/material"
import ChipComponent from "../../../components/Chip"
import Alert from "@mui/material/Alert";
import ReviewsList from "./Reviews";
import * as React from 'react';
import axios from "axios";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from "@mui/material/IconButton";
import Tooltip  from "@mui/material/Tooltip";
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import { useAppContext } from '../../../utils/ContextProvider.jsx'
import '../index.css'
import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AskAIPanel from "../../../components/Dashboard/Panels/AskAIPanel";
import SimilarShoesList from "./SimilarShoes";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ScrollRestoration } from "react-router-dom";
import Loader from "../../../components/Loader";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FeedbackSnackbar from "../../../components/Snackar/Feedback Snackbar";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import PreviousModelSection from "./PreviousModels";
import AddShoeModal from "../../../components/Modal/AddShoeModal";
import * as Sentry from "@sentry/react";
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PartnerDealsSection from "./PartnerDeals";
import ShoeAnalysisSection from "./ShoeAnalysis";
import { createEvent } from "../../../utils/CreateEvent/index.js";
import { shoeTraitMapping, shoeValueMapping } from "../../../utils/ValueMapping/index.js";
import { GraduationCap, UserCircle } from '@phosphor-icons/react'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
      </div>
    );
  }

  

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function ShoePage ( { notRec = null }) {

    var [ user, userShoes ] = useOutletContext()

    const { isLoading, setIsAlert, setLoading, requestUrl, trackEvent, upgradeModalOpen, setUpgradeModalOpen, sessionUser } = useAppContext()

    const { recommendationId, shoeName } = useParams()

    const navigate = useNavigate()

    var { shoe , preferences, requirements, shoeColorway, tabIndex, openAccordian} = useLocation().state || {}

    var  [ shoeColorway, setShoeColorway ] = useState(shoeColorway || shoe?.shoe.colorways[0])

    const [ websiteReviews, setWebsiteReviews ] = useState()

    var [ userShoe, setUserShoe ] = useState()

    const [ runnerReviews, setRunnerReviews ] = useState()

    const [open, setOpen] = useState(false);

    const [value, setValue] = useState(tabIndex? tabIndex : 0);

    const [addShoeModalOpen, setAddShoeModalOpen] = useState()

    var [averageReviewScore, setAverageReviewScore] = useState(0)

    var [ partnerDeals, setPartnerDeals ] = useState([])

    var [ visibleDeals, setVisibleDeals ] = useState([])

    var [reviewType, setReviewType] = useState('amateur')


    useEffect(() => {
        setLoading(true);
        if (!shoe) {
            const recommendationUrl = `${requestUrl}/api/recommendations/${recommendationId}`;
            axios({
                url: recommendationUrl,
                withCredentials: true
            })
            .then((res) => {
                const preferences = res.data.recommendation.preferences;
                const requirements = res.data.recommendation.requirements;
                const shoeNameArray = shoeName.split('_');
                const shoeList = res.data.recommendation.shoes.filter(
                    (shoe) => shoe.shoe.brand.slug === shoeNameArray[0] && 
                              shoe.shoe.model === shoeNameArray[1] && 
                              shoe.shoe.version === shoeNameArray[2]
                );
                const selectedShoe = shoeList[0];
                if (notRec) {
                    navigate(`/dashboard/browse/${recommendationId}/shoes/${shoeName}`, { 
                        state: { shoe: selectedShoe, preferences, requirements, shoeColorway: shoeColorway },
                        replace: true // Use replace instead of push to avoid adding a new entry to the history
                    });
                } else {
                    navigate(`/dashboard/recommendations/${recommendationId}/shoes/${shoeName}`, { 
                        state: { shoe: selectedShoe, preferences, requirements, shoeColorway: shoeColorway },
                        replace: true // Use replace instead of push to avoid adding a new entry to the history
                    });
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(isAlert => ({
                    ...isAlert,
                    error: errorMessage
                }));
                setLoading(false);
            });
        } else {
            const shoeReviewsUrl = `${requestUrl}/api/websiteReviews/shoe/${shoe?.shoe._id}`;
            const partnerDealsUrl = `${requestUrl}/api/partnerDeals/shoe/${shoe?.shoe._id}`;
            Promise.all([
                axios({ url: shoeReviewsUrl, withCredentials: true }),
                axios({ url: partnerDealsUrl, withCredentials: true })
            ])
            .then(([reviewsRes, dealsRes]) => {
                setWebsiteReviews(reviewsRes.data);

                const currentShoe = userShoes && userShoes.length > 0 
                    ? userShoes?.filter((userShoe) => userShoe?.shoeId?._id === shoe?.shoe._id) 
                    : null;
                setUserShoe(currentShoe ? currentShoe[0] : null);

                const colorways = shoe.shoe.shoeShape !== 'foot shaped'
                    ? shoe.shoe.colorways.filter(colorway => 
                        colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption))
                      ).sort()
                    : shoe.shoe.colorways;
                var colorway = colorways.length > 0 ? colorways[0] : shoe.shoe.colorways[0];
                createEvent({
                    action: 'view_shoe',
                    category: 'interaction',
                    data: {
                        shoeId: shoe.shoe._id,
                        recommendationId: recommendationId,
                        colorwayId: colorway._id,
                        route: !notRec ? 'recommendations' : 'browse'
                    }
                });
                setShoeColorway(colorway);
                setPartnerDeals(dealsRes.data);
                setVisibleDeals(dealsRes.data.filter((deal) => 
                    deal.shoeColorway.toString() == colorway._id.toString() && 
                    (deal.partner.countries.length === 0 || // Check if countries array is empty
                    (sessionUser.location ? deal.partner.countries.includes(sessionUser.location?.country) : true)) &&
                    (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal)
                ));
                setLoading(false);
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(isAlert => ({
                    ...isAlert,
                    error: errorMessage
                }));
                setLoading(false);
            });
        }
    }, [shoe, recommendationId, shoeName, notRec, requestUrl, requirements?.width?.traits, sessionUser?.location?.country]);

    
    const handleClickOpen = () => {
            trackEvent('View reviews breakdown', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})
            setOpen(true);
    };

    const [expanded, setExpanded] = useState(false);

    const handleAccordianClick = (panel) => (event, isExpanded) => {
        trackEvent('View shoe criteria reasoning', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})
        setExpanded(isExpanded ? panel : false);
    };
    
    const handleColorwayChange = (evt, colorway) => {
        setShoeColorway(colorway)
        createEvent({
            action: 'view_shoe_colorway',
            category: 'interaction',
            data: {
                shoeId: shoe.shoe._id,
                colorwayId: colorway._id,
                recommendationId: recommendationId,
                page: 'shoe'
            }
        })
        var deals = partnerDeals.filter((deal) => deal.shoeColorway.toString() == colorway._id.toString() && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal ))
        setVisibleDeals(deals)

    }

    const handleCopy = (evt, string) => {
        trackEvent('Copy shoe name', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})
        navigator.clipboard.writeText(string)
        setIsAlert(isAlert => {
            return {
                ...isAlert,
                success: 'Copied to clipboard'
            }
        })
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const sendToCompare = (evt, shoeList) => {
        var url = !notRec ? `/dashboard/recommendations/${recommendationId}/compare`: `/dashboard/browse/${recommendationId}/compare`
        navigate(url, {state: {shoe: [shoeList]}})
        
    }

    var breadcrumb = !notRec ? [
        {
            title: 'recommendations',
            href: '/dashboard/recommendations'
        },
        {
            title: `${preferences? preferences.shoeUsage : null}, ${preferences? preferences.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        },
        {
            title: `${shoe?.shoe.brand.name} ${shoe?.shoe.model} ${shoe?.shoe.version}`,
            href: `/dashboard/recommendations/${recommendationId}/${shoeName}`
        }
    
    ] :
    [
        {
            title: 'browse all shoes',
            href: '/dashboard/browse'
        },
        {
            title: `${shoe?.shoe.brand.name} ${shoe?.shoe.model} ${shoe?.shoe.version}`,
            href: `/dashboard/recommendations/${recommendationId}/${shoeName}`
        }
    
    ]

    

    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '100vh'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader
                breadcrumb={breadcrumb}
                />
                <FeedbackSnackbar />
                <div className="container-fluid dashboard-body pt-2 pb-5" id="recommendationsArea">
    
                        {/* Shoe information tile */}
                        <div className="row mx-2 ">
                            {/* Shoe information column */}
                            <div className="col-12 col-xl-10 mx-auto show-shoe-tile py-md-5 px-md-4 px-lg-5">
                                <div className="row">
    
                                    {/* Left side, shoe information, suitability and reviews tabs */}
                                    <div className="col-12 text-center mx-auto col-xl-7 mx-xl-0 order-2 order-xl-1">
    
                                        <div className="row">
                                            <div className="col-7 col-lg-6 mx-auto text-center text-xl-start mx-xl-0">
                                                <img src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.shoe.brand.slug}.png`} alt="" className="img img-fluid my-3"/>
                                            </div>
                                        </div>
    
                                        <div className="row justify-content-center justify-content-xl-start">
                                            <div className="col-auto text-center text-lg-start mb-3">
                                                <h1>{shoe?.shoe.brand.name} {(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)} {shoe?.shoe.version} (${shoe?.shoe.msrp})</h1>
                                            </div>
                                            <div className="col-auto px-0">
                                                <Tooltip title="Copy">
                                                    <IconButton onClick={(evt) => handleCopy(evt, `${shoe?.shoe.brand.name} ${(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)} ${shoe?.shoe.version} ${user?.runningProfile.footWidth === 'wide' ? (user?.runningProfile.gender === 'male' ? `2E width` : `D width` ) : (user?.runningProfile.footWidth === 'extra-wide' ? (user?.runningProfile.gender === 'male' ? `4E width` : `2e width` ) : null)} ${user?.runningProfile.gender}`)}>
                                                        <ContentCopyIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        
                                        <div className="row mt-md-3">
                                            <div className="col mx-auto mx-lg-2 px-0">
                                                <Tabs 
                                                    value={value}
                                                    onChange={handleChange}
                                                    aria-label="shoeInformationTabs"
                                                    variant="fullWidth"
                                                    scrollButtons="auto"
                                                    allowScrollButtonsMobile
                                                >
                                                    <Tab icon={<InfoOutlinedIcon />} iconPosition="top" label="Details" {...a11yProps(0)} />
                                                    <Tab icon={<PermContactCalendarOutlinedIcon />} iconPosition="top" label={`Suitability (${((shoe?.score/shoe?.maxScore)*100).toFixed(0)}%) `} {...a11yProps(1)} />
                                                    <Tab icon={<QueryStatsIcon />} iconPosition="top" label={`Ratings ${shoe?.shoe.websiteReviews.length && shoe?.shoe.websiteReviews.length > 0 ? `(${(parseFloat(shoe?.shoe.averageWebsiteScore)/2).toFixed(1)}/5)` : "" }`} {...a11yProps(2)}/>
                                                </Tabs>
                                            </div>             
                                        </div>
                                        <div className="row">
                                            <div className="col px-0">
                                                <Divider/>
                                            </div>
                                        </div>
    
                                        <div className="row">
                                            <div className="col px-0 ">
    
    
                                                {/* Overview tab */}
                                            <CustomTabPanel className="px-0" value={value} index={0}>                                             
    
    
    
                                                <div className="row mt-3">
                                                    <div className="col-12 text-start">
                                                        <p>{shoe?.shoe.descripton}</p>
                                                    </div>
                                                </div>

                                                <div className="row justify-content-start mb-2">
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.surface} running`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.cushion}, ${shoe?.shoe.cushionType} cushion`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.stability} stability`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.weight}g`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.shoeShape} toe-box`} color={'default'}/>
                                                    </div>
                                                    {shoe?.shoe.surface === 'trail' ?
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.grip} grip (${shoe?.shoe.lugDepth}mm lugs)`} color={'default'}/>
                                                    </div>
                                                    : null}
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.carbonPlate == 'FALSE' ? 'No carbon plate' : 'Carbon plate'}`} color={'default'}/>
                                                    </div>
    
    
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.heelToeDrop}mm heel-toe-drop`} color={'default'}/>
                                                    </div>
    
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.rocker} rocker`} color={'default'}/>
                                                    </div>

                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`Width options: ${shoe?.shoe.width.join(', ')}`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent 
                                                            size="small" 
                                                            label={
                                                                    `${shoe?.shoe.upperType} upper (${shoe?.shoe.upperName})`
                                                            } 
                                                            color={'default'}
                                                        />
                                                    </div>
                                                </div>

                                                <Divider className="my-2"/>
                                                {shoe?.shoe.websiteReviews.length > 3 ?
                                                <ShoeAnalysisSection websiteReviews={websiteReviews} open={open} setOpen={setOpen} openAccordian={openAccordian} />
                                                : null}

                                                {shoe?.shoe.websiteReviews.length > 3 ?
                                                        <div className="row justify-content-start mt-2 mx-1">
                                                        <AskAIPanel shoe={shoe? shoe : null} />
                                                        </div>
                                                    : null}

                                                {/* Key features section */}
                                                <Divider className="my-2"/>

                                                <div className="row mt-3">
                                                    <div className="col-12 text-start">
                                                        <h4>Key features</h4>
                                                    </div> 
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-12 text-start">
                                                        {shoe?.shoe?.keyFeatures?.map((feature, index) => {
                                                            return (
                                                                <div key={`keyFeature${index}`} className="row">
                                                                    <div className="col-12 text-start">
                                                                        <p><span style={{fontWeight: 'bold'}}>{index + 1}. {feature.title}:</span> {feature.description} </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                

                                                {shoe?.shoe.whatsNew && shoe?.shoe.whatsNewList?
                                                <div>

                                                    <Divider className="my-2"/>

                                                    <div className="row mt-3">
                                                        <div className="col-12 text-start">
                                                            <h4>Previous models</h4>
                                                        </div> 
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 text-start">
                                                            <p>{shoe?.shoe.whatsNew}</p>
                                                        </div>
                                                    </div>
                                                    { shoe?.shoe.whatsNewList.length > 0 ?
                                                    <div className="row">
                                                    <Accordion>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <div className="row align-items-center">
                                                                <div className="col-auto">
                                                                    <InfoOutlinedIcon/>
                                                                </div>
                                                                <div className="col-auto">
                                                                    List of updates
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                        <div className="col text-start">
                                                            <ul>
                                                            {shoe?.shoe.whatsNewList.map((trait, index) => {
                                                                return (
                                                                    <li key={`whatsNewListItem${index}`}><p>{trait.title}: {trait.description}</p></li>
                                                                )
                                                            })}

                                                            </ul>                                  
                                                    </div>

                                                        </AccordionDetails>
                                                    </Accordion>
                                                   
                                                </div>
                                                    : null
                                                    }
                                                    

                                                    <PreviousModelSection shoe={shoe} sendToCompare={sendToCompare} setShoeColorway={setShoeColorway} preferences={preferences} requirements={requirements} notRec={notRec}/>

                                                </div>

                                                : null }
                                                
    
                                            </CustomTabPanel>
    
    
                                                {/* Suitability tab */}
                                                <CustomTabPanel className="px-0" value={value} index={1}>
                                                    {/* <div className="row mt-3">
                                                        <div className="col-12 text-start">
                                                            <h2>Suitability ({((shoe?.score/shoe?.maxScore)*100).toFixed(0)}%)</h2>
                                                        </div>
                                                    </div> */}

                                                    {notRec ?
                                                    <div className="row mt-1">
                                                        <div className="col text-start">
                                                            <Alert severity="info" >For an in-depth analysis of this shoe, <span onClick={(evt) => navigate('/dashboard/recommendations')} style={{textDecoration: 'underline', cursor: 'pointer'}}>create a recommendation</span> to take into account your intended use and preferences</Alert>
                                                        </div>
                                                    </div>
                                                    : null}
    
                                                    <div className="row mt-2">
                                                        <div className="col-12 text-start">
                                                            <p>Based on your running profile and intended use, this shoe is a {((shoe?.score/shoe?.maxScore)*100).toFixed(0)}% match. Click the dropdowns in each section to learn more about your matching information and the potential impact of a bad decision.</p>
                                                        </div>
                                                    </div>
    
                                                    <div className="row mb-3">
                                                        <div className="col text-start">
                                                            {(() => {
                                                                // Group criteria by category
                                                                const criteriaByCategory = requirements ? shoe?.criteria?.reduce((acc, criteria) => {
                                                                    const traitRequirements = requirements[criteria.trait];
                                                                    const category = traitRequirements?.reasoning?.[0]?.category || 'Uncategorized';
                                                                    if (!acc[category]) {
                                                                        acc[category] = [];
                                                                    }
                                                                    acc[category].push(criteria);
                                                                    return acc;
                                                                }, {}) : {};

                                                                // Define the order of categories
                                                                const categoryOrder = ['requirement', 'preference'];

                                                                // Filter and sort categories based on the defined order
                                                                const filteredCategories = categoryOrder?.filter(category => criteriaByCategory[category] && requirements);

                                                                // Render criteria sorted by category
                                                                return filteredCategories.map((category) => (
                                                                    <div key={`${category}Category`} className="category-group">
                                                                        <h2 className='my-3'>{category === 'requirement' ? 'Based on your running style and needs' : 'Based on your intended use'}</h2>
                                                                        {criteriaByCategory[category].sort((a, b) => b.priorityScore - a.priorityScore).map((criteria, index) => {
                                                                            const string = criteria.trait.split(/(?=[A-Z])/).join(' ').toLowerCase();
                                                                            const finalString = (shoeTraitMapping[criteria.trait] || string).charAt(0).toUpperCase() + (shoeTraitMapping[criteria.trait] || string).slice(1);
                                                                            const criteriaPriority = criteria.priorityScore / shoe.maxScore > 0.15 ? 'High' : criteria.priorityScore / shoe.maxScore > 0.05 ? "Medium" : "Low";
                                                                            if (requirements[criteria.trait]?.reasoning?.length > 0 || criteria.pass === "FALSE") {
                                                                                return (
                                                                                    <div key={`${criteria.trait}TraitRow`} className="row align-items-center my-1">
                                                                                        <div className="col">
                                                                                            <Accordion style={{boxShadow: 'none', border: criteria.pass === 'TRUE' ? '1px solid #EEEBEB' : criteriaPriority === 'High' ? '1px solid red' : (criteriaPriority === 'Medium' ? '1px solid orange' : '1px solid #EEEBEB')}}  expanded={expanded === `panel${category}${index}`} onChange={handleAccordianClick(`panel${category}${index}`)}>
                                                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${category}${index}bh-content`} id={`panel${category}${index}bh-header`}>
                                                                                                    <div className="col">
                                                                                                        <div className="row align-items-center justify-content-between">
                                                                                                            <div className="col-auto">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-auto">
                                                                                                                        {criteria.pass === 'TRUE' ? <CheckCircleOutlineIcon color="success" /> : (criteriaPriority === "High" ? <ErrorOutlineIcon color="error" /> : criteriaPriority === "Medium" ? <WarningAmberIcon color="warning" /> : <WarningAmberIcon color="warning" /> )  }
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <h3 className="my-0">{`${finalString}`}</h3>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-auto">
                                                                                                                <p className="my-0">Importance: {criteria.priorityScore / shoe.maxScore > 0.15 ? `High` : criteria.priorityScore / shoe.maxScore > 0.05 ? 'Medium' : 'Low'}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </AccordionSummary>
                                                                                                <AccordionDetails>
                                                                                                    <div className="row my-1 justify-content-evenly">
                                                                                                        <div className="col-auto px-5 py-3 trait-tile">
                                                                                                            <div className="row">
                                                                                                                <div className="col">
                                                                                                                    <h4 className="my-0">Recommended:</h4>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col">
                                                                                                                    <p className="my-0">
                                                                                                                        {requirements[criteria.trait]?.traits?.length 
                                                                                                                            ? requirements[criteria.trait].traits.reduce((accu, elem) => {
                                                                                                                                const mappedValue = shoeValueMapping[criteria.trait]?.[elem] || elem;
                                                                                                                                return accu === null ? [mappedValue] : [...accu, ' or ', mappedValue];
                                                                                                                            }, null) 
                                                                                                                            : Object.entries(requirements[criteria.trait]?.traits || {}).map((elem) => 
                                                                                                                                `${elem[0]}: ${shoeValueMapping[criteria.trait]?.[elem[1]] || elem[1]}`
                                                                                                                            ).join(', ')}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-auto px-5 py-3 trait-tile">
                                                                                                            <div className="row">
                                                                                                                <div className="col">
                                                                                                                    <h4 className="my-0">This shoe:</h4>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col">
                                                                                                                    <p className="my-0">
                                                                                                                        {Array.isArray(criteria.value) 
                                                                                                                            ? criteria.value.map(val => shoeValueMapping[criteria.trait]?.[val] || val).join(', ')
                                                                                                                            : shoeValueMapping[criteria.trait]?.[criteria.value] || criteria.value}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>  
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {requirements[criteria.trait]?.description ? 
                                                                                                    <div className="row pt-3 px-3">
                                                                                                        <div className="col text-start">   
                                                                                                            <h4 className="mb-2">Description:</h4>
                                                                                                            <p key={`${index}ReasonParagraph`} className="my-2">- {requirements[criteria.trait].description}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null}

                                                                                                    {criteria.trait === 'cushionType' ?                                                      
                                                                                                    <div className="row px-3">
                                                                                                        <div className="col text-start">
                                                                                                            <p className="my-0">- {shoe?.shoe.cushionTypeReason}</p>
                                                                                                        </div>
                                                                                                    </div>  
                                                                                                    : null}  

                                                                                                    <div className="row pt-3 px-3">
                                                                                                        <div className="col text-start">   
                                                                                                            <h4 className="mb-2">Your needs:</h4>
                                                                                                            {requirements[criteria.trait]?.reasoning?.map((reason, index) => { return <p key={`${index}ReasonParagraph`} className="my-2">- {reason.description} <span><Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={reason.impact}><InfoOutlinedIcon/></Tooltip></span></p> })}    
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="row pt-3 px-3">
                                                                                                        <div className="col text-start">   
                                                                                                            <img className="img img-fluid" src={`/shoeTraitAssets/${criteria.trait}.png`} alt="" />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </AccordionDetails>
                                                                                            </Accordion>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null; // Ensure a return statement for the map function
                                                                        })}
                                                                    </div>
                                                                ));
                                                            })()}
                                                        </div>
                                                    </div>
                                                </CustomTabPanel>
    
                                                {/* Reviews tab */}
                                                <CustomTabPanel className="px-0" value={value} index={2}>

                                                    <div className="row">
                                                        <div className="col" onClick={() => setReviewType('amateur')} style={{cursor: 'pointer'}}>
                                                            <div className="row">
                                                                <div className="col info-tile mx-2 my-2 py-2">
                                                                    <div className="row">
                                                                        <div className="col-auto mx-auto">
                                                                            <UserCircle size={32} weight="fill" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <p>Reviews on Fittir <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setReviewType('amateur')}>({runnerReviews})</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h3 key={`averageReviewScore${averageReviewScore}`}>{averageReviewScore? `${averageReviewScore}/5` : '-'}</h3>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col" onClick={() => setReviewType('wear-tester')} style={{cursor: 'pointer'}}>
                                                            <div className="row">
                                                                <div className="col info-tile mx-2 my-2 py-2">
                                                                <div className="row">
                                                                        <div className="col-auto mx-auto">
                                                                            <GraduationCap size={32} weight="fill" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <p>Wear-tester reviews <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setReviewType('wear-tester')}>({shoe?.shoe.websiteReviews.length})</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h3>{shoe?.shoe.averageWebsiteScore ? `${(shoe?.shoe.averageWebsiteScore / 2).toFixed(1)}/5` : '-'}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                     
                                                        </div>
                                                    </div>
                                                    
    
                                                    <div className="row mb-3">
                                                        <div className="col" id="reviewsList">
                                                            <ReviewsList user={user} shoe={shoe} setRunnerReviews={setRunnerReviews} averageReviewScore={averageReviewScore} setAverageReviewScore={setAverageReviewScore} type={reviewType} websiteReviews={websiteReviews} />
                                                        </div>
                                                    </div>
    
                                                </CustomTabPanel>
                                            </div>
                                        </div>
    
                                    </div>
    
    
                                    {/* right side, shoe image, and deals */}
                                    <div className="col-11 mx-auto col-xl-5 mx-xl-0 pt-5 text-center order-1 order-xl-2">
                                        <div className="row align-items-center pb-4">
                                            <div className="col">
                                                <img key={`${shoe?.shoe.brand.slug}_${shoe?.shoe.modelSlug}_${shoe?.shoe.version}_${shoeColorway?.colorwayCode}`} src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.shoe.brand.slug}_${shoe?.shoe.modelSlug}_${shoe?.shoe.version}_${shoeColorway?.colorwayCode}.png`} alt="" className="img img-fluid"/>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                                <div className="col">
                                                    <p className="my-0">Color: {shoeColorway?.colorwayName}</p>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col">
                                                    <p className="my-0">Width options: {shoeColorway?.widthOptions?.join(', ')}</p>
                                                </div>
                                        </div>

                                        <div className="row justify-content-center mt-3">

                                            <div className="my-1 col-auto">
                                                <Button endIcon={<CompareArrowsIcon />} variant="outlined" size="small" onClick={(evt) => sendToCompare(evt, [shoe])}>
                                                    Compare
                                                </Button>
                                            </div>
                                            
                                            {!userShoe? 
                                            <div className="my-1 col-auto">
                                                <Button endIcon={<DoorSlidingOutlinedIcon/>} onClick={(evt) => setAddShoeModalOpen(true)} variant="outlined" size="small">
                                                    Add to shoe locker
                                                </Button>

                                            </div>
                                            :
                                            <div className="my-1 col-auto">
                                                <Tooltip title={'You own this shoe'}>
                                                    <span>
                                                        <Button disabled variant="outlined" size="small">
                                                            Owned
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                    
                                            </div>
                                            }   
                                            </div>
                                                                                    
                                        <div className="row mt-3 px-2 justify-content-center">
                                        {shoe?.shoe.colorways
                                        .sort((a, b) => {
                                            // Check if colorway has a deal
                                            const hasDealA = partnerDeals.some(deal => deal.shoeColorway.toString() === a._id.toString());
                                            const hasDealB = partnerDeals.some(deal => deal.shoeColorway.toString() === b._id.toString());

                                            // Check if width is available for colorway A and B
                                            const widthAvailableA = shoe.shoe.shoeShape === 'foot shaped' || a?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));
                                            const widthAvailableB = shoe.shoe.shoeShape === 'foot shaped' || b?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));

                                            // Sort priority:
                                            // 1. Has deal and width available (or foot shaped)
                                            // 2. Has width available (or foot shaped) but no deal
                                            // 3. Has deal but not in the required width
                                            // 4. No deal and no width available
                                            if (hasDealA && widthAvailableA !== hasDealB && widthAvailableB) {
                                                return hasDealB - hasDealA; // Prioritize deals with width availability
                                            }
                                            if (widthAvailableA !== widthAvailableB) {
                                                return widthAvailableB - widthAvailableA; // Prioritize width availability
                                            }
                                            return 0; // No change if both deal and width are the same
                                        })
                                        .map((colorway, index) => {
                                            // Check if the colorway is available in the required width
                                            var widthAvailable = shoe.shoe.shoeShape !== 'foot shaped' 
                                                ? colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)) 
                                                : true;

                                            // Check if the colorway has a deal
                                            const hasDeal = partnerDeals.some(deal => deal.shoeColorway.toString() === colorway._id.toString());

                                            return (
                                                <div 
                                                    className="col-2 mx-1 my-1" 
                                                    style={{cursor: widthAvailable ? 'pointer' : 'default' }} 
                                                    key={`${shoe.shoe._id}Colorway${index}`} 
                                                    onClick={(evt) => handleColorwayChange(evt, colorway)}
                                                >
                                                    <div className="row">
                                                        <div 
                                                            className="col py-2" 
                                                            style={{
                                                                border: shoeColorway?.colorwayCode === colorway.colorwayCode 
                                                                    ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') 
                                                                    : '1px solid #D9D9D9', 
                                                                borderRadius: '0.5em', 
                                                                opacity: widthAvailable ? '1' : '0.3'
                                                            }}
                                                        >
                                                            <img 
                                                                className='img-fluid' 
                                                                src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} 
                                                                alt="" 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        </div>


                                        <div className="row mt-4" id="retailOffers">
                                            <PartnerDealsSection key={`partnerDealsSection${visibleDeals?.length}${shoeColorway?._id}`} user={user} shoe={shoe?.shoe} colorway={shoeColorway} setColorway={setShoeColorway} handleColorwayChange={handleColorwayChange} partnerDeals={visibleDeals} recommendationId={recommendationId}   />
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
    
    
                            {/* Similar shoes column */}
                            <div className="col-12 col-xl-10 mx-auto my-3">
                                <div className="row mt-4">
                                    <SimilarShoesList recommendationId={recommendationId} shoe={shoe} preferences={preferences} requirements={requirements} shoeColorway={shoeColorway} setShoeColorway={setShoeColorway} notRec={notRec} />
                                </div>
                               
                            </div>
                        </div>
                </div>
    
                <ScrollRestoration />
                <AddShoeModal selectedShoe={shoe} openState={addShoeModalOpen} setOpenState={setAddShoeModalOpen} />
                {/* <PurchaseShoeModal openState={purchaseShoeModalOpen} setOpenState={setPurchaseShoeModalOpen} shoe={shoe} colorway={shoeColorway} requirements={requirements} /> */}
            </div>
        )
    }
    
}