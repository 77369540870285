import { useState, useEffect } from "react";
import { Button, Fab, Stack, Pagination } from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SearchPanel from "../../../components/Dashboard/Panels/SearchPanel/index.jsx";
import SortButton from "../../../components/Dashboard/Buttons/SortButton/index.jsx";
import ShoeCard from "../../../components/Dashboard/Cards/ShoeCard/index.jsx";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useAppContext } from "../../../utils/ContextProvider.jsx/index.jsx";
import { useNavigate, useOutletContext } from "react-router-dom";
import { sortingMethodsRecommendation } from "../../../utils/SortingMethods/index.js";
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx";
import Loader from "../../../components/Loader/index.jsx";
import { ScrollRestoration } from "react-router-dom";
import ShoeFilterDropdowns from "../../../components/Dashboard/Panels/FilterRow/index.jsx";
import { createEvent } from "../../../utils/CreateEvent/index.js"; // Make sure to import createEvent

export default function BrowseShoesPage() {

    var navigate = useNavigate()

    var buttons = [
        {
            text: 'Create a shoe recommendation',
            function: () => {navigate('/shoe-finder/preferences/surface')},
        },
        {
            text: 'Update your running profile',
            function: () => {return navigate('/dashboard/profile')}
        }

    ]

  const { isLoading, setLoading, requestUrl, setIsAlert } = useAppContext();
  var [user, userShoes] = useOutletContext();
  const [reviews, setReviews] = useState([])

  const [partnerDeals, setPartnerDeals] = useState()
  const [generalRec, setGeneralRec] = useState();
  const [shoes, setShoes] = useState();
  const [selectedShoes, setSelectedShoes] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("suitability");
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [surface, setSurface] = useState("road");

  // Debounce the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 100); // Adjust the delay as needed (300ms is common)
    
    // Cleanup timeout if the user types again before the delay finishes
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const generalRecommendationUrl = `${requestUrl}/api/users/generalRecommendation`;
    const partnerDealsUrl = `${requestUrl}/api/partnerDeals`;
    const reviewsUrl = `${requestUrl}/api/shoeReviews`;
    const generalRecommendationRequest = axios({
      url: generalRecommendationUrl,
      method: "GET",
      withCredentials: true,
    });

    const partnerDealsRequest = axios({
      url: partnerDealsUrl,
      method: "GET",
      withCredentials: true,
    });

    const reviewsRequest = axios({
      url: reviewsUrl,
      method: "GET",
      withCredentials: true
    });

    Promise.all([generalRecommendationRequest, partnerDealsRequest, reviewsRequest])
      .then(([generalRecommendationRes, partnerDealsRes, reviewsRes]) => {
        setGeneralRec(generalRecommendationRes.data);
        setShoes(generalRecommendationRes.data.roadRecommendation.shoes);
        setPartnerDeals(partnerDealsRes.data)
        setReviews(reviewsRes.data.reviews)
      })
      .catch((err) => {
        Sentry.captureException(err);
        const errorMessage =
          err.response?.data.err.message || "Whoops, something went wrong";
        setIsAlert((pageData) => ({
          ...pageData,
          error: errorMessage,
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestUrl]);

  useEffect(() => {
    // Filter shoes based on debounced search value
    if (debouncedSearch && debouncedSearch !== "") {
      const filteredShoes = generalRec[`${surface}Recommendation`]?.shoes?.filter((shoe) =>
        (shoe.shoe.brand.name + shoe.shoe.model)
          .replace(/\s/g, "")
          .replace("-", "")
          .toLowerCase()
          .includes(debouncedSearch.replace(/\s/g, "").replace("-", "").toLowerCase())
      );
      setShoes(filteredShoes);
      setPage(1)
    } else {
      setShoes(generalRec?.[`${surface}Recommendation`]?.shoes);
    }
  }, [debouncedSearch, generalRec, surface]);

  const toggleShoe = (evt, shoe) => {
    setSelectedShoes((prevSelectedShoes) => {
      if (prevSelectedShoes.some((selectedShoe) => selectedShoe.shoe._id === shoe.shoe._id)) {
        // Remove shoe if it exists in the array
        return prevSelectedShoes.filter(
          (selectedShoe) => selectedShoe.shoe._id !== shoe.shoe._id
        );
      } else {
        // Add shoe if it doesn't exist in the array
        return [...prevSelectedShoes, shoe];
      }
    });
  };

  const clearSearch = () => {
    setSearch("");
    setShoes(generalRec?.[`${surface}Recommendation`]?.shoes);
  };

  const handlePageChange = (evt, value) => {
    setPage(value);
    const element = document.getElementById("sortButton");
    setTimeout(() => {
      element.scrollIntoView();
    }, 1);
  };

  const handleComparisonPageClick = () => {
    var url = `/dashboard/browse/${generalRec?.[`${surface}Recommendation`]._id}/compare`
    navigate(url, {state: {shoe: [selectedShoes], rec: generalRec?.[`${surface}Recommendation`]._id}})

}

  return isLoading ? (
    <div>
        <Loader />
            <div className="container-lg main-form-body">
            
            <div className="row mt-4"></div>
            <div className="row" style={{minHeight: '100vh'}}>
            </div>
            <ScrollRestoration/>
        </div>
    </div>
  ) : (
    <div>
      <DashboardHeader heading={`Browse shoes`} subHeading={'Browse a list of all running shoes available on Fittir. For more specific recommendations based on your needs/preferences, please create a recommendation'} buttons={buttons} />
        <div className="container-fluid dashboard-body pt-4 pb-5" id="recommendationsArea">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-xl-11 mx-auto">
                        <div className="row">
                            {/* List */}
                            <div className="col-12 col-xl-9 shoe-list-column pe-lg-3 pe-xl-5 mx-auto mb-2 order-2 order-xl-1 mt-3 mt-xl-0">
                                <div className="row mt-3 mt-lg-0 ps-lg-2 align-items-center justify-content-center">
                                    <div className="col-10 mx-auto col-md mx-md-0 my-1">

                  <div className="row mt-3 mt-lg-0 ps-lg-2 align-items-center justify-content-center">
                        {/* Search and results number */}
                        <div className="col-10 mx-auto col-md mx-md-0 my-1">
                            <div className="row align-items-center">
                                <div className="col-11 col-lg-7 px-0 mx-auto mx-md-0 my-2 my-md-0 search-input">
                                    <SearchPanel search={search} handleChange={(e) => setSearch(e.target.value)} />
                                </div>
                                {search ?
                                <div className="col-auto">
                                    <a onClick={clearSearch}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear</p></a>
                                </div>
                                : null}
                            </div>
                            </div>                        
                                </div>
                                </div>
                                <div className="col-auto mx-auto my-1">
                                    <div className="row">
                                        <div className="col-auto">
                                            <Button variant="contained" endIcon={<CompareArrowsIcon />} onClick={handleComparisonPageClick}>
                                                Compare
                                            </Button>
                                        </div>
                                        <div className="col-auto" id="sortButton">
                                            <SortButton sort={sort} setSort={setSort} />
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row my-3" key={`${shoes?.length}`}>
                                    {(() => {
                                      const sortedShoes = shoes?.sort(sortingMethodsRecommendation[sort].method);
                                      const paginatedShoes = sortedShoes?.slice((page - 1) * 6, page * 6);

                                      // Track the shoes being viewed
                                      createEvent({
                                        action: 'view_shoes_list',
                                        category: 'interaction',
                                        data: {
                                          shoeIds: paginatedShoes?.map(shoe => shoe.shoe._id),
                                          route: 'browse',
                                          page: page,
                                          sortMethod: sort,
                                          surface: surface,
                                          search: debouncedSearch,
                                          recommendationId: generalRec?.[`${surface}Recommendation`]._id
                                        }
                                      });

                                      return paginatedShoes?.map((shoe) => (
                                        <div className="col-12 col-lg-6 my-2" style={{ height: "100%" }} key={shoe.shoe._id}>
                                          <ShoeCard
                                            shoe={shoe}
                                            requirements={generalRec?.[`${surface}Recommendation`].requirements}
                                            preferences={generalRec?.[`${surface}Recommendation`].preferences}
                                            recommendationId={generalRec?.[`${surface}Recommendation`]._id}
                                            currentShoe={userShoes?.some((userShoe) => userShoe.shoeId._id === shoe.shoe._id)}
                                            selectedShoes={selectedShoes}
                                            toggleShoe={toggleShoe}
                                            partnerDeals={partnerDeals}
                                            notRec={true}
                                            reviews={reviews}
                                          />
                                        </div>
                                      ));
                                    })()}
                                </div>
                                <div className="row">
                                    <div className="col-auto mx-auto">
                                    <Stack spacing={2}>
                                        <Pagination
                                        count={Math.ceil(shoes?.length / 6)}
                                        color="primary"
                                        page={page}
                                        onChange={handlePageChange}
                                        />
                                    </Stack>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 mx-auto mx-lg-0 col-xl-3 order-1 order-xl-2">
                                <ShoeFilterDropdowns shoes={shoes} setShoes={setShoes} surface={surface} setSurface={setSurface} generalRec={generalRec} setPage={setPage} partnerDeals={partnerDeals} />
                            </div>
                        </div>
                    </div>
                    {selectedShoes.length > 0 && (
                <Fab style={{ margin: 0, top: "auto", right: 20, bottom: 20, left: "auto", position: "fixed" }} variant="extended" color="primary"  onClick={handleComparisonPageClick}>
                  <CompareArrowsIcon sx={{ mr: 1 }} />
                  Compare
                </Fab>
              )}
                </div>
            </div>
        </div>
    </div>
  );
}