import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { useAppContext } from '../../utils/ContextProvider.jsx';
import { useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import FlashAlert from "../../components/Alert";
import "./index.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import * as Sentry from "@sentry/react";
import UpgradePlanModal from "../../components/Dashboard/Modal/UpgradePlanModal";
import SideNavBar from "../../components/Dashboard/SideNav";
import { IconButton, CircularProgress, Backdrop } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SideNavBarMobile from "../../components/Dashboard/SideNavMobile";
import {Helmet} from "react-helmet";

export default function DashboardPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, isAlert, setAlertState, setIsLoading, isFiltering, setIsFiltering, requestUrl, setIsAlert, setLoading, trackEvent, sessionUser, setSessionUser } = useAppContext();

    const [user, setUser] = useState();
    const [userShoes, setUserShoes] = useState();
    const [fetchingInfo, setFetchingInfo] = useState();

    var [open, setOpen] = useState(false)

    useEffect(() => {
        const url = `${requestUrl}/api/userShoes`;
        const userUrl = `${requestUrl}/api/users`;
        const query = new URLSearchParams(window.location.search);
        const isPaymentSuccess = query.get("success");

        const userShoesRequest = axios({
            url: url,
            method: "GET",
            withCredentials: true
        });

        const userRequest = axios({
            url: isPaymentSuccess ? `${userUrl}?paymentReferral=true` : userUrl,
            method: "GET",
            withCredentials: true
        });

        Promise.all([userShoesRequest, userRequest])
            .then(([userShoesRes, userRes]) => {
                setUserShoes(userShoesRes.data.userShoes);
                const user = userRes.data.user;
                setUser(user);
                setSessionUser(user);

                if (!user) {
                    localStorage.removeItem('isAuthenticated');
                    setIsAlert(pageData => ({
                        ...pageData,
                        error: 'Please login to view your dashboard'
                    }));
                    navigate('/auth/login');
                }

                if (isPaymentSuccess) {
                    trackEvent('Payment completed');
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }));
                navigate('/auth/login');
            });
    }, [navigate, requestUrl]);

    return (
        <div className="vh-100" style={{backgroundColor: '#F8F9FA'}}>
            <Helmet>
                <title>Fittir - Dashboard</title>
                <meta property="og:image" content="/meta/og_image.png" />
            </Helmet>
            {/* Flash Alerts */}
            {isAlert.error && <FlashAlert severity="error" message={isAlert.error} setAlertState={setAlertState} />}
            {isAlert.success && <FlashAlert severity="success" message={isAlert.success} setAlertState={setAlertState} />}
            
            <div className="row d-inline-flex d-md-none mt-1 py-2 px-2 justify-content-between" style={{backgroundColor: 'white', borderBottom: '1px solid #F8F9FA'}}>
                <div className="col-5">
                    <a href="/"><img className="img" style={{width: '60px'}} src="/logos/logo-primary.svg" alt="" /></a>
                </div>
                <div className="col-6 text-end">
                <IconButton size="large" aria-label="delete" onClick={(evt) => setOpen(true)}>
                    <MenuIcon size="large" style={{fontSize: '2rem'}} />
                </IconButton>
                </div>
            </div>

            <SideNavBarMobile open={open} setOpen={setOpen} />

            {/* Main content */}
            <div className="main-content d-flex">
                {/* Sidebar */}
                <div className="col-auto d-none d-md-inline">
                    <SideNavBar />
                </div>

                {/* Main content next to Sidebar */}
                <div className="col flex-grow-1 px-0 py-0">
                    <Outlet context={[user, userShoes]} />
                    <UpgradePlanModal user={user} />
                    <ScrollRestoration />
                    <div className="row" style={{backgroundColor: '#F8F9FA'}}>
                <div className="col-auto mx-auto text-center">
                    <p>Made with 💙 in Queenstown, New Zealand by <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => window.open('https://www.instagram.com/run.ant.run/')}>Anthony Grant</span></p>
                </div>
            </div>
                </div>
            </div>

            

            {/* Footer */}
            {/* <Footer /> */}
        </div>
    );
}
