import { useAppContext } from "../../../utils/ContextProvider.jsx"

export default function FormHeader({ progress, section = null }) {

    var { partner } = useAppContext()

    return (
        <header>
            <div className="container form-area">
                <div className={!partner ? "row align-items-center justify-content-between" : "row align-items-center justify-content-center justify-content-md-between"}>

                    
                    <div className={!partner ? "col-4" : "col-2 d-none d-md-inline"}>
                    {!partner ? 
                        <a className="navbar-brand" href="/"><img src="/logos/logo-primary.svg" className="logo-primary"/></a>

                    :  
                        <a className="navbar-brand" href={`/shoe-finder/intro?partner=${partner._id}`}><img src={`/logos/partners/${partner.slug}_icon.svg`} className="img img-fluid"/></a>
                    }
                        
                    </div>

                    <div className={!partner ? "col-6" : "col-10 col-md-6"}>
                        <div className="row">
                            <div className="progress mt-2 px-0">
                                <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0"
                                    aria-valuemax="100" style={{ width: `${progress}%`, backgroundColor: partner? partner.primary : null}}></div>
                            </div>
                        </div>
                        <div className="row pt-2 justify-content-between">
                            <div className="col px-1">
                                <p>
                                    {progress}%
                                </p>
                            </div>
                            <div className="col text-end">
                                <p><i>{section}</i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}