import * as React from 'react';
import Dialog  from '@mui/material/Dialog';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import axios from 'axios';
import { useAppContext } from '../../../utils/ContextProvider.jsx/index.jsx';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button'
import  DialogActions from '@mui/material/DialogActions';
import { set } from 'mongoose';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export default function EditShoeModal({setSelectedShoe = null, setSelectedShoeData= null, setSelectedShoeIsCurrent = null, selectedShoe = null, selectedShoeData = null, selectedShoeIsCurrent = null, heading = null, content = null, width = 'md', setOpenState = null, openState = null}) {

    var navigate = useNavigate()
    
    var { requestUrl, setLoading, setIsAlert, setLoading, isProcessing, setProcessing, trackEvent } = useAppContext()

    const handleClickOpen = () => {
        setOpenState(true);
    };

    const handleClose = () => {
        setSelectedShoe(null)
        setOpenState(false);
    };

    var [fetchingShoes, setFetchingShoes] = useState(false)

    var [allShoes, setAllShoes] = useState()

    var [shoe, setShoe] = useState(selectedShoe)

    var [shoeData, setShoeData] = useState({
        rating: selectedShoeData.rating,
        usage: selectedShoeData.usage,
        description: selectedShoeData.description
    })

    var [shoeIsCurrent, setShoeIsCurrent] = useState(selectedShoeIsCurrent)

    useEffect(()=> {
        setFetchingShoes(true)
        var url = `${requestUrl}/api/shoes`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setAllShoes(res.data.allShoes)
            setFetchingShoes(false)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setFetchingShoes(false)
        })
    },[])

    const updateShoeData = (evt) => {
        const changedField = evt.target.name
        const newValue = evt.target.value
        setSelectedShoeData(currData => {
            return {
                ...currData,
                [changedField]: newValue
            }
        })
    }

    const updateUserShoe = (evt) => {
        setProcessing(true)
        var url = `${requestUrl}/api/userShoes/${selectedShoe?._id}`
        axios({
            url: url,
            method: "PUT",
            data: {
                shoeId: selectedShoe?.shoeId._id,
                rating: selectedShoeData.rating,
                description: selectedShoeData.description,
                usage: selectedShoeData.usage,
                current: selectedShoeIsCurrent
            },
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Update existing shoe', {shoeName: `${selectedShoe?.shoeId.brand.name} ${selectedShoe?.shoeId.mode} ${selectedShoe?.shoeId.version}`})
            setProcessing(false)
            setOpenState(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Current shoe updated ✅`
                }
            })
            navigate(0)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }



    return (
        <React.Fragment>
                <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={openState}
                        fullWidth
                    >
                    <div className="row px-3 py-3">
                        <div className="col">
                            <div className="px-3 py-2 row align-items-center justify-content-between">
                                <div className="col-8">
                                    <h3 className="my-0">{heading}</h3>
                                </div>
                                <div className="col-auto text-end">
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>

                            {selectedShoe?
                             <div key={`${selectedShoe?.shoeId._id}ReviewData`}>
                                <div className="row mt-3">
                                <div className="col-5 mx-auto text-center">
                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${selectedShoe?.shoeId.brand.slug}.png`} alt="" />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-8 mx-auto text-center">
                                    <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${selectedShoe?.shoeId.brand.slug}_${selectedShoe?.shoeId.modelSlug}_${selectedShoe?.shoeId.version}_${selectedShoe?.shoeId.colorways[0]?.colorwayCode}.png`} alt="" />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col text-center">
                                    <h2>{selectedShoe?.shoeId.brand.name} {(selectedShoe?.shoeId.model)?.charAt(0).toUpperCase() + selectedShoe?.shoeId.model.slice(1)} {selectedShoe?.shoeId.version}</h2>
                                </div>
                            </div>
                            <div className="row my-2 mx-2">
                                <div className="col text-start">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Rating</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <Rating
                                                    name="rating"
                                                    size="large"
                                                    value={selectedShoeData.rating} 
                                                    onChange={updateShoeData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="row my-2 mx-2">
                                <div className="col text-start">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Description</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <TextField 
                                                id="descriptionTextField" 
                                                label="Description" 
                                                variant="outlined" 
                                                name="description" 
                                                multiline 
                                                sx={{width: '90%'}}
                                                value={selectedShoeData.description}
                                                helperText="The more detail the better, we use this to support your recommendation. e.g I like ___, I don't like ___"
                                                onChange={updateShoeData}  
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            
                            <div className="row my-2 mx-2">
                                <div className="col text-start">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Usage</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                        <FormControl sx={{width: '50%'}}>
                                            <InputLabel id="usageSelector">Distance used for</InputLabel>
                                            <Select
                                            labelId="distanceRanSelect"
                                            id="usageSelector"
                                            value={selectedShoeData.usage} 
                                            label="Distance used for"
                                            name="usage"
                                            onChange={updateShoeData} 
                                            >
                                                <MenuItem value={"0-50km"}>0-50km</MenuItem>
                                                <MenuItem value={"50-100km"}>50-100km</MenuItem>
                                                <MenuItem value={"100-150km"}>100-150km</MenuItem>
                                                <MenuItem value={"150-200km"}>150-200km</MenuItem>
                                                <MenuItem value={">250km"}> More than 250km</MenuItem>
                                            </Select>
                                        </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            : null}
                            
                        </div>
                    </div>
                    <DialogActions className='mx-3 my-3'>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button 
                            onClick={updateUserShoe} 
                            variant='contained'
                            disabled={isProcessing ? true : false}
                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                            >
                            Update
                        </Button>
                    </DialogActions>
                </BootstrapDialog>

            </React.Fragment>
    )
}