var colorOptions = [
    { name: 'beige', hex: '#F5F5DC' },
    { name: 'black', hex: '#000000' },
    { name: 'blue', hex: '#0000FF' },
    { name: 'brown', hex: '#A52A2A' },
    { name: 'green', hex: '#008000' },
    { name: 'grey', hex: '#808080' },
    { name: 'multicolor', hex: null },
    { name: 'navy', hex: '#000080' },
    { name: 'orange', hex: '#FFA500' },
    { name: 'pink', hex: '#FFC0CB' },
    { name: 'purple', hex: '#800080' },
    { name: 'red', hex: '#FF0000' },
    { name: 'white', hex: '#FFFFFF' },
    { name: 'yellow', hex: '#FFFF00' }
]

export { colorOptions }