import { TextField, InputAdornment, IconButton } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

export default function SearchPanel( {search = null, handleChange = null, handleSearch = null}) {

    const handleEnter = (evt) => {
        if (evt.keyCode === 13) {
            handleSearch(evt)
        }
    }

    return (
        <TextField 
            fullWidth
            sx={{
                "& fieldset": { border: 'none' },
            }}
            value={search}
            className="my-0" 
            size="small"
            variant="outlined" 
            label="Search"
            onKeyDown={handleEnter}
            onChange={handleChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment onClick={handleSearch} position="end">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        
        />
    )
}