import { useEffect, useState } from "react"
import axios from "axios"
import Skeleton from '@mui/material/Skeleton';
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import ChipComponent from "../../../../components/Chip/index.jsx";
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import StarRateIcon from '@mui/icons-material/StarRate';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpAlt'
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import SomeIcon from '@mui/icons-material/CheckCircle';
import CushionIcon from '@mui/icons-material/Weekend';
import ExpensiveIcon from '@mui/icons-material/AttachMoney';
import SimilarIcon from '@mui/icons-material/CompareArrows';
import CheaperIcon from '@mui/icons-material/ArrowDownward';
import ResponsiveIcon from '@mui/icons-material/Speed';
import GripIcon from '@mui/icons-material/AllInclusive';
import SameBrandIcon from '@mui/icons-material/CheckCircle';
import DifferentBrandIcon from '@mui/icons-material/SwapHoriz';
import LightModeIcon from '@mui/icons-material/LightMode';
import { shoeTraitMapping, shoeValueMapping } from "../../../../utils/ValueMapping/index.js";
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import ShoeSimilarityChart from "../../../../components/Dashboard/Charts/ShoeSimilarityChart/index.jsx";

export default function SimilarShoesList( { recommendationId = null, shoe = null, preferences = null, requirements = null, shoeColorway = null, setShoeColorway = null, notRec = null }) {

    const { isAlert, setAlertState, setIsAlert, setLoading, requestUrl, trackEvent } = useAppContext()

    var [similarShoes, setSimilarShoes] = useState()

    var [fetchingSimilarShoes, setFetchingSimilarShoes] = useState(false)

    var [similarShoesType, setSimilarShoesType] = useState('similar')

    var navigate = useNavigate()

    const [displayCount, setDisplayCount] = useState(3); // Initial number of shoes to display

    const loadMoreShoes = () => {
        setDisplayCount(prevCount => prevCount + 3); // Load 3 more shoes each time
    };

    useEffect(()=> {
        if (shoe) {
            setFetchingSimilarShoes(true)
            var url = `${requestUrl}/api/recommendations/${recommendationId}/shoes/${shoe?.shoe._id}/similar-shoes?type=${similarShoesType}`
            axios({
                url: url,
                withCredentials: true
            }).then((res) => {
                const sortedShoes = res.data?.similarShoesList?.filter((shoe) => shoe.shoe.latestModel === 'TRUE' && shoe.shoe.discontinued === 'FALSE' ).sort((a, b) => {
                    return b.score - a.score || 
                           parseFloat(b.shoe.averageWebsiteScore).toFixed(1) - parseFloat(a.shoe.averageWebsiteScore).toFixed(1) || 
                           b.shoe.websiteReviews.length - a.shoe.websiteReviews.length;
                });
                setSimilarShoes(sortedShoes || null);
                setFetchingSimilarShoes(false);
            }
            ).catch((err)=> {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setFetchingSimilarShoes(false)
        })
        }
        
    }, [shoe, similarShoesType, recommendationId, requestUrl, setIsAlert])

    const handleClick = (evt, shoe, colorwayIndex) => {
        var url = !notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` : `/dashboard/browse/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`
        trackEvent('View similar shoe')
        setShoeColorway(shoe.shoe.colorways[colorwayIndex])
        navigate(url, {state: {shoe: shoe, preferences: preferences, requirements: requirements  }})
    }

    const setSimilarShoeVariable = (variable) => {
        trackEvent(`Update similar shoes`, { variable: variable })
        setSimilarShoesType(variable)
    }

    if (fetchingSimilarShoes) {
        return (
            <div className="col">
                <div className="row mb-2">
                    <div className="col-auto">
                        <h2>Similar shoes</h2>
                    </div>
                    <div className="col-auto px-0 ">
                        <Tooltip title="Similar shoes to this running shoe, sorted based on suitability and performance (ratings).">
                            <InfoIcon/>
                        </Tooltip>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col">
                        <Chip
                            key="similar"
                            size="small"
                            label="Most similar"
                            icon={<SimilarIcon />}
                            clickable
                            color={similarShoesType === 'similar' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('similar')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="cheaper"
                            size="small"
                            label="Cheaper"
                            icon={<CheaperIcon />}
                            clickable
                            color={similarShoesType === 'cheaper' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('cheaper')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        {shoe && ((shoe.score / shoe.maxScore) * 100).toFixed(0) < 100 && (
                            <Chip
                                key="better-suited"
                                size="small"
                                label="Better suited"
                                icon={<SomeIcon />}
                                clickable
                                color={similarShoesType === 'better suited' ? 'primary' : 'default'}
                                onClick={() => setSimilarShoeVariable('better suited')}
                                style={{ marginRight: '8px', marginBottom: '8px' }}
                            />
                        )}
                        <Chip
                            key="more-cushioned"
                            size="small"
                            label="More cushioned"
                            icon={<CushionIcon />}
                            clickable
                            color={similarShoesType === 'more cushioned' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('more cushioned')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        {shoe && shoe.shoe.surface == 'trail' && (              
                            <Chip
                                key="more-grip"
                                size="small"
                                label="More grip"
                                icon={<GripIcon />}
                                clickable
                                color={similarShoesType === 'more grip' ? 'primary' : 'default'}
                                onClick={() => setSimilarShoeVariable('more grip')}
                                style={{ marginRight: '8px', marginBottom: '8px' }}
                            />
                        )}
                        <Chip
                            key="lighter"
                            size="small"
                            label="Lighter"
                            icon={<FlightTakeoffOutlinedIcon />}
                            clickable
                            color={similarShoesType === 'lighter' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('lighter')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="more-responsive"
                            size="small"
                            label="More responsive"
                            icon={<ResponsiveIcon />}
                            clickable
                            color={similarShoesType === 'more responsive' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('more responsive')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                                               <Chip
                            key="softer-cushion"
                            size="small"
                            label="Softer Cushion"
                            icon={<CushionIcon />}
                            clickable
                            color={similarShoesType === 'softer cushion' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('softer cushion')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="same-brand"
                            size="small"
                            label="Same brand"
                            icon={<SameBrandIcon />}
                            clickable
                            color={similarShoesType === 'same brand' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('same brand')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="different-brand"
                            size="small"
                            label="Different brand"
                            icon={<DifferentBrandIcon />}
                            clickable
                            color={similarShoesType === 'different brand' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('different brand')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="less-cushioned"
                            size="small"
                            label="Less cushioned"
                            icon={<CushionIcon />}
                            clickable
                            color={similarShoesType === 'less cushioned' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('less cushioned')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="more-expensive"
                            size="small"
                            label="More expensive"
                            icon={<ExpensiveIcon />}
                            clickable
                            color={similarShoesType === 'more expensive' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('more expensive')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Skeleton height={80} variant="rounded" animation="wave" />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Skeleton height={80} variant="rounded" animation="wave" />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Skeleton height={80} variant="rounded" animation="wave" />
                    </div>
                </div>
            </div>

        )
    } else if (shoe) {
        return (
            <div className="col">
                <div className="row">
                    <div className="col-auto">
                        <h2>Similar shoes</h2>
                    </div>
                    <div className="col-auto px-0 ">
                        <Tooltip title="Similar shoes in your recommendation, based on price, brand, cushion and width.">
                            <InfoIcon/>
                        </Tooltip>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col">
                        <Chip
                            key="recommended"
                            size="small"
                            label="Most similar"
                            icon={<SimilarIcon />}
                            clickable
                            color={similarShoesType === 'similar' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('similar')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="cheaper"
                            size="small"
                            label="Cheaper"
                            icon={<CheaperIcon />}
                            clickable
                            color={similarShoesType === 'cheaper' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('cheaper')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        {shoe && ((shoe.score / shoe.maxScore) * 100).toFixed(0) < 100 && (
                            <Chip
                                key="better-suited"
                                size="small"
                                label="Better suited"
                                icon={<SomeIcon />}
                                clickable
                                color={similarShoesType === 'better suited' ? 'primary' : 'default'}
                                onClick={() => setSimilarShoeVariable('better suited')}
                                style={{ marginRight: '8px', marginBottom: '8px' }}
                            />
                        )}
                        <Chip
                            key="more-cushioned"
                            size="small"
                            label="More cushioned"
                            icon={<CushionIcon />}
                            clickable
                            color={similarShoesType === 'more cushioned' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('more cushioned')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />   
                        {shoe && shoe.shoe.surface == 'trail' && (              
                            <Chip
                                key="more-grip"
                            size="small"
                            label="More grip"
                            icon={<GripIcon />}
                                clickable
                                color={similarShoesType === 'more grip' ? 'primary' : 'default'}
                                onClick={() => setSimilarShoeVariable('more grip')}
                                style={{ marginRight: '8px', marginBottom: '8px' }}
                            />
                        )}
                        <Chip
                            key="lighter"
                            size="small"
                            label="Lighter"
                            icon={<FlightTakeoffOutlinedIcon />}
                            clickable
                            color={similarShoesType === 'lighter' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('lighter')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="more-responsive"
                            size="small"
                            label="More responsive"
                            icon={<ResponsiveIcon />}
                            clickable
                            color={similarShoesType === 'more responsive' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('more responsive')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                                                <Chip
                            key="softer-cushion"
                            size="small"
                            label="Softer Cushion"
                            icon={<CushionIcon />}
                            clickable
                            color={similarShoesType === 'softer cushion' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('softer cushion')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="same-brand"
                            size="small"
                            label="Same brand"
                            icon={<SameBrandIcon />}
                            clickable
                            color={similarShoesType === 'same brand' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('same brand')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="different-brand"
                            size="small"
                            label="Different brand"
                            icon={<DifferentBrandIcon />}
                            clickable
                            color={similarShoesType === 'different brand' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('different brand')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="less-cushioned"
                            size="small"
                            label="Less cushioned"
                            icon={<CushionIcon />}
                            clickable
                            color={similarShoesType === 'less cushioned' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('less cushioned')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                        <Chip
                            key="more-expensive"
                            size="small"
                            label="More expensive"
                            icon={<ExpensiveIcon />}
                            clickable
                            color={similarShoesType === 'more expensive' ? 'primary' : 'default'}
                            onClick={() => setSimilarShoeVariable('more expensive')}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                    </div>
                </div>
                <div className="row">
                    {similarShoes?.slice(0, displayCount).map((shoe) => {
                        var colorwayIndex = shoe.shoe.colorways?.indexOf(shoe.shoe.colorways.filter((colorway) => colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)))[0]) 

                        if (colorwayIndex == -1) {
                            colorwayIndex = 0
                        }

                        return(
                            <div key={`${shoe.shoe._id}SimilarShoeTile`} className="col-12 col-md-4">
                                <div className="row">
                                    <div className="col  mx-2 my-2 pt-2 pb-3 px-2  show-shoe-tile">
                                    <a href={!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` : `/dashboard/browse/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` }>
                                <Button
                                    disableFocusRipple
                                    disableRipple
                                    style={{ backgroundColor: 'transparent', textTransform: 'none', color: 'inherit'}} 
                                    onClick={(evt) => handleClick(evt, shoe, colorwayIndex)}
                                    key={`${shoe.shoe._id}SimilarShoeCard`}
                                    >
                                        <div className="col">
                                            <div className="row mt-3">
                                                <div className="col-auto mx-auto">
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {(() => {
                                                                    // Group criteria by category, using 'Uncategorized' as a default
                                                                    const criteriaByCategory = shoe.criteria.reduce((acc, criteria) => {
                                                                        const category = requirements[criteria.trait].reasoning[0]?.category || 'Uncategorized';
                                                                        if (!acc[category]) {
                                                                            acc[category] = [];
                                                                        }
                                                                        acc[category].push(criteria);
                                                                        return acc;
                                                                    }, {});

                                                                    // Filter to only include 'requirement' and 'preference' categories
                                                                    const filteredCategories = Object.keys(criteriaByCategory).filter(category => 
                                                                        category === 'requirement' || category === 'preference'
                                                                    );

                                                                    // Render criteria sorted by category
                                                                    return filteredCategories.map((category) => (
                                                                        <div key={`${category}Category`} className="category-group">
                                                                            <h6 className=' mb-0 mt-1'>{category === 'requirement' ? 'Running style' : 'Intended use'}</h6>
                                                                            {criteriaByCategory[category].sort((a, b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                                                                var string = criteria.trait.split(/(?=[A-Z])/);
                                                                                var finalString = shoeTraitMapping[criteria.trait] || string.join(' ').toLowerCase();
                                                                                if (requirements[criteria.trait].reasoning.length > 0) {
                                                                                    return (
                                                                                        <div key={`${criteria.trait}TraitRow`} className="row">
                                                                                            <div className="col">
                                                                                                {criteria.pass === 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {
                                                                                                    shoeValueMapping[criteria.trait]?.[shoe.shoe[criteria.trait]] || 
                                                                                                    (Array.isArray(shoe.shoe[criteria.trait]) ? 
                                                                                                        shoe.shoe[criteria.trait].join(', ') : 
                                                                                                        shoe.shoe[criteria.trait])
                                                                                                } {
                                                                                                    criteria.pass === "FALSE" ? 
                                                                                                    `(Recommended: ${
                                                                                                        requirements[criteria.trait].traits.length > 0 ? 
                                                                                                            requirements[criteria.trait].traits
                                                                                                                .map(trait => shoeValueMapping[criteria.trait]?.[trait] || trait)
                                                                                                                .join(', ') 
                                                                                                            : 
                                                                                                        Object.entries(requirements[criteria.trait].traits)
                                                                                                            .map((elem) => `${elem[0]}: ${shoeValueMapping[criteria.trait]?.[elem[1]] || elem[1]}`)
                                                                                                            .join(', ')
                                                                                                    })` 
                                                                                                    : ""
                                                                                                }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        ))
                                                                })()}
                                                                <a style={{color: 'white', cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => handleClick(evt, 1)}>Learn more</a>
                                                            </span>
                                                        }
                                                    >
                                                        <span>
                                                            <ChipComponent
                                                                icon={
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? <StarRateIcon /> : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <ThumbUpOffAltIcon /> : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                                                    
                                                                }
                                                                label={`${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match`}
                                                                color={
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                                                    
                                                                }
                                                                variant={((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? null : 'outlined'}
                                                                href={`/dashboard/recommendations/${recommendationId}/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}#matching-information`}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                    
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6 mx-auto text-center">
                                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.shoe.brand.slug}.png`} alt="" />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-10 mx-auto text-center">
                                                    <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.shoe.brand.slug}_${shoe?.shoe.modelSlug}_${shoe?.shoe.version}_${shoe?.shoe.colorways[colorwayIndex]?.colorwayCode}.png`} alt="" />
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col text-center">
                                                    <h3 style={{color: 'black'}}>{shoe?.shoe.brand.name} {(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)} {shoe?.shoe.version} (${shoe?.shoe.msrp})</h3>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col text-center">
                                                    <p>
                                                        <Chip
                                                            label={`${shoe?.shoe.cushion} cushion (${shoe?.shoe.stackHeight}mm)`}
                                                            color={similarShoesType === 'more cushioned' || similarShoesType === 'less cushioned' ? 'success' : 'default'}
                                                            style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            size="small"
                                                        />
                                                        <Chip
                                                            label={`$${shoe?.shoe.msrp}`}
                                                            color={similarShoesType === 'cheaper' || similarShoesType === 'more expensive' ? 'success' : 'default'}
                                                            style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            size="small"
                                                        />
                                                        <Chip
                                                            label={`${shoe?.shoe.weight} grams`}
                                                            color={similarShoesType === 'lighter' ? 'success' : 'default'}
                                                            style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            size="small"
                                                        />
                                                        <Chip
                                                            label={`${shoe?.shoe.rocker} rocker`}
                                                            color={similarShoesType === 'more responsive' ? 'success' : 'default'}
                                                            style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            size="small"
                                                        />
                                                        <Chip
                                                            label={`${shoe?.shoe.cushionType} cushion`}
                                                            color={similarShoesType === 'more responsive' ? 'success' : 'default'}
                                                            style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            size="small"
                                                        />
                                                        <Chip
                                                            label={shoe?.shoe.carbonPlate == 'TRUE' ? 'Carbon plate' : 'No Carbon Plate'}
                                                            color={similarShoesType === 'more responsive' ? 'success' : 'default'}
                                                            style={{ marginRight: '8px', marginBottom: '8px' }}
                                                            size="small"
                                                        />
                                                    </p>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </Button>
                                </a>

                                    </div>
                                </div>
                                
                                
                            </div>
                            
                            
                        )
                    })}
                </div>
                {similarShoes && displayCount < similarShoes.length && (
                    <div className="row mt-3">
                        <div className="col text-center">
                            <Button variant="contained" onClick={loadMoreShoes}>
                                Load More
                            </Button>
                        </div>
                    </div>
                )}
                {/* <ShoeSimilarityChart similarShoes={similarShoes} similarShoesType={similarShoesType}/> */}
            </div>
        )
    }
    
}