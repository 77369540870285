import { useEffect, useState } from "react"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import { Skeleton, Tooltip, Button } from "@mui/material"
import axios from "axios"
import { useAppContext } from "../../../../utils/ContextProvider.jsx"
import { useOutletContext } from "react-router-dom"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GeneralTooltip from "../../../../components/ToolTip/index.jsx"

export default function AIComparisonPanel ({ shoeList = null }) {

    var [ isFetching, setIsFetching ] = useState(false)

    var [ response, setResponse ] = useState()

    var { isAlert, setIsAlert, requestUrl, sessionUser, setUpgradeModalOpen } = useAppContext()

    useEffect(() => {
        const delay = 1000; // Delay in milliseconds (e.g., 1000ms = 1 second)
        const timer = setTimeout(() => {
            setIsFetching(true);
            // API call to fetch response
            var listOfShoes = null
            shoeList.filter((shoe) => shoe.shoe.cushion).map((shoe, index) => {
                if (index != -1) {
                    listOfShoes += `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}, `
                } else if (index == -1) {
                    listOfShoes += `and ${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}.`
                }
            })
            axios({
                url: `${requestUrl}/api/integrations/open_ai/comparison`,
                method: 'POST',
                data: {
                    shoeList: listOfShoes
                },
                withCredentials: true
            })
            .then((res) => {
                setResponse(res.data.comparisonSummary);
                setIsFetching(false);
            })
            .catch((err) => {
                var errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: errorMessage
                    };
                });
                setIsFetching(false);
            });
        }, delay);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount or dependencies change
    }, [shoeList, sessionUser]);

        if (isFetching) {
            return (
                <div className="col ask-ai-tile py-3 px-3">
                    <div className="row">
                        <div className="col-auto">
                            <AutoAwesomeIcon />
                        </div>
                        <div className="col">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <h4>Summary:</h4>
                                </div>
                                <div className="col-auto">
                                    <GeneralTooltip title={'Powered by Fittir AI, we fetch information from wear-testers and high quality reviews to simplify the process of research and comparison.'} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
            <div className="col ask-ai-tile py-3 px-3">
                <div className="row ">
                    <div className="col-auto">
                        <AutoAwesomeIcon />
                    </div>
                    <div className="col">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h4>Summary:</h4>
                            </div>
                            <div className="col-auto">
                                <GeneralTooltip  title={'Powered by Fittir AI, we fetch information from wear-testers and high quality reviews to simplify the process of research and comparison.'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>{response}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    
}
