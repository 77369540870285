import Chip from '@mui/material/Chip';

export default function ChipComponent({ color = 'success', label = null, variant = null, icon = null, href = null, size = 'large'}) {
    return (
        <Chip
            size={size}
            icon={icon}
            color={color}
            label={label}
            variant={variant === null ? "" : "outlined"}
            href={href}
            clickable={href !== null ? true : false}
            style={{cursor: 'default'}}
        />
    )
}

