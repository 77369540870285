import SingleBedIcon from '@mui/icons-material/SingleBed';
import ChairIcon from '@mui/icons-material/Chair';
import ConstructionIcon from '@mui/icons-material/Construction';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Alert, Chip, Rating } from '@mui/material';
import { useEffect, useState } from 'react';
import { shoeTraitGroupings } from '../../../../utils/ShoeTraitGroupings';
import { useAppContext } from '../../../../utils/ContextProvider.jsx';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GeneralTooltip from '../../../../components/ToolTip';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import TraitSummaryCard from './TraitSummaryCard';
import BedIcon from '@mui/icons-material/Bed';
import CompressIcon from '@mui/icons-material/Compress';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import BuildIcon from '@mui/icons-material/Build';
import IceSkatingIcon from '@mui/icons-material/IceSkating';
import AppsIcon from '@mui/icons-material/Apps';

// Filter reviews by trait
var filterWebsiteReviews = (trait, reviews) => {
    var prosList = [];
    var consList = [];

    // Iterate over the reviews and filter pros and cons based on the given trait
    reviews.forEach((review) => {
        // Filter the pros list where the group matches the given trait
        const pros = review.prosList.filter(item => item.group === trait);
        prosList = prosList.concat(pros);  // Accumulate the filtered pros

        // Filter the cons list where the group matches the given trait
        const cons = review.consList.filter(item => item.group === trait);
        consList = consList.concat(cons);  // Accumulate the filtered cons
    });

    // Return the filtered pros and cons grouped by the trait
    return {
        pros: prosList,
        cons: consList
    };
};

const getSortedSubGroupsByType = (sortedReviews) => {
    const proSubGroupCounts = {};
    const conSubGroupCounts = {};

    // Helper function to generate a composite key from sub_group and group
    const generateKey = (subGroup, group) => `${subGroup}|${group}`;

    // Accumulate counts for each combination of subGroup and group in pros and cons
    Object.keys(sortedReviews).forEach(shoeTrait => {
        const pros = sortedReviews[shoeTrait]?.pros || [];
        const cons = sortedReviews[shoeTrait]?.cons || [];

        pros.forEach(item => {
            const subGroup = item.sub_group;
            const group = item.group;
            if (subGroup) {
                const key = generateKey(subGroup, group);
                if (!proSubGroupCounts[key]) {
                    proSubGroupCounts[key] = { subGroup, group, count: 0 };
                }
                proSubGroupCounts[key].count += 1;
            }
        });

        cons.forEach(item => {
            const subGroup = item.sub_group;
            const group = item.group;
            if (subGroup) {
                const key = generateKey(subGroup, group);
                if (!conSubGroupCounts[key]) {
                    conSubGroupCounts[key] = { subGroup, group, count: 0 };
                }
                conSubGroupCounts[key].count += 1;
            }
        });
    });

    // Convert to arrays and sort by frequency (most frequent first)
    const sortedPros = Object.values(proSubGroupCounts).sort((a, b) => b.count - a.count);
    const sortedCons = Object.values(conSubGroupCounts).sort((a, b) => b.count - a.count);

    return { sortedPros, sortedCons };
};



// Get sorted sub-groups by type
const getSortedsub_groupsByType = (sortedReviews) => {
    const sub_groupCounts = {};

    Object.keys(sortedReviews).forEach(shoeTrait => {
        const pros = sortedReviews[shoeTrait]?.pros || [];
        const cons = sortedReviews[shoeTrait]?.cons || [];

        pros.forEach(item => {
            const sub_group = item.sub_group;
            if (sub_group) {
                sub_groupCounts[sub_group] = (sub_groupCounts[sub_group] || 0) + 1;
            }
        });

        cons.forEach(item => {
            const sub_group = item.sub_group;
            if (sub_group) {
                sub_groupCounts[sub_group] = (sub_groupCounts[sub_group] || 0) + 1;
            }
        });
    });

    return Object.entries(sub_groupCounts).sort((a, b) => b[1] - a[1]);
};

export default function CompareShoeAnalysisSection({ link = null, websiteReviews = null, open = null, setOpen = null }) {

    const [isFiltering, setIsFiltering] = useState(true);
    const [sortedReviews, setSortedReviews] = useState({});
    const [selectedsub_group, setSelectedsub_group] = useState({});
    const { sessionUser, setUpgradeModalOpen, trackEvent } = useAppContext();
    const [sortedsub_groups, setSortedsub_groups] = useState([])

    const [sortedSubGroups, setSortedSubGroups] = useState({ sortedPros: [], sortedCons: [] });

    const [openVariable, setOpenVariable] = useState()

    // Sorting and storing reviews by shoe trait
    useEffect(() => {
        if (websiteReviews) {
            setIsFiltering(true)
            const sortedReviews = {};
            for (const shoeTrait of shoeTraitGroupings) {
                const output = filterWebsiteReviews(shoeTrait, websiteReviews);
                sortedReviews[shoeTrait] = output;
            }
            setIsFiltering(false);
            setSortedReviews(sortedReviews);

            // Update sorted sub-groups
            const sortedsub_groups = getSortedsub_groupsByType(sortedReviews);
            setSortedsub_groups(sortedsub_groups);

            const sortedGroups = getSortedSubGroupsByType(sortedReviews);
            setSortedSubGroups(sortedGroups);
        }
    }, [websiteReviews, sessionUser]);


    const handleViewTrait = (shoeTrait, sub_group) => {
        // Open a new tab with the provided link
            if (link) {
                // Set the localStorage item before opening the new window
                localStorage.setItem('openAccordian', shoeTrait);
                localStorage.setItem('openAccordianSubGroup', sub_group);
                const newWindow = window.open(link, '_blank');
                if (newWindow) {
                    newWindow.onload = () => {
                        // Ensure the localStorage item is set before any further actions
                        localStorage.setItem('openAccordian', shoeTrait);
                        localStorage.setItem('openAccordianSubGroup', sub_group);
                    };
                }
            }

    };
    

    if (websiteReviews && websiteReviews.length > 0 && !isFiltering) {
        return (
            <div className="col">
                <div className="row justify-content-center">
                    <div className="col-11 col-md-6 my-1 text-center">
                        <Alert severity='success' icon={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >
                            <InsertEmoticonIcon /> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedPros[0]?.group, sortedSubGroups.sortedPros[0]?.subGroup )}>{sortedSubGroups.sortedPros[0]?.group}: {sortedSubGroups.sortedPros[0]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedPros[1]?.group, sortedSubGroups.sortedPros[1]?.subGroup )}>{sortedSubGroups.sortedPros[1]?.group}: {sortedSubGroups.sortedPros[1]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedPros[2]?.group, sortedSubGroups.sortedPros[2]?.subGroup )}>{sortedSubGroups.sortedPros[2]?.group}: {sortedSubGroups.sortedPros[2]?.subGroup}</span> <br></br>
                        </Alert>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-11 col-md-6 my-1 text-center">
                        <Alert severity='warning' icon={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >
                            <SentimentVeryDissatisfiedIcon/> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedCons[0]?.group, sortedSubGroups.sortedCons[0]?.subGroup )}>{sortedSubGroups.sortedCons[0]?.group}: {sortedSubGroups.sortedCons[0]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedCons[1]?.group, sortedSubGroups.sortedCons[1]?.subGroup )}>{sortedSubGroups.sortedCons[1]?.group}: {sortedSubGroups.sortedCons[1]?.subGroup}</span> <br></br>
                            - <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={(evt) => handleViewTrait(sortedSubGroups.sortedCons[2]?.group, sortedSubGroups.sortedCons[2]?.subGroup )}>{sortedSubGroups.sortedCons[2]?.group}: {sortedSubGroups.sortedCons[2]?.subGroup}</span> <br></br>
                        </Alert>
                    </div>
                </div>

            </div>
        );
    }

    return null;
}