import { Avatar, } from "@mui/material"
import ChipComponent from "../../../Chip"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import { useOutletContext } from "react-router-dom"
import YouTubeIcon from '@mui/icons-material/YouTube';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export default function ReviewCard ({ review = null, type = null }) {

    var [user, userShoes] = useOutletContext()

    return (
        <div className="row review-card pt-2 my-2">
            <div className="col px-3 py-3">
                <div className="row">
                    <div className="px-2 col-auto">
                        {type === 'amateur' ?
                        <Avatar 
                            alt={review?.userId?.name || ''} 
                                src={review?.userId?.profilePicture}
                            />
                        : type == 'wear-tester' &&
                        review.type === 'youtube' ? <YouTubeIcon /> : <LibraryBooksIcon />
                        }
                    </div>
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto text-start">
                                <h4 className="my-0">{type === 'amateur' ? review.userId.name : review.reviewer}</h4>
                            </div>
                            { type === 'amateur' && review.userId.runningProfile.gender === user.runningProfile.gender && review.userId.runningProfile.footStrike === user.runningProfile.footStrike && review.userId.runningProfile.footWidth === user.runningProfile.footWidth && review.userId.runningProfile.pronation === user.runningProfile.pronation ?
                            <div className="col-auto px-1">
                                <ChipComponent 
                                    icon={<ThumbUpOffAltIcon />}
                                    variant={'outlined'}
                                    color="success"
                                    size="small"
                                    label={"Similar running profile"}
                                />
                            </div>
                            : null}
                        </div>

                        {type === 'amateur' &&
                        <div className="row mt-2">
                            <div className="col text-start">
                                <p><i>{review.userId.runningProfile.gender}, {review.userId.runningProfile.pronation} pronation, {review.userId.runningProfile.footWidth} foot, {review.userId.runningProfile.archType} arch, {review.userId.runningProfile.footStrike}  </i></p>
                            </div>
                        </div>
                        }
                        
                        
                        <div className="row mt-2">
                            <div className="col text-start">
                                <p>{type === 'amateur' ? review.description : review.summary}</p>
                            </div>
                        </div>

                        {type === 'amateur' &&
                        <div className="row mt-2">
                            <div className="col text-start">
                                <p><i>Used for: {review.usage}</i></p>
                            </div>
                        </div>
                        }
                        
                        {type === 'amateur' && review.rating >= 4 &&
                        <div className="row">
                            <div className="col text-start">
                                <ChipComponent 
                                    icon={<ThumbUpOffAltIcon />}
                                    variant={'outlined'}
                                    color="success"
                                    size="small"
                                    label={"Recommended"}
                                />
                            </div>
                        </div>
                        }
                        
                    </div>
                    <div className="col-auto text-end">
                        <h4 className="my-0 pe-4">{type === 'amateur' ? `${review.rating}/5` : (review.score ? `${(parseFloat(review.score).toFixed(2) / 2)}/5` : 'no score')}</h4>
                    </div>
                </div>       
            </div>
        </div>
    )
} 