import Button from "@mui/material/Button";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneIcon from '@mui/icons-material/Done';
import { useAppContext } from "../../../../../utils/ContextProvider.jsx";
import { createEvent } from "../../../../../utils/CreateEvent/index.js";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ColorLensIcon from '@mui/icons-material/ColorLens';

export default function PartnerDealCard ({ partnerDeal = null, shoe = null, colorway = null, recommendationId = null, user = null }) {

    var { trackEvent } = useAppContext()


    const handleClick = () => {
        trackEvent('View partner deal', {partner: partnerDeal?.partner.name, shoeName: `${shoe?.brand.name} ${shoe?.model} ${shoe?.version}`})
        createEvent({
            action: 'view_partner_deal',
            category: 'interaction',
            data: {
                partner: partnerDeal?.partner.name,
                shoeId: shoe?._id,
                colorwayId: colorway?._id,
                recommendationId: recommendationId,
                partnerDeal: partnerDeal,
                price: partnerDeal?.price,
                specialPrice: partnerDeal?.specialPrice ? partnerDeal?.specialPrice : null
            }
        })
    }

    return (
        <div className="row my-2">
            <div className="col text-center py-4 px-1" style={{border: `1px solid ${partnerDeal?.partner.primary}`, borderRadius: '1rem'}}>

                <div className="row">
                    <div className="col-7 mx-auto">
                        <img className="img img-fluid" src={`/logos/partners/${partnerDeal?.partner.slug}.svg`} alt="" />      
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        {partnerDeal.specialPrice ? (
                            <h1 className="my-0 px-0" style={{color: partnerDeal?.partner.primary, fontSize: '2rem'}}>
                                ${partnerDeal?.specialPrice.toFixed(2)}  
                                <span style={{color: '#687393', fontSize: '0.9rem', textDecoration: 'line-through'}}>
                                    ${partnerDeal?.price.toFixed(2)}
                                </span>
                            </h1>
                        ) : partnerDeal.partner.coupon ? (
                            <h1 className="my-0 px-0" style={{color: partnerDeal?.partner.primary, fontSize: '2rem'}}>
                                ${(partnerDeal.price * (1 - partnerDeal.partner.coupon.discountPercent/100)).toFixed(2)}
                                <span style={{color: '#687393', fontSize: '0.9rem', textDecoration: 'line-through'}}>
                                    ${partnerDeal?.price.toFixed(2)}
                                </span>
                                <div style={{fontSize: '0.9rem'}}>
                                    Use code: {partnerDeal.partner.coupon.code}
                                </div>
                            </h1>
                        ) : null}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        {partnerDeal?.sizes?.length > 0 ?
                        partnerDeal.sizes.some(sizeObj => sizeObj.size === user?.runningProfile.shoeSize && sizeObj.stock > 0) ?
                        <p><AspectRatioIcon /> Stock in your size ({user?.runningProfile.shoeSize} US)</p> :
                        <p><AspectRatioIcon /> Sizes available: {partnerDeal?.sizes?.filter(sizeObj => sizeObj.stock > 0).map(sizeObj => sizeObj.size).sort((a, b) => a - b).join(', ')} (US)</p>
                        : <p><AspectRatioIcon /> Sizes available: check website for sizes available</p>
                        }
                        <p><ColorLensIcon /> Sells this color in your required width ({user?.runningProfile.footWidth})</p>
                        {partnerDeal.partner.offers.map((offer) => {
                            return (
                                <p><DoneIcon /> {offer}</p>
                            )
                        })}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <Button
                            variant="contained"
                            endIcon={<ArrowRightAltIcon/>}
                            size='large'
                            style={{background: `${partnerDeal?.partner.primary}`}}
                            href={partnerDeal.affiliateLink || `${partnerDeal?.partner.baseUrl}${partnerDeal?.urlKey}${partnerDeal?.partner.referralUrlString}`}
                            target="_blank"
                            onClick={handleClick}
                            >
                            Visit website
                        </Button>
                    </div>
                </div>

            </div>  
        </div>
    )
}