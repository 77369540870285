import { useAppContext } from "../../../utils/ContextProvider.jsx"
import Loader from "../../../components/Loader/index.jsx"
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx"
import { useEffect, useState } from "react"
import axios from "axios"
import Button from "@mui/material/Button"
import ChipComponent from "../../../components/Chip/index.jsx"
import { useNavigate,Link } from "react-router-dom"
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined"
import EditShoeModal from "../../../components/Modal/EditShoeModal/index.jsx"
import AddShoeModal from "../../../components/Modal/AddShoeModal/index.jsx"
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpAlt'
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu"
import MenuItem from  "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined.js"
import { Tooltip } from "@mui/material"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import * as Sentry from "@sentry/react";
import { ScrollRestoration } from "react-router-dom"
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import StarRateIcon from '@mui/icons-material/StarRate';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import { useOutletContext } from 'react-router-dom';

export default function ShoeLockerPage () {

    const navigate = useNavigate()

    var buttons = [
        {
            text: 'Add existing shoe',
            function: (evt) => setAddShoeModalOpen(true)
        }

    ]


    var {isLoading, setLoading, requestUrl, setIsAlert, isAlert, trackEvent, setProcessing, setProcessing } = useAppContext()

    var [allShoes, setAllShoes] = useState()

    var [user] = useOutletContext()

    var [pastShoes, setPastShoes] = useState()

    var [generalRec, setGeneralRec] = useState()

    var [currentShoes, setCurrentShoes] = useState()

    var [selectedShoe, setSelectedShoe] = useState()

    var [selectedShoeData, setSelectedShoeData] = useState({
        rating: 0,
        usage: '0-50km',
        description: ''
    })

    var [selectedShoeIsCurrent, setSelectedShoeIsCurrent] = useState()

    var [userShoes, setUserShoes] = useState()

    const [addShoeModalOpen, setAddShoeModalOpen] = useState()
    
    const [editShoeModalOpen, setEditShoeModalOpen] = useState()

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        setLoading(true);
    
        const fetchUserShoes = axios({
            url: `${requestUrl}/api/userShoes`,
            method: "GET",
            withCredentials: true
        });
    
        const fetchGeneralRecommendation = axios({
            url: `${requestUrl}/api/users/generalRecommendation`,
            method: "GET",
            withCredentials: true
        });
    
        // Use Promise.all to fetch both requests concurrently
        Promise.all([fetchUserShoes, fetchGeneralRecommendation])
            .then(([userShoesRes, generalRecRes]) => {

                setUserShoes(userShoesRes.data.userShoes)
                // Handle user shoes response
                const currentShoes = userShoesRes.data.userShoes?.filter((shoe) => shoe.current);
                setCurrentShoes(currentShoes);
    
                const pastShoes = userShoesRes.data.userShoes?.filter((shoe) => !shoe.current);
                setPastShoes(pastShoes);
    
                // Handle general recommendation response
                setGeneralRec(generalRecRes.data);
    
                setLoading(false);
            })
            .catch((err) => {
                Sentry.captureException(err);
    
                const errorMessage = err.response?.data?.err?.message || "Whoops, something went wrong";
                setIsAlert((pageData) => ({
                    ...pageData,
                    error: errorMessage
                }));
    
                setLoading(false);
            });
    }, [requestUrl]);
    

    const handleEditShoeModalOpen = (evt, shoe) => {
        setSelectedShoe(shoe)
        setSelectedShoeData(shoe.shoeReviewId)
        setSelectedShoeIsCurrent(shoe.current)
        setEditShoeModalOpen(true)
    }

    const retireShoe = (evt, shoe) => {
        var url = `${requestUrl}/api/userShoes/${shoe._id}`
        axios({
            url: url,
            method: "PUT",
            data: {
                shoeId: shoe.shoeId._id,
                rating: shoe.shoeReviewId.rating,
                description: shoe.shoeReviewId.description,
                usage: shoe.shoeReviewId.usage,
                current: false
            },
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Update existing shoe', {shoeName: `${selectedShoe?.shoeId.brand.name} ${selectedShoe?.shoeId.mode} ${selectedShoe?.shoeId.version}`})
            setProcessing(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Current shoe updated ✅`
                }
            })
            navigate(0)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }

    const unRetireShoe = (evt, shoe) => {
        var url = `${requestUrl}/api/userShoes/${shoe._id}`
        axios({
            url: url,
            method: "PUT",
            data: {
                shoeId: shoe.shoeId._id,
                rating: shoe.shoeReviewId.rating,
                description: shoe.shoeReviewId.description,
                usage: shoe.shoeReviewId.usage,
                current: true
            },
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Update existing shoe', {shoeName: `${selectedShoe?.shoeId.brand.name} ${selectedShoe?.shoeId.mode} ${selectedShoe?.shoeId.version}`})
            setProcessing(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Current shoe updated ✅`
                }
            })
            navigate(0)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }
    
    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '100vh'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader
                    heading={`Shoe locker`}
                    subHeading={'Detailed information about your current, and past running shoes. This is used to tailor and inform your recommendations.'}
                    buttons={buttons}
                />
                <div className="container-fluid dashboard-body pb-5" id="recommendationsArea">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-10 mx-auto">
                                <div className="row justify-content-between align-items-center mt-2">
                            <div className="col-11 col-md-8 mx-auto mx-md-0">
                                <div className="row">
                                    <div className="col text-center text-md-start">
                                        <h2>Current shoes ({currentShoes?.length})</h2>
                                    </div>
                                </div>
                            </div>
                                
                                </div>
                                <div className="row">
                                    {currentShoes?.map((shoe, index) => {
                                        var generalRecShoe = generalRec?.[`${shoe.shoeId.surface}Recommendation`]?.shoes.filter((recShoe) => recShoe.shoe._id == shoe?.shoeId._id)[0]
                                        return (
                                            <div className="col-11 col-md-4 mx-auto mx-md-0 px-2">
                                                <div key={`${shoe?.shoeId._id}CurrentShoeTile`} className="col my-2 pt-2 pb-3 px-2  show-shoe-tile">
                                                    <div className="row justify-content-end align-items-center">
                                                        <div className="col mx-2">
                                                            <div className="row">
                                                                    <div className="col-auto my-1">
                                                                        <ChipComponent 
                                                                            color={"primary"}
                                                                            label={"Current shoe"}
                                                                            size={"small"}
                                                                            />
                                                                    </div>
                                                                    {
                                                                        shoe.shoeId.discontinued === 'TRUE' ?
                                                                        <div className="col-auto my-1">
                                                                        <ChipComponent 
                                                                            color={"default"}
                                                                            label={"Discontinued"}
                                                                            size={"small"}
                                                                            />
                                                                    </div>
                                                                        : null
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        <div className="col-auto mx-3">
                                                            <div className="row">
                                                                <div className="col-auto px-0">
                                                                    <Tooltip title={'Update'}>
                                                                        <IconButton onClick={(evt) => handleEditShoeModalOpen(evt, shoe)}>
                                                                            <EditOutlinedIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <Tooltip title={'Retire'}>
                                                                        <IconButton onClick={(evt)=> retireShoe(evt, shoe)}>
                                                                            <ArchiveOutlinedIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {generalRecShoe ?
                                                    <div className="row mt-3">
                                                    <div className="col-auto mx-auto">
                                                        <Tooltip 
                                                            arrow 
                                                            disableFocusListener 
                                                            enterTouchDelay={0} 
                                                            leaveTouchDelay={5000}
                                                            title={
                                                                <span>
                                                                    {generalRecShoe?.criteria.sort((a, b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                                                        var string = criteria.trait.split(/(?=[A-Z])/);
                                                                        var finalString = string.join(' ').toLowerCase();
                                                                        if (generalRec?.[`${shoe.shoeId.surface}Recommendation`].requirements[criteria.trait].reasoning.length > 0) {
                                                                            return (
                                                                                <div key={`${criteria.trait}TraitRow`} className="row">
                                                                                    <div className="col">
                                                                                        {criteria.pass === 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {Array.isArray(generalRecShoe.shoe[criteria.trait]) ? generalRecShoe.shoe[criteria.trait].join(', ') : generalRecShoe.shoe[criteria.trait]}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })}
                                                                    <a style={{color: 'white', cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => navigate(`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/shoes/${shoe.shoeId.brand.slug}_${shoe.shoeId.model}_${shoe.shoeId.version}`, {state: {shoe: generalRecShoe, preferences: generalRec?.[`${shoe.shoeId.surface}Recommendation`].preferences, requirements: generalRec?.[`${shoe.shoeId.surface}Recommendation`].requirements, shoeColorway: shoe.shoeId.colorways[0], tabIndex: 1 }})}>Learn more</a>
                                                                </span>
                                                            }
                                                        >
                                                            <span>
                                                                <ChipComponent
                                                                    icon={
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                                                    }
                                                                    label={ 
                                                                            ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 95 ? 'Great profile match' : 
                                                                            ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 85 ? 'Average profile match' : 
                                                                            ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 75 ? 'Average profile match' : 'Poor profile match' 
                                                                            }
                                                                    color={
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                                                    }
                                                                    variant={(generalRecShoe?.score / generalRecShoe?.maxScore) < 1.0 ? 'outlined' : null}
                                                                    href={`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/${generalRecShoe?.shoe.brand.slug}_${generalRecShoe?.shoe.model}_${generalRecShoe?.shoe.version}#matching-information`}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                    : 
                                                    // show shoe as discontinued if not in recommendation
                                                    <div className="row mt-3">
                                                    <div className="col-auto mx-auto">
                                                        <ChipComponent
                                                                    icon={
                                                                        <CancelIcon />
                                                                    }
                                                                    label={ 
                                                                            'Update profile for scoring'
                                                                            }
                                                                    color={
                                                                        'default'
                                                                    }
                                        
                                                                />
                                                    </div>
                                                </div>
                                                    }
                                                    
                                                    <Button
                                                    disableFocusRipple
                                                    disableRipple
                                                    style={{ backgroundColor: 'transparent', textTransform: 'none', color: 'inherit'}} 
                                                    onClick={(evt) => navigate(`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/shoes/${shoe.shoeId.brand.slug}_${shoe.shoeId.model}_${shoe.shoeId.version}`, {state: {shoe: generalRecShoe, preferences: generalRec?.[`${shoe.shoeId.surface}Recommendation`].preferences, requirements: generalRec?.[`${shoe.shoeId.surface}Recommendation`].requirements, shoeColorway: shoe.shoeId.colorways[0], tabIndex: 0 }})}
                                                    key={`${shoe?.shoeId._id}SimilarShoeCard`}
                                                    >
                                                        <div className="col">

                                                        <div className="row justify-content-center">
                                                            </div>

                                                            <div className="row mt-3">
                                                                <div className="col-6 mx-auto text-center">
                                                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoeId.brand?.slug}.png`} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-10 mx-auto text-center">
                                                                    <a href={`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/shoes/${shoe.shoeId.brand.slug}_${shoe.shoeId.model}_${shoe.shoeId.version}`}><img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoeId.brand?.slug}_${shoe?.shoeId.modelSlug}_${shoe?.shoeId.version}_${shoe?.shoeId.colorways[0]?.colorwayCode}.png`} alt="" /></a>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col text-center">
                                                                    <h3>{shoe.shoeId.brand?.name} {(shoe?.shoeId.model)?.charAt(0).toUpperCase() + shoe?.shoeId.model.slice(1)} {shoe?.shoeId.version}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="row justify-content-between align-items-center mt-5">
                                    <div className="col-11 col-md-8 mx-auto mx-md-0">
                                        <div className="row">
                                            <div className="col text-center text-md-start">
                                                <h2>Past shoes ({pastShoes?.length})</h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row justify-content-start">
                                    {pastShoes?.map((shoe, index) => {
                                        var generalRecShoe = generalRec?.[`${shoe.shoeId.surface}Recommendation`]?.shoes.filter((recShoe) => recShoe.shoe._id == shoe?.shoeId._id)[0]
                                        return (
                                            <div className="col-6 col-md-4 px-2">
                                                <div key={`${shoe?.shoeId._id}SimilarShoeTile`} className="col my-2 pt-2 pb-3 px-2  show-shoe-tile">
                                                <div className="row justify-content-end align-items-center">

                                                        <div className="col mx-2">
                                                            <div className="row">
                                                                <div className="col-auto my-1">
                                                                    <ChipComponent 
                                                                        color={"default"}
                                                                        label={"Past shoe"}
                                                                        size={"small"}
                                                                        />
                                                                </div>
                                                                {
                                                                    shoe.shoeId.discontinued === 'TRUE' ?
                                                                    <div className="col-auto my-1">
                                                                    <ChipComponent 
                                                                        color={"default"}
                                                                        label={"Discontinued"}
                                                                        size={"small"}
                                                                        />
                                                                </div>
                                                                    : null
                                                                }
                                                                
                                                            </div>

                                                        </div>

                                                        <div className="col-auto mx-3">
                                                            <div className="row">
                                                                <div className="col-auto px-0">
                                                                    <Tooltip title={'Update'}>
                                                                        <IconButton onClick={(evt) => handleEditShoeModalOpen(evt, shoe)} >
                                                                            <EditOutlinedIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <Tooltip title={'Reinstate'}>
                                                                        <IconButton onClick={(evt)=> unRetireShoe(evt, shoe)}>
                                                                            <UnarchiveOutlinedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {generalRecShoe ?
                                                    <div className="row mt-3">
                                                    <div className="col-auto mx-auto">
                                                        <Tooltip 
                                                            arrow 
                                                            disableFocusListener 
                                                            enterTouchDelay={0} 
                                                            leaveTouchDelay={5000}
                                                            title={
                                                                <span>
                                                                    {generalRecShoe?.criteria.sort((a, b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                                                        var string = criteria.trait.split(/(?=[A-Z])/);
                                                                        var finalString = string.join(' ').toLowerCase();
                                                                        if (generalRec?.[`${shoe.shoeId.surface}Recommendation`].requirements[criteria.trait].reasoning.length > 0) {
                                                                            return (
                                                                                <div key={`${criteria.trait}TraitRow`} className="row">
                                                                                    <div className="col">
                                                                                        {criteria.pass === 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {Array.isArray(generalRecShoe.shoe[criteria.trait]) ? generalRecShoe.shoe[criteria.trait].join(', ') : generalRecShoe.shoe[criteria.trait]}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })}
                                                                    <a style={{color: 'white', cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => navigate(`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/shoes/${shoe.shoeId.brand.slug}_${shoe.shoeId.model}_${shoe.shoeId.version}`, {state: {shoe: generalRecShoe, preferences: generalRec?.[`${shoe.shoeId.surface}Recommendation`].preferences, requirements: generalRec?.[`${shoe.shoeId.surface}Recommendation`].requirements, shoeColorway: shoe.shoeId.colorways[0], tabIndex: 1 }})}>Learn more</a>
                                                                </span>
                                                            }
                                                        >
                                                            <span>
                                                                <ChipComponent
                                                                    icon={
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                                                    }
                                                                    label={ 
                                                                            ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 95 ? 'Great profile match' : 
                                                                            ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 85 ? 'Average profile match' : 
                                                                            ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 75 ? 'Average profile match' : 'Poor profile match' 
                                                                            }
                                                                    color={
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                                                        ((generalRecShoe?.score / generalRecShoe?.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                                                    }
                                                                    variant={(generalRecShoe?.score / generalRecShoe?.maxScore) < 1.0 ? 'outlined' : null}
                                                                    href={`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/${generalRecShoe?.shoe.brand.slug}_${generalRecShoe?.shoe.model}_${generalRecShoe?.shoe.version}#matching-information`}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                    : 
                                                    // show shoe as discontinued if not in recommendation
                                                    <div className="row mt-3">
                                                    <div className="col-auto mx-auto">
                                                        <ChipComponent
                                                                    icon={
                                                                        <CancelIcon />
                                                                    }
                                                                    label={ 
                                                                            'Update profile for scoring'
                                                                            }
                                                                    color={
                                                                        'default'
                                                                    }
                                        
                                                                />
                                                    </div>
                                                </div>
                                                    }
                                                        <Button
                                                        disableFocusRipple
                                                        disableRipple
                                                        style={{ backgroundColor: 'transparent', textTransform: 'none', color: 'inherit'}} 
                                                        onClick={(evt) => navigate(`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/shoes/${shoe.shoeId.brand.slug}_${shoe.shoeId.model}_${shoe.shoeId.version}`, {state: {shoe: generalRecShoe, preferences: generalRec?.[`${shoe.shoeId.surface}Recommendation`].preferences, requirements: generalRec?.[`${shoe.shoeId.surface}Recommendation`].requirements, shoeColorway: shoe.shoeId.colorways[0], tabIndex: 0 }})}
                                                        key={`${shoe?.shoeId._id}SimilarShoeCard`}
                                                        >
                                                            <div className="col">
                                                                <div className="row mt-3">
                                                                    <div className="col-6 mx-auto text-center">
                                                                        <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoeId.brand?.slug}.png`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-10 mx-auto text-center">
                                                                    <a href={`/dashboard/browse/${generalRec?.[`${shoe.shoeId.surface}Recommendation`]._id}/shoes/${shoe.shoeId.brand.slug}_${shoe.shoeId.model}_${shoe.shoeId.version}`}><img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoeId.brand?.slug}_${shoe?.shoeId.modelSlug}_${shoe?.shoeId.version}_${shoe?.shoeId.colorways[0]?.colorwayCode}.png`} alt="" /></a>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col text-center">
                                                                        <h3>{shoe.shoeId.brand?.name} {(shoe?.shoeId.model)?.charAt(0).toUpperCase() + shoe?.shoeId.model.slice(1)} {shoe?.shoeId.version}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <EditShoeModal heading={'Update current shoe'} setOpenState={setEditShoeModalOpen} openState={editShoeModalOpen} selectedShoe={selectedShoe} selectedShoeIsCurrent={selectedShoeIsCurrent} selectedShoeData={selectedShoeData} setSelectedShoe={setSelectedShoe} setSelectedShoeData={setSelectedShoeData} setSelectedShoeIsCurrent={setSelectedShoeIsCurrent}/>
                <AddShoeModal heading={'Update current shoe'} setOpenState={setAddShoeModalOpen} user={user} userShoes={userShoes} openState={addShoeModalOpen} />
            </div>
            
    )
    }
}