import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"
import { runningProfileOptions } from "../../../../../utils/ProfileOptions"

export default function ArchKnownPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var name = ""
        var value = ""
        if (!evt.target.value) {
            if (evt.target.parentNode.value) {
                value = evt.target.parentNode.value
                name = evt.target.parentNode.name
            } else {
                value = evt.target.parentNode.parentNode.value
                name = evt.target.parentNode.parentNode.name
            }
            
        } else {
            value = evt.target.value
            name = evt.target.name
        }
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        var newRunningProfileObject = {...runningProfileObject, [name]: value}
        var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
        localStorage.setItem("runningProfile", newRunningProfileObjectString)
        navigate('/shoe-finder/running-profile/foot-width')
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"What type of arch do you have?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={runningProfileOptions.archType}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}