import Modal from "../../../Modal"
import * as React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { shoeTraitMapping } from '../../../../utils/ValueMapping';

export default function RecommendationReasoningModal({openState = null, setOpenState = null, recommendation = null, variable = null, openVariable = null, maxScore = null}) {

    // Group requirements by category
    const requirementsByCategory = recommendation?.requirements ? Object.entries(recommendation.requirements).reduce((acc, [key, value]) => {
        const category = Array.isArray(value.reasoning) && value.reasoning.length > 0 ? value.reasoning[0].category : 'Uncategorized';
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push([key, value]);
        return acc;
    }, {}) : {};

    // Filter to only include 'requirement' and 'preference' categories
    const filteredCategories = Object.keys(requirementsByCategory).filter(category => 
        category === 'requirement' || category === 'preference'
    );

    const content = 
        <div className="col">
            <div className="row">
                <div className="col">
                    <p>Our recommendation process has been refined based on professional input and feedback from 1000's of runners. Based on your needs and intended use, the following criteria have been used to generate your {recommendation?.preferences?.shoeUsage} {recommendation?.preferences?.shoeSurface} recommendation.</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {filteredCategories.map((category) => (
                        <div key={`${category}Category`} className="category-group">
                            <h3 className='mb-3 mt-3'>{category === 'requirement' ? 'Based on your running style and needs' : 'Based on your intended use'}</h3>
                            {requirementsByCategory[category].sort((a, b) => b[1].priorityScore - a[1].priorityScore).map(([key, value]) => {
                                var string = key.split(/(?=[A-Z])/).join(' ').toLowerCase();
                                var finalTitleString = shoeTraitMapping[key] || string
                                if (Array.isArray(value.reasoning) && value.reasoning.length > 0) {
                                    var FinalDescriptionString = "";
                                    for (let j = 0; j < value.reasoning.length; j++) {
                                        FinalDescriptionString += `${value.reasoning[j].description}. `;
                                    }
                                    return (
                                        <div className="row my-1" key={`${finalTitleString}AccordianRow`}>
                                            <div className="col">
                                                <Accordion defaultExpanded={openVariable == key}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`${finalTitleString}ReasoningContent`}
                                                        id={`${finalTitleString}Header`}
                                                    >
                                                        <div className="col">
                                                            <div className="row align-items-center justify-content-between">
                                                                <div className="col-auto">
                                                                    <h4 className="my-0">{finalTitleString.charAt(0).toUpperCase() + finalTitleString.slice(1)}</h4>
                                                                </div>
                                                                <div className="col-auto">
                                                                    {value.priorityScore ?
                                                                        <p style={{textDecoration: 'underline'}} className="my-0">Importance: {value.priorityScore / maxScore > 0.15 ? `High` : value.priorityScore / maxScore > 0.05 ? 'Medium' : 'Low'}</p>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {value.description ?
                                                        <div className="row">
                                                            <p>Description: <br />- {value.description} </p>
                                                        </div>
                                                        : null}
                                                        <div className="row">
                                                            <p>Recommended: <br />- {value.traits.length ? value.traits.reduce((accu, elem) => {return accu === null ? [elem] : [...accu, ', ', elem]} ) : Object.entries(value.traits).map((elem) => `${elem[0]}: ${elem[1]}`).join(', ')} </p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p>Your needs: {value.reasoning.map((reason, index) => { return <p key={`${key}${index}Description`} className="my-0">- {reason.description}</p> })}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p>Impact: {value.reasoning.map((reason, index) => { return <p key={`${key}${index}Impact`} className="my-0">- {reason.impact} {reason.references.length > 0 ? <span><Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={<React.Fragment>{reason.references?.map((ref) => { return <span><a style={{color: 'white'}} target="_blank" href={ref}>{ref}</a><br /></span> })}</React.Fragment>}><FormatQuoteIcon /></Tooltip></span> : null}</p> })}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <img className="img img-fluid" src={`/shoeTraitAssets/${key}.png`} alt="" />
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>

    return (
        <Modal
            heading={'Running shoe recommendation reasoning'}
            content={content}
            openState={openState}
            setOpenState={setOpenState}
        />
    )
}