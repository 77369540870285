import './index.css'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../../../utils/ContextProvider.jsx'
import Button from '@mui/material/Button'

export default function FormBodySelection( {heading = null, subheading = null, selectionOptions = null, handleClick = null, partner = null, toggleAlt = false} ) {

    var { partner } = useAppContext()
    
    var buttonSize = ""

    if (selectionOptions.length <= 2) {
        buttonSize = "col-md-5"
    } else if (selectionOptions.length <=3)  {
        buttonSize = "col-md-4"
    } else {
        buttonSize = "col-md-3"
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-auto mx-auto text-center text-sm-start">
                    <h2>{heading}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-auto mx-auto text-center text-sm-start">
                    <p>{subheading}</p>
                </div>
            </div>

            <div className="row">
                {selectionOptions.map((item, index)=> (
                    <div className={`col-12 ${buttonSize} py-2 mt-md-2 mx-auto`} key={item.name + item.value + 'Button'}>
                        <Button
                        className="btn form-card-button py-4 py-md-5 mx-auto text-center" 
                        name={item.name} 
                        value={item.value} 
                        onClick={(e) => {
                            handleClick({
                                ...e,
                                target: {
                                    name: item.name,
                                    value: item.value
                                }
                            });
                        }} 
                        sx={{
                            color: 'black',
                            border: '1px solid #EEEBEB',
                            textTransform: 'none',
                            '&:hover': {
                              borderColor: partner? `${partner.primary} !important` : null,
                              backgroundColor: 'white'
                            },
                        }}
                        >
                            <div className="row align-items-center">

                                {(item.imageUrl || item.icon) ? 
                                    <div className="col-11 mx-auto pb-4">
                                    {item.imageUrl ? 
                                        <img className='img img-fluid' style={{maxHeight: '300px'}} src={item.imageUrl} alt="" />
                                        : item.icon
                                    }
                                </div>                
                                : null} 
                             <h4 className='my-0'>{!toggleAlt ? item.title : item.titleAlt ? item.titleAlt : item.title}</h4> <br /> 
                             {item.subHeading ? <p className='my-0' style={{fontSize: '12px'}}>{item.subHeading}</p> : null}
                             </div>
                        </Button>
                    </div>
                ))}
            </div>
        </div> 
    )
}