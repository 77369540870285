import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from "react";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import { Button } from "@mui/material";

export default function RecommendationReasoningPanel ({ requirements = null, setOpenState = null }) {

    const { trackEvent } = useAppContext()

    const handleClick = (evt) => {
        trackEvent('View recommendation reasoning', {route: 'shoeListPage'})
        setOpenState(true)
    }

    return (
        <div className="row align-items-center justify-content-between py-3" style={{border: '1px solid green', borderRadius: '0.5rem', background: 'white'}}>
            <div className="col">
                <div className="row align-items-center">
                    <div className="col-10 col-sm-auto mx-auto text-center text-sm-start mx-sm-0">
                        <InfoIcon/>
                    </div>
                    <div className="col-10 col-sm-auto col-md-8 px-1 mx-auto text-center text-sm-start mx-sm-0">
                        <h4 className="my-0">Recommendation reasoning</h4>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-sm-auto text-center text-sm-start mx-sm-0">
                <Button
                    onClick={handleClick}
                    >
                    Learn more
                </Button>
            </div>
        </div>
    )
}