import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"
import { preferencesOptions } from "../../../../../utils/PreferencesOptions"

export default function ConditionsPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, "conditions": evt.target.value}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        navigate('/shoe-finder/preferences/usage')
        
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Are you consistently running in very muddy or boggy terrain?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={preferencesOptions.conditions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
                toolTip={"This helps us understand whether or not you need deeper, more spaced out lugs to handle mud and wet-conditions or not."}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}